
import { IRoutesLink, ISideBarRoutes } from "../../../Utils/interfaces";
import dashboardIcon from '../../../Assets/images/dashboard-nav-icon.png';
import QuoteRecordsIcon from '../../../Assets/images/quotes-nav-icon.png';
import vectorRecordsIcon from '../../../Assets/images/all-vector-orders-nav-icon.png';
import myAccountIcon from '../../../Assets/images/my-account-nav-icon.png';
import digitizingIcon from '../../../Assets/images/digitizing-nav-icon-records.png'
import LockICon from '../../../Assets/images/lock-nav-icon.png';
import allCustomersIcon from '../../../Assets/images/all-customers-nav-icon.png'


// Sales Routes

import Dashboard from "../Pages/Dashboard/Dashboard";
import SalesAllQuotes from '../Pages/QuotesRecords/SalesAllQuotes';
import SalesQuoteDetails from '../Pages/QuotesRecords/SalesQuoteDetails/SalesQuoteDetails';
import DigitizingRecords from '../Pages/DigitizingRecords/DigitizingRecords';
import DigitizingRecordsDetails from '../Pages/DigitizingRecords/DigitizingRecordsDetails/DigitizingRecordsDetails';
import VectorRecords from '../Pages/VectorRecords/VectorRecords';
import VectorOrderDetails from '../Pages/VectorRecords/VectorOrderDetails/VectorOrderDetails';
import MyProfile from '../../Customer/Pages/MyProfile/MyProfile';
import ChangePassword from '../../Customer/Pages/MyProfile/ChangePassword/ChangePassword';
import CustomersList from "../Pages/CustomersList/CustomersList";
import CustomerDetails from "../Pages/CustomersList/CustomerDetails/CustomerDetails";
import MonthlyReport from "../Pages/MonthlyReport/MonthlyReport";


// Sales Routes

export const salesRoutes: IRoutesLink[] = [
    {
        path: "/sales/dashboard",
        component: <Dashboard />,
    },
    {
        path: "/sales/quote-records",
        component: <SalesAllQuotes />,
    },
    {
        path: "/sales/quote-record-details/:id",
        component: <SalesQuoteDetails />,
    },
    {
        path: "/sales/digitizing-records",
        component: <DigitizingRecords />,
    },
    {
        path: "/sales/digitizing-order-details/:id",
        component: <DigitizingRecordsDetails />,
    },
    {
        path: "/sales/vector-records",
        component: <VectorRecords />,
    },
    {
        path: "/sales/vector-order-details/:id",
        component: <VectorOrderDetails />,
    },
    {
        path: "/sales/customers-list",
        component: <CustomersList />,
    },
    {
        path: "/sales/customer-details/:id",
        component: <CustomerDetails />,
    },
    {
        path: "/sales/monthly-report",
        component: <MonthlyReport />,
    },
    {
        path: "/sales/profile",
        component: <MyProfile />,
    },
    {
        path: "/sales/reset-password",
        component: <ChangePassword />,
    },
    

];

export const salesSideBarItems: ISideBarRoutes[] = [
    {
        path: "/sales/dashboard",
        icon: <img src={dashboardIcon} alt="dashbord-icon" />,
        title: "Dashboard",
        isSubNav: false,
    },
    {
        path: "/sales/quote-records",
        icon: <img src={QuoteRecordsIcon} alt="quotes-icon" />,
        title: "Quote Records",
        isSubNav: false,
    },
    {
        path: "/sales/digitizing-records",
        icon: <img src={digitizingIcon} alt="digitizing-icon" />,
        title: "Digitizing Records",
        isSubNav: false,
    },
    {
        path: "/sales/vector-records",
        icon: <img src={vectorRecordsIcon} alt="vector-icon" />,
        title: "Vector Records",
        isSubNav: false,
    },
    {
        path: "/sales/customers-list",
        icon: <img src={allCustomersIcon} alt="vector-icon" />,
        title: "Customer Records",
        isSubNav: false,
    },
    {
        path: "/sales/monthly-report",
        icon: <img src={digitizingIcon} alt="vector-icon" />,
        title: "Monthly Report",
        isSubNav: false,
    },
    {
        path: "",
        icon: <img src={myAccountIcon} alt="account-icon" />,
        title: "My Account",
        isSubNav: true,
        subNav: [
            {
                path: "/sales/profile",
                component: <MyProfile />,
                icon: <img src={myAccountIcon} alt="account-icon" />,
                title: "My Profile"
            },
            {
                path: "/sales/reset-password",
                component: <ChangePassword />,
                icon: <img src={LockICon} alt="account-icon" />,
                title: "Change Password"
            },
        ]
    },
];