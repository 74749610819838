import React, { useEffect } from "react";
import { Card, Col, Row, Container } from "react-bootstrap";
import OrderIcon from "../../../../Assets/images/allorders-updated.png";
import QuoteIcon from "../../../../Assets/images/allquotes_updated.png";
import VectorIcon from "../../../../Assets/images/vectors order.png";
import InvoicesIcon from "../../../../Assets/images/Allinvoices_updated.png";
import dashbordIcon from '../../../../Assets/images/dashboard-icon.png';
import machineOperatorIcon from '../../../../Assets/images/machineoperator_updated.png';
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import "./Dashboard.css";
import AllQuotes from "../AllQuotes/AllQuotes";
import AllOrders from "../AllOrders/AllOrders";
import AllVectorOrders from "../AllVectorOrders/AllVectorOrders";
import { getDashboardData } from "../../../../Redux/Actions/admin";
import Loader from "../../../../Utils/Loader";
import salesIcon from "../../../../Assets/images/send quotes.png";
import moment from "moment";

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const Dashboard = () => {
  const { loading, dashboard } = useSelector(
    (state: any) => state?.adminDashboardData
  );
  const dispatch: AppDispatch = useDispatch();
  const date = new Date();

  useEffect(() => {
    dispatch(getDashboardData());
  }, []);

  return (
    <div className="page_responsive">
      <Container fluid style={{ maxWidth: "1500px" }}>
        <div className="dashboard_div_main card">
          <div className="dashboard_container">
            <img src={dashbordIcon} alt='dashboaord' />
            <h4>Dashboard</h4>
            <div className="w-100 text-end">
              <h4> {moment(`${new Date(date)}`).utc().format('YYYY-MM-DD hh:mm A')}</h4>
            </div>
          </div>
        </div>
        <Row className="justify-content-around my-2">
          <Col md={4}>
            <Card className="details-card" id='detailed1'>
              {loading ? (
                <Loader />
              ) : (
                <Card.Body>
                  <Row className="align-items-center">
                    <Col md={8}>
                      <div className="all_icon_div_text">
                        <h5>All Quotes:</h5>

                        <div className="d-flex">
                          <div className="left_total">
                            <div className="circle_main">
                              <div className="circle_inner"></div>
                              <p>Total Quotes</p>
                            </div>
                            <h6>{dashboard?.data?.quote?.total}</h6>
                          </div>
                          <div>
                            <div className="circle_main">
                              <div className="circle_inner"></div>
                              <p>Today's Quotes</p>
                            </div>
                            <h6>{dashboard?.data?.quote?.today?.count}</h6>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={4} className={'d-flex justify-content-end'}>
                      <div className="all_icon_div">
                        <img src={QuoteIcon} alt="" />
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              )}
            </Card>
          </Col>
          <Col md={4}>
            <Card className="details-card" id='detailed2'>
              {loading ? (
                <Loader />
              ) : (
                <Card.Body>
                  <Row className="align-items-center">
                    <Col md={8}>
                      <div className="all_icon_div_text">
                        <h5>All Orders:</h5>

                        <div className="d-flex">
                          <div className="left_total">
                            <div className="circle_main">
                              <div className="circle_inner"></div>
                              <p>Total Orders</p>
                            </div>
                            <h6>{dashboard?.data?.digitizing?.total}</h6>
                          </div>
                          <div>
                            <div className="circle_main">
                              <div className="circle_inner"></div>
                              <p>Today's Orders</p>
                            </div>
                            <h6>{dashboard?.data?.digitizing?.today?.count}</h6>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={4} className={'d-flex justify-content-end'}>
                      <div className="all_order_icon_div">
                        <img src={OrderIcon} alt="" />
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              )}
            </Card>
          </Col>

          <Col md={4}>
            <Card className="details-card" id='detailed3'>
              {loading ? (
                <Loader />
              ) : (
                <Card.Body>
                  <Row className="align-items-center">
                    <Col md={8}>
                      <div className="all_icon_div_text">
                        <h5>Vectors Orders:</h5>

                        <div className="d-flex">
                          <div className="left_total">
                            <div className="circle_main">
                              <div className="circle_inner"></div>
                              <p>Total Orders</p>
                            </div>
                            <h6>{dashboard?.data?.vector?.total}</h6>
                          </div>
                          <div>
                            <div className="circle_main">
                              <div className="circle_inner"></div>
                              <p>Today's Orders</p>
                            </div>
                            <h6>{dashboard?.data?.vector?.today?.count}</h6>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={4} className={'d-flex justify-content-end'}>
                      <div className="all_vector_icon_div">
                        <img src={VectorIcon} alt="vector-icon" />
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              )}
            </Card>
          </Col>
        </Row>

        <Row className="justify-content-around my-2">

          <Col md={4}>
            <Card className="details-card" id='detailed4'>
              {loading ? (
                <Loader />
              ) : (
                <Card.Body>
                  <Row className="align-items-center">
                    <Col md={8}>
                      <div className="all_icon_div_text">
                        <h5>All Invoices:</h5>

                        <div className="d-flex">
                          <div className="left_total">
                            <div className="circle_main">
                              <div className="circle_inner"></div>
                              <p>Total Invoices:</p>
                            </div>
                            <h6>{dashboard?.data?.invoice?.total}</h6>
                          </div>
                          <div>
                            <div className="circle_main">
                              <div className="circle_inner"></div>
                              <p>Today's Invoices:</p>
                            </div>
                            <h6>{dashboard?.data?.invoice?.today?.count}</h6>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={4} className={'d-flex justify-content-end'}>
                      <div className="all_invoices_icon_div">
                        <img src={InvoicesIcon} alt="" />
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              )}
            </Card>
          </Col>

          <Col md={4}>
            <Card className="details-card" id='detailed5'>
              {loading ? (
                <Loader />
              ) : (
                <Card.Body>
                  <Row className="align-items-center">
                    <Col md={8}>
                      <div className="all_icon_div_text">
                        <h5>Sales</h5>

                        <div className="d-flex">
                          <div className="left_total">
                            <div className="circle_main">
                              <div className="circle_inner"></div>
                              <p>Today's Sales</p>
                            </div>
                            <h6>{dashboard?.data?.sales?.today_sales}</h6>
                          </div>
                          <div>
                            <div className="circle_main">
                              <div className="circle_inner"></div>
                              <p>Monthly Sales</p>
                            </div>
                            <h6>{dashboard?.data?.sales?.monthly_sales}</h6>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={4} className={'d-flex justify-content-end'}>
                      <div className="all_sales_icon_div">
                        <img src={salesIcon} alt="vector-icon" />
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              )}
            </Card>
          </Col>

          <Col md={4}>
            <Card className="details-card" id='detailed6'>
              {loading ? (
                <Loader />
              ) : (
                <Card.Body>
                  <Row className="align-items-center">
                    <Col md={8}>
                      <div className="all_icon_div_text">
                        <h5>Machine Operator:</h5>

                        <div className="d-flex">
                          <div className="left_total">
                            <div className="circle_main">
                              <div className="circle_inner"></div>
                              <p>Today's Tasks:</p>
                            </div>
                            <h6>{dashboard?.data?.operator[0]?.today ? dashboard?.data?.operator[0]?.today : 0}</h6>
                          </div>
                          <div>
                            <div className="circle_main">
                              <div className="circle_inner"></div>
                              <p>Pending Tasks:</p>
                            </div>
                            <h6>{dashboard?.data?.operator[0]?.new ? dashboard?.data?.operator[0]?.new : 0}</h6>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={4} className={'d-flex justify-content-end'}>
                      <div className="all_invoices_icon_div">
                        <img src={machineOperatorIcon} alt="" />
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              )}
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={12} className="my-1">
            <AllQuotes style="none" today={1} />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="my-1">
            <AllOrders style="none" today={1} />
          </Col>
        </Row>

        <Row>
          <Col md={12} className="my-1">
            <AllVectorOrders style="none" today={1} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Dashboard;
