import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Card, Col, Row, Spinner, Form } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";
import "./orderDetails.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { errorNotify, successNotify } from "../../../../../Utils/toast";
import { getUserOrderDetails } from "../../../../../Redux/Actions/UserOrders";
import Loader from "../../../../../Utils/Loader";
import { deleteAdminOrdersRecords, orderSentToCustomer } from "../../../../../Redux/Actions/admin";
import moment from "moment";
import Modal from "../../../../../Components/SiteModal/SiteModal";
import { downloadFile, cloudBaseURL } from '../../../../../Utils/helper'
import OrderIcon from "../../../../../Assets/images/orders.png";

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;
const OrderDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { error, loading, orderDetails } = useSelector((state: any) => state.userOrderDetails);
  const { deleteOrders, loading: deleteLoading } = useSelector((state: any) => state?.adminDeleteOrders);
  const { loading: sendLoading, sentToCustomer, error: sendError } = useSelector((state: any) => state.orderSentToCustomer)
  const customerID = orderDetails?.data?.Customer?.id;
  const [show, setShow] = React.useState(false);
  const [sendShow, setSendShow] = React.useState(false);
  const [orderData, setOrderData] = React.useState({ price: 0 });

  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    if (error) {
      errorNotify(error);
      dispatch({ type: "CLEAR_ERRORS" });
    }
    dispatch(getUserOrderDetails(id));
  }, []);

  useEffect(() => {
    if (deleteOrders && deleteOrders?.status === 1) {
      successNotify("Order Deleted Successfully");
      setShow(false);
      navigate(-1);
      dispatch({ type: "ALL_ORDERS_DELETE_RESET" });
    }
    if (sentToCustomer?.status === 1) {
      successNotify("Order Sent Successfully");
      setSendShow(false);
      navigate(-1);
      dispatch({ type: "ORDER_SENT_TO_CUSTOMER_RESET" });
    }
    if (sendError) {
      errorNotify(error);
      dispatch({ type: "CLEAR_ERRORS" });
    }
  }, [deleteOrders, sentToCustomer?.status, sendError]);

  const deleteOrderRecords = (id: any) => {
    dispatch(deleteAdminOrdersRecords(id));
  };

  const OrderIdHandler = () => {
    setShow(!show);
  };
  const SendOrderHandler = () => {
    setSendShow(!sendShow);
  };
  const sendOrderToCustomer = (e: any) => {
    e.preventDefault()
    dispatch(orderSentToCustomer(id, orderData))
  }

  const modal = (
    <Modal
      title="Delete"
      size={"lg"}
      show={show}
      onCloseModal={() => setShow(!show)}
    >
      <React.Fragment>
        <p>Are u sure you want to delete this order?</p>
        <div className="modal_decision">
          <button
            className="my-1 details_btn bg-red"
            onClick={() => deleteOrderRecords(id)}
          >
            {deleteLoading ? <Spinner animation="border" size="sm" /> : "Yes"}
          </button>
          <button
            className="my-1 details_btn sent_order"
            onClick={() => setShow(false)}
          >
            No
          </button>
        </div>
      </React.Fragment>
    </Modal>
  );
  const SendOrderModal = (
    <Modal
      title="Send Order"
      size={undefined}
      show={sendShow}
      onCloseModal={() => setSendShow(!sendShow)}
    >
      <React.Fragment>
        <div className="send_order_container">
          <p>Are u sure you want to send this order?</p>
          <Form onSubmit={sendOrderToCustomer}>
            <label className="text-start">Price:{orderDetails?.data?.Customer?.Country?.currency_symbol}</label>
            <input
              className="form-control"

              placeholder="Enter Price"
              value={orderData.price}
              onChange={(e: any) => setOrderData({ price: e.target.value })}
              type="text"
            />
            <button
              className="my-1 details_btn sent_order"
              type="submit"

            >
              {sendLoading ? <Spinner animation="border" size="sm" /> : "Send Order To Customer"}
            </button>
          </Form>
        </div>
      </React.Fragment>
    </Modal>
  );

  return (
    <div className="page_responsive">
      <div className="card d-flex">
        <div className="send_quotes_head_container" id="digitizer_order">
          <div className='send_digitizing_order_div_shorter'>
            <img src={OrderIcon} alt="pencil-logo" />
          </div>
          <h4>Order Details</h4>
        </div>
      </div>
      <div className="user_details_card">
        <Card className="order_details_card">
          {loading ? (
            <Loader />
          ) : (
            <Card.Body>
              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1  detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Order ID:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">{orderDetails?.data?.order_id}</Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1  detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Order Name:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">{orderDetails?.data?.order_name}</Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1  detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Order Date:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {moment(orderDetails?.data?.createdAt)
                          .utc()
                          .format("DD-MM-YYYY")}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1  detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Customer Username:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {orderDetails?.data?.Customer?.username}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1  detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Customer Full Name:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {orderDetails?.data?.Customer?.first_name}{" "}
                        {orderDetails?.data?.Customer?.last_name}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1  detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Required Format:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {orderDetails?.data?.Format?.name}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1  detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Height:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">{orderDetails?.data?.height}</Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1  detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Width:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">{orderDetails?.data?.width}</Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1  detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Fabric:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {orderDetails?.data?.Fabric?.name}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1  detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Placement:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {orderDetails?.data?.Placement?.name}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1  detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Number of Colors:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">{orderDetails?.data?.no_color}</Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1  detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Super Urgent:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {parseInt(orderDetails?.data?.urgent) === 1
                          ? "urgent"
                          : "not-urgent"}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1  detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Status:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {parseInt(orderDetails?.data?.status) === 0
                          ? "pending"
                          : parseInt(orderDetails?.data?.status) === 1
                            ? "Process"
                            : "completed"}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1  detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Price:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {parseInt(orderDetails?.data?.price) > 0
                          ? (orderDetails?.data?.Customer?.Country?.currency_symbol) + (orderDetails?.data?.price)
                          : "Price not Selected"}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="justify-content-around my-1">
                <Col md={11} className="m-1  detail_field">
                  <Row>
                    <Col md={12}>
                      <Typography variant="h6">
                        Additional Instruction:{" "}
                      </Typography>
                      <Typography>
                        {orderDetails?.data?.additional_instruction}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {(orderDetails?.data?.attachments?.length > 0) && (
                <Row className="justify-content-around my-1">
                  <Col md={11} className="m-1">
                    <Typography variant="h4">Customer Attachments:</Typography>
                  </Col>
                </Row>
              )}
              <Row className="justify-content-around my-1">
                {(orderDetails?.data?.attachments) &&
                  orderDetails?.data?.attachments.map(
                    (attachment: any, i: any) => {
                      if (parseInt(attachment?.attachment_type) === 0) {
                        return (
                          <Col md={11} className="m-1 detail_field">
                            <Row className="align-items-center">
                              <Col md={4}>
                                <Typography variant="h6">File {i + 1}:</Typography>
                              </Col>
                              <Col md={4}>
                                <Typography className="text-center">
                                  {attachment.attachment_name}
                                </Typography>
                              </Col>
                              <Col md={4} className="d-flex justify-content-end">
                                <button className="download_btn" onClick={() => downloadFile(`${cloudBaseURL}digitizing/${id}/${attachment.attachment_name}`, `${attachment.attachment_name}`)}>
                                  <FaDownload /> Download
                                </button>
                              </Col>
                            </Row>
                          </Col>
                        )
                      }
                    }
                  )}
              </Row>

              {parseInt(orderDetails?.data?.digitizer_order_status) === 1 && orderDetails?.data?.attachments && (
                <Row className="justify-content-around my-1">
                  <Col md={11} className="m-1 ">
                    <Typography variant="h4">Digitizer Attachments:</Typography>
                  </Col>
                </Row>
              )}
              <Row className="justify-content-around my-1">
                {orderDetails?.data?.attachments &&
                  orderDetails?.data?.attachments.map(
                    (attachment: any, i: any) => {
                      if (parseInt(attachment?.attachment_type) === 1) {
                        return (
                          <Col md={11} className="m-1 detail_field">
                            <Row className="align-items-center">
                              <Col md={4}>
                                <Typography variant="h6">File {i + 1}:</Typography>
                              </Col>
                              <Col md={4}>
                                <Typography className="text-center">
                                  {attachment.attachment_name}
                                </Typography>
                              </Col>
                              <Col md={4} className="d-flex justify-content-end">
                                <button className="download_btn" onClick={() => downloadFile(`${cloudBaseURL}digitizing/${id}/${attachment.attachment_name}`, `${attachment.attachment_name}`)}>
                                  <FaDownload /> Download
                                </button>
                              </Col>
                            </Row>
                          </Col>
                        )
                      }
                    }
                  )}
              </Row>

              <Row className="pt-4 justify-content-center">
                <Col xs={12} md={12} className='button_container_main'>
                  {parseInt(orderDetails?.data?.digitizer_order_status) === 1 && parseInt(orderDetails?.data?.status) === 1 ? (
                    <button
                      className="my-1 details_btn bg-orange"
                      onClick={() => SendOrderHandler()}
                    >
                      Send to Customer
                    </button>
                  ) : null
                }

                  <button
                    className="my-1 details_btn bg-gray"
                    id={`${parseInt(orderDetails?.data.status) === 1 || parseInt(orderDetails?.data.status) === 2
                      ? "disabled_btn"
                      : null
                      }`}
                    disabled={
                      parseInt(orderDetails?.data.status) === 1 || parseInt(orderDetails?.data.status) === 2 ? true : false
                    }
                    onClick={() =>
                      navigate(
                        `/admin/assign-order/${orderDetails?.data?.order_id}`,
                        { state: { name: orderDetails?.data?.order_name } }
                      )
                    }
                  >
                    {`${parseInt(orderDetails?.data.status) === 1 || parseInt(orderDetails?.data.status) === 2
                      ? "Order Assigned"
                      : "Assign Order"
                      }`}
                  </button>

                  <button
                    className="my-1 details_btn sent_order"
                    onClick={() =>
                      navigate(`/admin/customer-details/${customerID}`)
                    }
                  >
                    Customer Details
                  </button>
                  <button
                    className="my-1 details_btn bg-red"
                    onClick={() => OrderIdHandler()}
                  >
                    {deleteLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Delete Order"
                    )}
                  </button>
                </Col>
              </Row>
            </Card.Body>
          )}
        </Card>
      </div>
      {modal}
      {SendOrderModal}
    </div>
  );
};

export default OrderDetails;
