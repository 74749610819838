import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import { Card, Col, Row } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { getSalesVectorOrderDetails } from "../../../../../Redux/Actions/Sales";
import { errorNotify } from "../../../../../Utils/toast";
import Loader from "../../../../../Utils/Loader";
import moment from 'moment';
import { downloadFile, cloudBaseURL, CurrentUserInfo } from '../../../../../Utils/helper';
import VectorIcon from "../../../../../Assets/images/vector.png";

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const VectorOrderDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { error, loading, salesVectorDetails } = useSelector((state: any) => state.salesVectorDetails);
  const dispatch: AppDispatch = useDispatch();
  const CurrencySymbol = CurrentUserInfo()?.country?.currency_symbol;

  useEffect(() => {
    if (error) {
      errorNotify(error);
      dispatch({ type: "CLEAR_ERRORS" });
    }
    dispatch(getSalesVectorOrderDetails(id));
  }, []);

  return (
    <div className="page_responsive">
      <div className="card">
        <div className="send_quotes_head_container" id="vector_order_div">
          <div className='send_vector_order_div_shorter'>
            <img src={VectorIcon} alt="pencil-logo" />
          </div>
          <h4>Vector Order Details</h4>
        </div>
      </div>
      <div className="user_details_card">
        <Card className="order_details_card">
          {loading ? (
            <Loader />
          ) : (
            <Card.Body>
              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Order ID:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">{salesVectorDetails?.data?.vector_no}</Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1  detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Order Name:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {salesVectorDetails?.data?.vector_name}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1  detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Order Date:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">{moment(salesVectorDetails?.data?.send_date).utc().format("DD-MM-YYYY")}</Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1  detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Customer Username:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {salesVectorDetails?.data?.Customer?.username}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1  detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Customer Full Name:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {salesVectorDetails?.data?.Customer?.first_name}{" "}
                        {salesVectorDetails?.data?.Customer?.last_name}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1  detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6" >Required Format:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {salesVectorDetails?.data?.Format?.name}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1  detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Number of Colors:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">{salesVectorDetails?.data?.no_color}</Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1  detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Super Urgent:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {parseInt(salesVectorDetails?.data?.urgent) === 0
                          ? "Not Urgent"
                          : "Urgent"}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {/* <Row className="justify-content-around my-1">
                <Col md={5} className="m-1  detail_field">
                  <Row className="align-items-center">
                    <Col md={4}>
                      <Typography variant="h6">File 1:</Typography>
                    </Col>
                    <Col md={4}>
                      <Typography className="text-center">
                        {salesVectorDetails?.data?.file1 === ""
                          ? "Doest not have any file"
                          : salesVectorDetails?.data?.file1}
                      </Typography>
                    </Col>
                    {salesVectorDetails?.data?.file1 === "" ? null : (
                      <Col md={4} className="d-flex justify-content-end">
                        <button className="download_btn" onClick={() => downloadFile(`${cloudBaseURL}${salesVectorDetails?.data?.file1}`, salesVectorDetails?.data?.file1)}>
                          <FaDownload />  Download
                        </button>
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col md={5} className="m-1  detail_field">
                  <Row className="align-items-center">
                    <Col md={4}>
                      <Typography variant="h6">File 2:</Typography>
                    </Col>
                    <Col md={4}>
                      <Typography className="text-center">
                        {salesVectorDetails?.data?.file2 === ""
                          ? "Doest not have any file"
                          : salesVectorDetails?.data?.file2}
                      </Typography>
                    </Col>

                    {salesVectorDetails?.data?.file2 === "" ? null : (
                      <Col md={4} className="d-flex justify-content-end">
                        <button className="download_btn" onClick={() => downloadFile(`${cloudBaseURL}vector/${id}/${salesVectorDetails?.data?.file2}`, salesVectorDetails?.data?.file2)}>
                          <FaDownload />  Download
                        </button>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row> */}


              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1  detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Status:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {parseInt(salesVectorDetails?.data?.status) === 0
                          ? "pending"
                          : "Completed"}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1  detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Price:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">{salesVectorDetails?.data?.price === 0 ? "Price not selected" : `${CurrencySymbol}${salesVectorDetails?.data?.price}`}</Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="justify-content-around my-1">
                <Col md={11} className="m-1  detail_field">
                  <Row>
                    <Col md={12}>
                      <Typography variant="h6">
                        Additional Instruction:{" "}
                      </Typography>
                      <Typography>
                        {salesVectorDetails?.data?.additional_instruction}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>


              {(salesVectorDetails?.data?.attachments?.length > 0) && (
                <Row className="justify-content-around my-1">
                  <Col md={11} className="m-1">
                    <Typography variant="h4">Customer Attachments:</Typography>
                  </Col>
                </Row>
              )}
              <Row className="justify-content-around my-1">
                {(salesVectorDetails?.data?.attachments) &&
                  salesVectorDetails?.data?.attachments.map(
                    (attachment: any, i: any) => {
                      if (parseInt(attachment?.attachment_type) === 0) {
                        return (
                          <Col md={11} className="m-1 detail_field" key={i}>
                            <Row className="align-items-center">
                              <Col md={4}>
                                <Typography variant="h6">File {i + 1}:</Typography>
                              </Col>
                              <Col md={4}>
                                <Typography className="text-center">
                                  {attachment.attachment_name}
                                </Typography>
                              </Col>
                              <Col md={4} className="d-flex justify-content-end">
                                <button className="download_btn" onClick={() => downloadFile(`${cloudBaseURL}vector/${id}/${attachment.attachment_name}`, `${attachment.attachment_name}`)}>
                                  <FaDownload /> Download
                                </button>
                              </Col>
                            </Row>
                          </Col>
                        )
                      }
                    }
                  )}
              </Row>

              {(parseInt(salesVectorDetails?.data?.designer_status) === 1 && salesVectorDetails?.data?.attachments?.length > 0) && (
                <Row className="justify-content-around my-1">
                  <Col md={11} className="m-1">
                    <Typography variant="h4">Designer Attachments:</Typography>
                  </Col>
                </Row>
              )}
              <Row className="justify-content-around my-1">
                {(salesVectorDetails?.data?.attachments && parseInt(salesVectorDetails?.data?.status) === 2) &&
                  salesVectorDetails?.data?.attachments.map(
                    (attachment: any, i: any) => {
                      if (parseInt(attachment?.attachment_type) === 1) {
                        return (
                          <Col md={11} className="m-1 detail_field" key={i}>
                            <Row className="align-items-center">
                              <Col md={4}>
                                <Typography variant="h6">File {i + 1}:</Typography>
                              </Col>
                              <Col md={4}>
                                <Typography className="text-center">
                                  {attachment.attachment_name}
                                </Typography>
                              </Col>
                              <Col md={4} className="d-flex justify-content-end">
                                <button className="download_btn" onClick={() => downloadFile(`${cloudBaseURL}vector/${id}/${attachment.attachment_name}`, `${attachment.attachment_name}`)}>
                                  <FaDownload /> Download
                                </button>
                              </Col>
                            </Row>
                          </Col>
                        )
                      }
                    }
                  )}
              </Row>


              <Row className="pt-4 justify-content-around">
                <Col md={11} className="p-0">
                  <Col xs={12} md={3}>
                    <button
                      className="my-1 details_btn bg-gray"
                      onClick={() => navigate("/sales/vector-records")}
                    >
                      Go Back
                    </button>
                  </Col>
                </Col>
              </Row>
            </Card.Body>
          )}
        </Card>
      </div>
    </div>
  );
};

export default VectorOrderDetails;
