import React from "react";
import {Paper} from "@mui/material"
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./notFound.css"
const NotFound = () => {
    const navigate = useNavigate()
  return (
    <div className="notFound_container">
      <Paper className="notFound_box">
            <div style={{textAlign:"center",color:"#495057"}}>
            <h1 className="heading_404">404</h1>
            <h2 >OPPS! PAGE NOT FOUND</h2>
            <h4>Sorry, the page you are looking for doesn't exist.</h4>
            <Button className="mt-4" onClick={()=>navigate('/')}>Return Back</Button>
            </div>
      </Paper>
    </div>
  );
};

export default NotFound;
