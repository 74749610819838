import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Col, Row, Form, Spinner } from "react-bootstrap";
import { ThunkDispatch } from "redux-thunk";
import Input from "../../../../../../Components/Input/Input";
import Button from "../../../../../../Components/Button/Button";
import { AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { IAddUser } from "../../../../../../Utils/interfaces";
import { getRolesList, getUserDetails, updateUserDetails } from "../../../../../../Redux/Actions/admin";
import Loader from "../../../../../../Utils/Loader";
import { successNotify } from "../../../../../../Utils/toast";

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const EditUser = () => {
  const navigation = useNavigate();

  const {register,handleSubmit,setValue,formState: { errors }} = useForm<IAddUser>();
  const { id } = useParams();
  const { rolesList } = useSelector((state: any) => state.allRoles);
  const { loading, userDetails } = useSelector((state: any) => state.UserDetails);
  const { loading: updateLoading, updateUser } = useSelector((state: any) => state.updateUserDetails);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(getRolesList());
    dispatch(getUserDetails(id));
  }, [dispatch,id]);


  useEffect(() => {
    if (updateUser?.status === 1) {
      navigation('/admin/all-users')
      successNotify("Updated Successfully!")

      dispatch({ type: 'UPDATE_USER_DETAILS_RESET' })
    }
  }, [updateUser, dispatch, id])

  setValue("first_name", userDetails?.data?.first_name);
  setValue("last_name", userDetails?.data?.last_name);
  setValue("email", userDetails?.data?.email);
  setValue("password", userDetails?.data?.password);
  setValue("address", userDetails?.data?.address);
  setValue("role", userDetails?.data?.Role?.id)
  setValue('status', userDetails?.data?.status)

  const onSubmitHandler = handleSubmit(async (data: any) => {

    const userData = {
      first_name: data.first_name,
      last_name: data.last_name,
      role: parseInt(data.role),
      status: data.status,
      address: data.address
    }

    dispatch(updateUserDetails(id, userData));
  });

  return (
    <div className="page_responsive">
      <div className="create_emp_bg_background">
        <Container>
          <Form onSubmit={onSubmitHandler}>
            <Row>
              <Col md={12}>
                {loading ? (
                  <Loader />
                ) : (
                  <div className="personal_details_container">
                    <div className="details_head">
                      <h5>Edit Details</h5>
                    </div>

                    <div className="details_container">
                      <Row>
                        <Col md={6}>
                          <Input>
                            <Form.Label>First Name:</Form.Label>
                            <Form.Control
                              type="text"
                              {...register("first_name")}
                            />
                          </Input>
                        </Col>

                        <Col md={6}>
                          <Input>
                            <Form.Label>Last Name:</Form.Label>
                            <Form.Control
                              type="text"
                              {...register("last_name")}
                            />
                          </Input>
                        </Col>

                        <Col md={6}>
                          <Input>
                            <Form.Label>Email:</Form.Label>
                            <Form.Control
                              type="text"
                              disabled
                              {...register("email")}
                            />
                          </Input>
                        </Col>

                        <Col md={6}>
                          <Input>
                            <Form.Label>Address:</Form.Label>
                            <Form.Control
                              type="text"
                              {...register("address")}
                            />
                          </Input>
                        </Col>

                        <Col md={6}>
                          <Input>
                            <Form.Label>Role:</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              {...register("role")}
                            >
                              {rolesList?.data?.rows.map(
                                (rolesData: any, index: number) => (
                                  <option key={index} value={rolesData?.id}>
                                    {rolesData?.name}
                                  </option>
                                )
                              )}
                            </Form.Select>
                          </Input>
                        </Col>

                        <Col md={6}>
                          <Input>
                            <Form.Label>Status</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              {...register("status")}
                            >
                              <option value={0}>Pending</option>
                              <option value={1}>Active</option>
                            </Form.Select>
                          </Input>
                          {errors.status ? (
                            <small className="text-danger">
                              {errors.status && errors.status.message}
                            </small>
                          ) : null}
                        </Col>
                      </Row>
                      <Col md={12}>
                        <div className="d-flex justify-content-end py-3">
                          <Button type="submit"> {updateLoading ? <Spinner animation="border" size="sm" /> : 'UPDATE'} </Button>
                        </div>
                      </Col>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </div>
  );
};

export default EditUser;
