import React, { useEffect, useState } from "react";
import MuiDataTable from "../../../../Components/MUIDataTable/MUIDataTable";
import { useNavigate } from "react-router-dom";
import { deleteCustomerOrderRecords, getUserOrdersRecord } from "../../../../Redux/Actions/UserOrders";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import Loader from "../../../../Utils/Loader";
import moment from 'moment';
import { CurrentUserInfo } from '../../../../Utils/helper';
import eyeIcon from '../../../../Assets/images/eye.png';
import { Link } from "react-router-dom";
import deleteIcon from '../../../../Assets/images/delete-icon.png';
import Modal from '../../../../Components/SiteModal/SiteModal';
import { Spinner } from "react-bootstrap";
import { successNotify } from "../../../../Utils/toast";

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const DigitizingRecords = () => {
  const { loading, orderList, ordersCount } = useSelector((state: any) => state.userOrders);
  const { error: deleteError, loading: deleteLoading, deleteCustomerOrder } = useSelector((state: any) => state.customerOrderDelete);
  const dispatch: AppDispatch = useDispatch();
  const navigation = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [text, setText] = useState('');
  const currencySymbol = CurrentUserInfo()?.country?.currency_symbol;
  const [show, setShow] = useState(false);
  const [orderID, setOrderID] = useState("");

  useEffect(() => {
    dispatch(getUserOrdersRecord(page, rowsPerPage, text));
  }, [page, rowsPerPage, text]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deleteCustomerOrder && deleteCustomerOrder?.status === 1) {
      successNotify('Order Deleted Successfully')
      setShow(false)
      dispatch({ type: "CUSTOMER_ORDER_DELETE_RESET" })
      dispatch(getUserOrdersRecord(page, rowsPerPage, text));
    }

  }, [deleteCustomerOrder])// eslint-disable-next-line

  const orderIDHandler = (id: string) => {
    setOrderID(id);
    setShow(!show);
  };

  const modal = (
    <Modal title="Delete" size={'lg'} show={show} onCloseModal={() => setShow(!show)}>
      <React.Fragment>
        <p>Are u sure you want to delete this order?</p>
        <div className="modal_decision">
          <button className="my-1 details_btn bg-red" onClick={() => dispatch(deleteCustomerOrderRecords(orderID))}>
            {deleteLoading ? <Spinner animation='border' size='sm' /> : "Yes"}
          </button>
          <button className="my-1 details_btn sent_order" onClick={() => setShow(false)}>No</button>
        </div>
      </React.Fragment>
    </Modal>
  )

  const digitizingRecordsColumns = [
    { name: "sno", label: "S:NO" },
    { name: "order_id", label: "ORDER NO" },
    { name: "order_name", label: "ORDER NAME" },
    {
      name: "createdAt", label: "SEND DATE",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return moment(new Date(`${value} UTC`)).format("DD-MM-YYYY hh:mm:ss");
        },
      },
    },
    {
      name: "status",
      label: "STATUS",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          if (parseInt(value) === 0) {
            return (
              <div className="status-pending">
                <p className="mb-0">pending</p>
              </div>
            )
          }
          else if (parseInt(value) === 1) {
            return (
              <div className="status_in_process">
                <p className="mb-0">In process</p>
              </div>
            )
          }
          else if (parseInt(value) === 2) {
            return (
              <div className="status_completed">
                <p className="mb-0">completed</p>
              </div>
            )
          }
        },
      },
    },
    {
      name: "price", label: "PRICE",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          if (!value || null || 0) {
            return '-'
          }
          else {
            return (<span className='currency'>{currencySymbol}{parseInt(value)}</span>)
          }
        },
      },
    },

    {
      name: "DETAILS",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <div className="action_container">
              {
                parseInt(tableMeta?.rowData[4]) === 0 ?
                  <button
                    className="delete-btn"
                    type="button"
                    onClick={() => orderIDHandler(tableMeta.rowData[1])}
                  >
                    <img src={deleteIcon} alt="eye" />
                  </button> : null
              }
              <Link to={`/user/customer/order-details/${tableMeta.rowData[1]}`}>
                <button
                  className="view-btn"
                  type="button">
                  <img src={eyeIcon} alt="eye" />
                </button>
              </Link>
            </div >
          );
        },
      },
    },
  ];
  let newData: any = [];
  orderList?.data?.rows?.map((item: any, index: any) => {
    newData.push({ sno: rowsPerPage * page + index + 1, ...item });
  });

  return (
    <div className="page_responsive">
      <div className="quotes-table">
        {!text && loading ? <Loader /> :
          <MuiDataTable
            title={"DIGITIZING RECORDS"}
            data={newData}
            columns={digitizingRecordsColumns}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            count={ordersCount}
            setText={setText}
            text={text}
          />
        }
      </div>
      {modal}
    </div>
  );
};

export default DigitizingRecords;
