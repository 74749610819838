import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Container, Col, Row, Form, Spinner } from "react-bootstrap";
import Input from "../../../../../Components/Input/Input";
import Button from "../../../../../Components/Button/Button";
import { ICustomerDetails } from "../../../../../Utils/interfaces";
import { getUserDetails, getUsersByRoles, userAssignToSalesPerson } from '../../../../../Redux/Actions/admin';
import Loader from '../../../../../Utils/Loader';
import moment from 'moment';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import '../AllCustomers.css'
import { errorNotify, successNotify } from '../../../../../Utils/toast';

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const CustomerDetails = () => {
    const { register, handleSubmit, setValue } = useForm<ICustomerDetails>();
    const { id } = useParams();
    const dispatch: AppDispatch = useDispatch()
    const { loading, userDetails } = useSelector((state: any) => state.UserDetails);
    const { users } = useSelector((state: any) => state.allUsersByRole);
    const { loading: userAssignedLoading, assignedToSales, error: assignedToSalesError } = useSelector((state: any) => state.userAssignedToSales);
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(100)
    const [showall, setShowAll] = useState(1)
    const [salesID, setSalesID] = useState('')

    setValue('username', userDetails?.data?.username)
    setValue('email', userDetails?.data?.email)
    setValue('alternative_email', userDetails?.data?.alternative_email ? userDetails?.data?.alternative_email : "-")
    setValue('registered_date', moment(userDetails?.data?.registered_date).format("DD-MM-YYYY"))
    setValue('first_name', userDetails?.data?.first_name)
    setValue('last_name', userDetails?.data?.last_name)
    setValue('company_name', userDetails?.data?.company_name ? userDetails?.data?.company_name : '-')
    setValue('companyType', userDetails?.data?.companyType?.name ? userDetails?.data?.companyType?.name : '-')
    setValue('phone', userDetails?.data?.phone ? userDetails?.data?.phone : '-')
    setValue('cell', userDetails?.data?.cell ? userDetails?.data?.cell : '-')
    setValue('fax', userDetails?.data?.fax ? userDetails?.data?.fax : '-')
    setValue('address', userDetails?.data?.address ? userDetails?.data?.address : '-')
    setValue('city', userDetails?.data?.city ? userDetails?.data?.city : '-')
    setValue('country', userDetails?.data?.Country.id === 256 ? "United States" : 'United Kingdom')
    setValue('state', userDetails?.data?.state ? userDetails?.data?.state : '-')
    setValue('zip_code', userDetails?.data?.zip_code ? userDetails?.data?.zip_code : '-')
    setValue('postal_code', userDetails?.data?.state ? userDetails?.data?.postal_code : '-')
    setValue('Reference', userDetails?.data?.Reference?.name ? userDetails?.data?.Reference.name : '-')


    useEffect(() => {
        dispatch(getUserDetails(id))
        dispatch(getUsersByRoles("Sales", page, rowsPerPage, '', showall));
    }, [])

    useEffect(() => {
        if (assignedToSales?.status === 1) {
            successNotify(assignedToSales?.message)
            dispatch({ type: "USER_ASSIGNED_TO_SALESPERSON_RESET" })
            setSalesID('')
        }
        if (assignedToSalesError) {
            errorNotify(assignedToSalesError)
            dispatch({ type: "CLEAR_ERRORS" })
        }
    }, [assignedToSales?.status,assignedToSalesError])

    const onSubmitHandler = handleSubmit(async (data: any) => {
        if (salesID === '') {
            errorNotify("Please select any sales person")
        }
        else {
            dispatch(userAssignToSalesPerson(id, salesID))
        }
    });

    // Auto Complete Search Handlers

    const formatResult = (item: any) => {
        return (
            <>
                <span style={{ display: 'block', textAlign: 'left' }}>{item?.first_name} {item?.last_name}</span>
            </>
        )
    }

    const handleOnSearch = (string: any, results: any) => {
        console.log(string, results)
    }

    const handleOnSelect = (item: any) => {
        // the item selected
        setSalesID(item?.id)
    }

    return (
        <div className='page_responsive'>
            <div className="create_emp_bg_background">
                <Container>
                    <Form onSubmit={onSubmitHandler}>
                        <Row>
                            <Col md={12}>
                                {loading ? <Loader /> :
                                    <div className="personal_details_container">
                                        <div className="details_head">
                                            <h5>Customer Details</h5>
                                        </div>

                                        <div className="details_container">
                                            <Row>
                                                <Col md={6}>
                                                    <Input>
                                                        <Form.Label>User Name:</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            disabled
                                                            {...register("username")}
                                                        />
                                                    </Input>
                                                </Col>

                                                <Col md={6}>
                                                    <Input>
                                                        <Form.Label>Email:</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            disabled
                                                            {...register("email")}
                                                        />
                                                    </Input>
                                                </Col>

                                                <Col md={4}>
                                                    <Input>
                                                        <Form.Label>Alternative Email:</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            disabled
                                                            {...register("alternative_email")}
                                                        />
                                                    </Input>
                                                </Col>

                                                <Col md={4}>
                                                    <Input>
                                                        <Form.Label>Registered Date:</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            disabled
                                                            {...register("registered_date")}
                                                        />
                                                    </Input>
                                                </Col>

                                                <Col md={4}>
                                                    <Input>
                                                        <Form.Label>First Name:</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            disabled
                                                            {...register("first_name")}
                                                        />
                                                    </Input>
                                                </Col>

                                                <Col md={4}>
                                                    <Input>
                                                        <Form.Label>Last Name:</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            disabled
                                                            {...register("last_name")}
                                                        />
                                                    </Input>
                                                </Col>

                                                <Col md={4}>
                                                    <Input>
                                                        <Form.Label>Company Name:</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            disabled
                                                            {...register("company_name")}
                                                        />
                                                    </Input>
                                                </Col>

                                                <Col md={4}>
                                                    <Input>
                                                        <Form.Label>Company Type:</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            disabled
                                                            {...register("companyType")}
                                                        />
                                                    </Input>
                                                </Col>

                                                <Col md={4}>
                                                    <Input>
                                                        <Form.Label>Phone No:</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            disabled
                                                            {...register("phone")}
                                                        />
                                                    </Input>
                                                </Col>


                                                <Col md={4}>
                                                    <Input>
                                                        <Form.Label>Cell:</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            disabled
                                                            {...register("cell")}
                                                        />
                                                    </Input>
                                                </Col>

                                                <Col md={4}>
                                                    <Input>
                                                        <Form.Label>Fax:</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            disabled
                                                            {...register("fax")}
                                                        />
                                                    </Input>
                                                </Col>

                                                <Col md={8}>
                                                    <Input>
                                                        <Form.Label>Address:</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            disabled
                                                            {...register("address")}
                                                        />
                                                    </Input>
                                                </Col>

                                                <Col md={4}>
                                                    <Input>
                                                        <Form.Label>City:</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            disabled
                                                            {...register("city")}
                                                        />
                                                    </Input>
                                                </Col>

                                                <Col md={4}>
                                                    <Input>
                                                        <Form.Label>State:</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            disabled
                                                            {...register("state")}
                                                        />
                                                    </Input>
                                                </Col>

                                                <Col md={4}>
                                                    <Input>
                                                        <Form.Label>Country:</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            disabled
                                                            {...register("country")}
                                                        />
                                                    </Input>
                                                </Col>

                                                <Col md={4}>
                                                    <Input>
                                                        <Form.Label>Zip Code:</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            disabled
                                                            {...register("zip_code")}
                                                        />
                                                    </Input>
                                                </Col>

                                                <Col md={4}>
                                                    <Input>
                                                        <Form.Label>Postal Code:</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            disabled
                                                            {...register("postal_code")}
                                                        />
                                                    </Input>
                                                </Col>

                                                <Col md={4}>
                                                    <Input>
                                                        <Form.Label>Reference:</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            disabled
                                                            {...register("Reference")}
                                                        />
                                                    </Input>
                                                </Col>
                                                <Col md={4}>
                                                    <Input>
                                                        <Form.Label>ASSIGN TO</Form.Label>
                                                        <div className='sales_container_main' id="users_container">
                                                            <ReactSearchAutocomplete
                                                                onClear={() => setSalesID('')}
                                                                placeholder="Search any sales person"
                                                                items={users?.data?.rows}
                                                                onSearch={handleOnSearch}
                                                                fuseOptions={{ keys: ['first_name', 'last_name'] }}
                                                                autoFocus
                                                                onSelect={handleOnSelect}
                                                                resultStringKeyName='first_name'
                                                                formatResult={formatResult}
                                                                maxResults={15}
                                                            />
                                                        </div>
                                                    </Input>
                                                </Col>
                                            </Row>
                                            <Col md={12}>
                                                <div className="d-flex justify-content-end py-3">
                                                    <Button type="submit">
                                                      {
                                                        userAssignedLoading ? <Spinner animation='border' size='sm' /> : "ASSIGN"
                                                      }
                                                    </Button>
                                                </div>
                                            </Col>
                                        </div>
                                    </div>
                                }
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>
        </div>
    )
}

export default CustomerDetails
