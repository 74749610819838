import api from "../../Utils/interceptors";
import { getToken } from "../../Utils/helper";

// Dashboard Count

export const DesignerDashboardCount = () => async (dispatch: any) => {
    try {
        dispatch({
            type: "DESIGNER_DASHBOARD_COUNT_REQUEST",
        });

        const { data } = await api.get('/designer/dashboard');

        dispatch({
            type: "DESIGNER_DASHBOARD_COUNT_SUCCESS",
            payload: data,
            success: true,
        });
    } catch (e: any) {
        dispatch({
            type: "DESIGNER_DASHBOARD_COUNT_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
};

export const DesignerAllOrdersList = (pageNO: number, perPage: number, text: string, today: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: "DESIGNER_ALL_ORDERS_LIST_REQUEST",
        });

        const { data } = await api.get(`designer/order/list?page_no=${pageNO + 1}&per_page=${perPage}&term=${text}&today=${today}`);


        dispatch({
            type: "DESIGNER_ALL_ORDERS_LIST_SUCCESS",
            payload: data,
            success: true,
        });
    } catch (e: any) {
        dispatch({
            type: "DESIGNER_ALL_ORDERS_LIST_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
};


export const DesignerOrderDetails = (order_id: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: "DESIGNER_ORDER_DETAIL_REQUEST"
        })

        const { data } = await api.get(`designer/vector/order/${order_id}`)
  
        dispatch({
            type: "DESIGNER_ORDER_DETAIL_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e: any) {
        dispatch({
            type: "DESIGNER_ORDER_DETAIL_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
}