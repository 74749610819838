export const CustomerInvoicesListReducer = (state = [], action: any) => {
    switch (action.type) {
        case "GET_CUSTOMER_INVOICE_LIST_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "GET_CUSTOMER_INVOICE_LIST_SUCCESS":
            return {
                ...state,
                loading: false,
                customerInvoicesList: action.payload,
                customerInvoicesCount: action.payload.data.count
            };
        case "GET_CUSTOMER_INVOICE_LIST_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
                invoicesCount: 0
            };
        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};


export const CustomerInvoicesDetailsReducer = (state = { customerInvoiceDetail: [] }, action: any) => {
    switch (action.type) {
      case "GET_CUSTOMER_INVOICE_DETAIL_REQUEST":
        return {
          ...state,
          loading: true,
        };
      case "GET_CUSTOMER_INVOICE_DETAIL_SUCCESS":
        return {
          ...state,
          loading: false,
          customerInvoiceDetail: action.payload,
        };
      case "GET_CUSTOMER_INVOICE_DETAIL_FAILED":
        return {
          ...state,
          customerInvoiceDetail: null,
          loading: false,
          error: action.payload,
        };
      case "CLEAR_ERRORS":
        return {
          ...state,
          error: null
        }
      default:
        return state;
    }
  };