import React, { useEffect } from 'react'
import { Container, Col, Row, Form, Spinner } from "react-bootstrap";
import Input from "../../../../Components/Input/Input";
import Button from "../../../../Components/Button/Button";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch, useSelector } from "react-redux";
import { successNotify, errorNotify } from '../../../../Utils/toast';
import { useNavigate } from 'react-router-dom';
import { getUsersByRoles, MoOrderSave } from '../../../../Redux/Actions/admin';
import { useForm } from "react-hook-form";
import { IMOOrderSave } from "../../../../Utils/interfaces";
import { MOOrderSaveValidation } from '../../../../Utils/validation';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { IoMdAdd } from 'react-icons/io';

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const MOOrderSave = () => {
  const dispatch: AppDispatch = useDispatch();
  const { register, handleSubmit, reset, formState: { errors } } = useForm<IMOOrderSave>();
  const navigate = useNavigate()
  const { error, loading, moOrderData } = useSelector((state: any) => state.MoOrderSave);
  const { users } = useSelector((state: any) => state.allUsersByRole);
  const [fileSelected, setFileSelected] = React.useState<any>(null);
  const [fileSelected2, setFileSelected2] = React.useState<any>(null);
  const [fileSelected3, setFileSelected3] = React.useState<any>(null);
  const [fileSelected4, setFileSelected4] = React.useState<any>(null);
  const [operatorPage, setOperatorPage] = React.useState(0)
  const [operatorRowsPerPage, setOperatorRowsPerPage] = React.useState(100);
  const showall = 1;
  const [operatorID, setOperatorID] = React.useState('');
  // Dynamically Add Inputs
  const [inputValues, setInputValues] = React.useState<any>([]);
  const [counter, setCounter] = React.useState(0);
  const [btnCounter, setBtnCounter] = React.useState(4);

  useEffect(() => {
    if (moOrderData?.message) {
      successNotify(moOrderData?.message);
      reset()
      setInputValues([])
      navigate('/admin/mo-orders-list')
      dispatch({ type: "MO_ORDER_SAVE_RESET" });
    }
    if (error) {
      errorNotify(error);
      dispatch({ type: "CLEAR_ERRORS" });
    }
  }, [error, moOrderData])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getUsersByRoles("Operator", operatorPage, operatorRowsPerPage, '', showall));
  }, [operatorPage, operatorRowsPerPage, showall])

  const handleClick = () => {
    if (inputValues?.length >= btnCounter) {
      setBtnCounter(btnCounter + 1)
    }
    if (inputValues?.length === btnCounter) {
      setCounter(counter + 1);
    }
  };

  const handleOnChange = (e: any) => {
    const fileList = e.target.files[0]
    setInputValues([...inputValues, fileList]);
  };

  const handleImageChange = (name: any) => (e: any) => {
    const fileList = e.target.files;
    if (!fileList) return;

    if (name === "file1") {
      setInputValues([...inputValues, fileList[0]]);
    }
    else if (name === "file2") {
      setInputValues([...inputValues, fileList[0]]);
    }
    else if (name === "file3") {
      setInputValues([...inputValues, fileList[0]]);
    }
    else if (name === "file4") {
      setInputValues([...inputValues, fileList[0]]);
    }
  };

  const onSubmitHandler = handleSubmit(async (data) => {

    if (inputValues?.length <= 0) {
      errorNotify("Please select atleast 1 file!")
    }
    else {
      const fileData: any = new FormData()
      fileData.append('order_name', data.order_name)
      fileData.append('additional_instruction', data.additional_instruction)
      fileData.append('expire_in', data.expire_in)
      fileData.append('operator', operatorID)
      fileData.append('attachments[]', inputValues);

      dispatch(MoOrderSave(fileData))

    }
  });

  // Auto Complete Search Handlers

  const formatResult = (item: any) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>{item?.first_name} {item?.last_name}</span>
      </>
    )
  }

  const handleOnSearch = (string: any, results: any) => {
    console.log(string, results)
  }

  const handleOnSelect = (item: any) => {
    // the item selected
    setOperatorID(item?.id)
  }

  return (
    <div className='page_responsive'>
      <div className="create_emp_bg_background">
        <Container>
          <Form onSubmit={onSubmitHandler}>
            <Row>
              <Col md={12}>
                <div className="personal_details_container">
                  <div className="details_head">
                    <h5>Machine Operator Order Save</h5>
                  </div>

                  <div className="details_container">
                    <Row>
                      <Col md={6}>
                        <Input>
                          <Form.Label>Order Name:</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder='Enter order name'
                            {...register('order_name', MOOrderSaveValidation.order_name)}
                          />
                        </Input>
                        {errors.order_name ? (
                          <small className="text-danger">
                            {errors.order_name && errors.order_name.message}
                          </small>
                        ) : null}
                      </Col>
                      <Col md={6}>
                        <Input>
                          <Form.Label>Expires In:</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder='Enter total hours'
                            {...register('expire_in', MOOrderSaveValidation.expire_in)}
                          />
                        </Input>
                        {errors.expire_in ? (
                          <small className="text-danger">
                            {errors.expire_in && errors.expire_in.message}
                          </small>
                        ) : null}
                      </Col>
                      <Col md={6}>
                        <Input>
                          <Form.Label>File1:</Form.Label>
                          <Form.Control
                            type="file"
                            onChange={handleImageChange("file1")}
                          />
                        </Input>
                      </Col>

                      <Col md={6}>
                        <Input>
                          <Form.Label>File2:</Form.Label>
                          <Form.Control
                            type="file"
                            onChange={handleImageChange("file2")}
                          />
                        </Input>
                      </Col>

                      <Col md={6}>
                        <Input>
                          <Form.Label>File3:</Form.Label>
                          <Form.Control
                            type="file"
                            onChange={handleImageChange("file3")}
                          />
                        </Input>
                      </Col>

                      <Col md={6}>
                        <Input>
                          <Form.Label>File4:</Form.Label>
                          <Form.Control
                            type="file"
                            onChange={handleImageChange("file4")}
                          />
                        </Input>
                      </Col>

                      {Array.from(Array(counter)).map((c, index: any) => {
                        return (
                          <Col md={6} key={index}>
                            <Input>
                              <Form.Label>
                                File {index + 5}:
                              </Form.Label>
                              <Form.Control
                                type="file"
                                onChange={handleOnChange}
                                key={c}
                                className={index}
                                placeholder="Enter width"
                              />
                            </Input>
                          </Col>
                        );
                      })}

                      <div className="add_more_fields">
                        <button className='mt-1 mb-2' type="button" onClick={handleClick}><IoMdAdd /> Add more fields</button>
                      </div>

                      <Col md={6}>
                        <Input>
                          <Form.Label>Additional Instructions:</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder='Enter additional instruction'
                            {...register('additional_instruction', MOOrderSaveValidation.additional_instruction)}
                          />
                        </Input>
                        {errors.additional_instruction ? (
                          <small className="text-danger">
                            {errors.additional_instruction && errors.additional_instruction.message}
                          </small>
                        ) : null}
                      </Col>

                      <Col md={6}>
                        <Input>
                          <Form.Label>Select Operator</Form.Label>
                          <div id="users_container">
                            <ReactSearchAutocomplete
                              placeholder="Search any customer"
                              onClear={() => setOperatorID('')}
                              items={users?.data?.rows}
                              onSearch={handleOnSearch}
                              fuseOptions={{ keys: ['first_name', 'last_name'] }}
                              autoFocus
                              onSelect={handleOnSelect}
                              resultStringKeyName='first_name'
                              formatResult={formatResult}
                              maxResults={15}
                            />
                          </div>
                        </Input>
                      </Col>
                    </Row>
                    <Col md={12}>
                      <div className="d-flex justify-content-end py-3">
                        <Button type="submit">
                          {
                            loading ? <Spinner animation='border' size='sm' /> : "Upload Order"
                          }
                        </Button>
                      </div>
                    </Col>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </div>
  )
}

export default MOOrderSave
