import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import MuiDataTable from "../../../../Components/MUIDataTable/MUIDataTable";
import { getUsersByRoles } from '../../../../Redux/Actions/admin';
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../Utils/Loader";
import { Link, useNavigate } from 'react-router-dom';
import eyeIcon from '../../../../Assets/images/eye.png';

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const AllCustomers = () => {
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const dispatch: AppDispatch = useDispatch()
  const { users, loading, userCount } = useSelector((state: any) => state.allUsersByRole);
  const [text, setText] = useState('')
  const [showall, setShowAll] = useState('')

  useEffect(() => {
    dispatch(getUsersByRoles("Customer", page, rowsPerPage, text, showall))
  }, [page, rowsPerPage, text, dispatch, showall])

  const customersColumns = [
    {
      name: "id",
      options: {
        display: false
      }
    },
    { name: "sno", label: "S:NO" },
    { name: "username", label: "USERNAME" },
    { name: 'first_name', label: 'FIRST NAME' },
    { name: 'last_name', label: "LAST NAME" },

    {
      name: "View Details",
      label: "DETAILS",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <div className="action_container">
              <Link to={`/admin/edit-customer-detail/${tableMeta.rowData[0]}`}>
                <button
                  className="view-btn"
                  type="button">
                  <img src={eyeIcon} alt="eye" />
                </button>
              </Link>
            </div>
          )
        }
      }
    }
  ]

  let newData: any = [];
  users?.data?.rows?.map((item: any, index: any) => {
    newData.push({ sno: rowsPerPage * page + index + 1, ...item });
  });

  return (
    <div className="page_responsive">
      <Row>
        <Col md={12} className="p-2 my-3 all-customers-table">
          {!text && loading ? <Loader /> :
            <MuiDataTable
              title={"ALL CUSTOMERS"}
              data={newData}
              columns={customersColumns}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              count={userCount}
              text={text}
              setText={setText}
            />
          }
        </Col>
      </Row>
    </div>
  );
};

export default AllCustomers;
