import React from "react";
import SalesNavbar from "../../Container/Sales/Routes/Navbar";
import { Outlet } from "react-router-dom";
import NotFound from "../../Container/Pages/NotFound/NotFound";

const SalesLayout = () => {
    let userAuth: any = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "") : null;

    return userAuth?.role?.name === "Sales" ? (
        <React.Fragment>
            <SalesNavbar />
            <Outlet />
        </React.Fragment>
    ) : (
        <NotFound />
    );
}
export default SalesLayout;
