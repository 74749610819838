import { IRoutesLink, ISideBarRoutes } from "../../../Utils/interfaces";

// User Routes
import Login from "../../Pages/Auth/UserLogin";
import Home from "../../Pages/Home/Home";
import Register from "../../Pages/Auth/Register";
import NotFound from "../../Pages/NotFound/NotFound";

// ICONS

import dashboardIcon from '../../../Assets/images/dashboard-nav-icon.png';
import QuoteRecordsIcon from '../../../Assets/images/quotes-nav-icon.png';
import vectorRecordsIcon from '../../../Assets/images/all-vector-orders-nav-icon.png';
import InvoicesIcon from '../../../Assets/images/all-invoices-nav-icon.png'
import usersIcon from '../../../Assets/images/users-nav-icon.png'
import allCustomersIcon from '../../../Assets/images/all-customers-nav-icon.png'
import myAccountIcon from '../../../Assets/images/my-account-nav-icon.png';
import allOrdersIcon from '../../../Assets/images/all-orders-nav-icon.png'
import LockICon from '../../../Assets/images/lock-nav-icon.png';
import plusIcon from '../../../Assets/images/plus.png';

// Admin Routes
import Dashboard from "../Pages/Dashboard/Dashboard";
import AllQuotes from "../Pages/AllQuotes/AllQuotes";
import QuoteDetails from "../Pages/AllQuotes/QuoteDetails/QuoteDetails";
import AllOrders from "../Pages/AllOrders/AllOrders";
import AllVectorOrders from "../Pages/AllVectorOrders/AllVectorOrders";
import VectorOrderDetails from "../Pages/AllVectorOrders/VectorDetails/VectorOrderDetails";
import AllInvoices from "../Pages/AllInvoices/AllInvoices";
import InvoiceDetails from "../Pages/AllInvoices/InvoiceDetail/InvoiceDetails";
import AllCustomers from "../Pages/AllCustomers/AllCustomers";
import OrderDetails from "../Pages/AllOrders/OrderDetaill/OrderDetails";
import AssignOrder from "../Pages/AllOrders/AssignOrder/AssignOrder";
import ApprovedQuote from "../Pages/AllQuotes/ApprovedQuote/ApprovedQuote"
import AddUser from "../Pages/Users/AddUser/AddUser";
import AllUsers from "../Pages/Users/AllUsers/AllUsers";
import EditUser from "../Pages/Users/AllUsers/EditUser/EditUser";
import EditCustomer from "../Pages/AllCustomers/CustomerDetails/CustomerDetails";
import CustomerDetails from "../Pages/AllQuotes/CustomerDetails/CustomerDetails";
import SendToCustomer from "../Pages/AllVectorOrders/VectorDetails/SendToCustomer/SendToCustomer";
import GenerateInvoice from "../Pages/AllInvoices/GenerateInvoice/GenerateInvoice";
import MyProfile from "../../Customer/Pages/MyProfile/MyProfile";
import ChangePassword from "../../Customer/Pages/MyProfile/ChangePassword/ChangePassword";
import AssignVector from "../Pages/AllVectorOrders/AssignVector/AssignVector"

// Admin MO Orders
import MOOrderSave from "../Pages/MOOrderSave/MOOrderSave";
import MOOrderList from "../Pages/MOOrderSave/MOOrderList/MOOrderList";
import MOOrderDetails from "../Pages/MOOrderSave/MOOrderDetails/MOOrderDetails";

// =================== USER ROUTES ==================

export const userRoutes = [
  {
    path: "/admin/login",
    component: <Login />,
  },
  {
    path: "/user/registration",
    component: <Register />,
  },
  {
    path: "/user/login",
    component: <Login />,
  },
  {
    path: "/",
    component: <Home />,
  },
  {
    path: "*",
    component: <NotFound />,
  },
];

// Admin Routes

export const adminRoutes: IRoutesLink[] = [
  {
    path: "/admin/dashboard",
    component: <Dashboard />,
  },

  {
    path: "/admin/all-quotes",
    component: <AllQuotes />,
  },

  {
    path: "/admin/all-orders",
    component: <AllOrders />,
  },
  {
    path: "/admin/order-details/:id",
    component: <OrderDetails />,
  },
  {
    path: "/admin/quote-details/:id",
    component: <QuoteDetails />,
  },
  {
    path: "/admin/customer-details/:id",
    component: <CustomerDetails />,
  },
  {
    path: "/admin/vector-order-details/:id",
    component: <VectorOrderDetails />,
  },
  {
    path: "/admin/vector-order-attachment/:id",
    component: <SendToCustomer />,
  },
  {
    path: "/admin/assign-order/:id",
    component: <AssignOrder />
  },
  {
    path: "/admin/quote-approved/:id",
    component: <ApprovedQuote />
  },
  {
    path: "/admin/all/vector-record",
    component: <AllVectorOrders />,
  },
  {
    path: "/admin/invoice-record",
    component: <AllInvoices />,
  },
  {
    path: "/admin/invoice-details/:id",
    component: <InvoiceDetails />,
  },
  {
    path: "/admin/generate-invoice/:id",
    component: <GenerateInvoice />,
  },
  {
    path: "/admin/customers",
    component: <AllCustomers />,
  },
  {
    path: "/admin/edit-customer-detail/:id",
    component: <EditCustomer />,
  },
  {
    path: "/admin/add-user",
    component: <AddUser />
  },
  {
    path: "/admin/all-users",
    component: <AllUsers />
  },
  {
    path: "/admin/edit-user/:id",
    component: <EditUser />
  },
  {
    path: "/admin/profile",
    component: <MyProfile />
  },
  {
    path: "/admin/reset-password",
    component: <ChangePassword />
  },
  {
    path: "/admin/mo-order-save",
    component: <MOOrderSave />
  },
  {
    path: "/admin/mo-orders-list",
    component: <MOOrderList />
  },
  {
    path: "/admin/mo-order-details/:id",
    component: <MOOrderDetails />
  },

  {
    path: "/admin/vector-assign-order/:id",
    component: <AssignVector />
  },

];

export const adminSideBarItems: ISideBarRoutes[] = [
  {
    path: "/admin/dashboard",
    icon: <img src={dashboardIcon} alt="dashboard-icon" />,
    title: "Dashboard",
    isSubNav: false,
  },
  {
    path: "/admin/all-quotes",
    icon: <img src={QuoteRecordsIcon} alt="quotes-icon" />,
    title: "All Quotes",
    isSubNav: false,
  },
  {
    path: "/admin/all-orders",
    icon: <img src={allOrdersIcon} alt="order-icon" />,
    title: "Digitizing Orders",
    isSubNav: false,
  },
  {
    path: "/admin/all/vector-record",
    icon: <img src={vectorRecordsIcon} alt="vector-order-icon" />,
    title: "All Vector Orders",
    isSubNav: false,
  },
  {
    path: "/admin/mo-order-save",
    icon: <img src={allOrdersIcon} alt="vector-order-icon" />,
    title: "MO Orders Save",
    isSubNav: false,
  },
  {
    path: "/admin/mo-orders-list",
    icon: <img src={vectorRecordsIcon} alt="vector-order-icon" />,
    title: "MO Orders List",
    isSubNav: false,
  },
  {
    path: "/admin/invoice-record",
    icon: <img src={InvoicesIcon} alt="invoices-icon" />,
    title: "All Invoices",
    isSubNav: false,
  },
  {
    path: "",
    icon: <img src={usersIcon} alt="invoices-icon" />,
    title: "Users",
    isSubNav: true,
    subNav: [
      {
        path: "/admin/add-user",
        component: <AddUser />,
        icon: <img src={plusIcon} alt="add-icon" />,
        title: "Add User"
      },
      {
        path: "/admin/all-users",
        component: <AllUsers />,
        icon: <img src={allCustomersIcon} alt="users-icon" />,
        title: "All Users"
      },
    ]
  },
  {
    path: "/admin/customers",
    icon: <img src={allCustomersIcon} alt="customers-icon" />,
    title: "All Customers",
    isSubNav: false,
  },

  {
    path: "",
    icon: <img src={myAccountIcon} alt="account-icon" />,
    title: "My Account",
    isSubNav: true,
    subNav: [
      {
        path: "/admin/profile",
        component: <MyProfile />,
        icon: <img src={myAccountIcon} alt="account-icon" />,
        title: "My Profile"
      },
      {
        path: "/admin/reset-password",
        component: <ChangePassword />,
        icon: <img src={LockICon} alt="account-icon" />,
        title: "Change Password"
      },
    ]
  },
];
