import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { getUserQuotesDetails } from "../../../../../Redux/Actions/UserQuotes";
import { errorNotify, successNotify } from "../../../../../Utils/toast";
import Loader from "../../../../../Utils/Loader";
import { deleteAdminQuotesRecords } from "../../../../../Redux/Actions/admin";
import moment from "moment";
import Modal from '../../../../../Components/SiteModal/SiteModal';
import { downloadFile, cloudBaseURL } from '../../../../../Utils/helper'
import QuoteIcon from "../../../../../Assets/images/quote.png";

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const QuoteDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { error, loading, getSingleQuote } = useSelector((state: any) => state.indivisualQuote);
  const { deletequotes, loading: DeletedLoading } = useSelector((state: any) => state.adminDeleteQuotes);
  const [show, setShow] = React.useState(false);
  const customerID = getSingleQuote?.data?.Customer?.id;

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (error) {
      errorNotify(error);
      dispatch({ type: "CLEAR_ERRORS" });
    }
    dispatch(getUserQuotesDetails(id));
  }, []);

  useEffect(() => {
    if (deletequotes && deletequotes.status === 1) {
      successNotify('Quote Deleted Successfully')
      setShow(false)
      navigate("/admin/all-quotes")
      dispatch({ type: "QUOTES_DELETE_RESET" })
    }

  }, [deletequotes])

  const quoteDeleteHandler = (id: any) => {
    dispatch(deleteAdminQuotesRecords(id))
  }

  const QuoteIdHandler = () => {
    setShow(!show)
  }


  const modal = (
    <Modal title="Delete" size={'lg'} show={show} onCloseModal={() => setShow(!show)}>
      <React.Fragment>
        <p>Are u sure you want to delete this quote?</p>
        <div className="modal_decision">
          <button className="my-1 details_btn bg-red" onClick={() => quoteDeleteHandler(id)}>
            {DeletedLoading ? <Spinner animation='border' size='sm' /> : "Yes"}
          </button>
          <button className="my-1 details_btn sent_order" onClick={() => setShow(false)}>No</button>
        </div>
      </React.Fragment>
    </Modal >
  )

  return (
    <div className="page_responsive">
      <div className="card ">
        <div className="send_quotes_head_container">
          <div className='send_quote_div_shorter'>
            <img src={QuoteIcon} alt="pencil-logo" />
          </div>
          <h4>Quote Details</h4>
        </div>
      </div>
      <div className="user_details_card">
        <Card className="order_details_card">
          {loading ? (
            <Loader />
          ) : (
            <Card.Body>
              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">QUOTE ID:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">{getSingleQuote?.data?.id}</Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Quote Name:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {getSingleQuote?.data?.quote_name}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Order Date:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {moment(getSingleQuote?.data?.createdAt).format(
                          "DD-MM-YYYY"
                        )}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Customer Username:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {getSingleQuote?.data?.Customer?.username}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Customer Full Name:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {getSingleQuote?.data?.Customer?.first_name}
                        {getSingleQuote?.data?.Customer?.last_name}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Required Format:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {getSingleQuote?.data?.Format?.name}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Height:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">{getSingleQuote?.data?.height}</Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Width:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">{getSingleQuote?.data?.width}</Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Fabric:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {getSingleQuote?.data?.Fabric?.name}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Placement:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {getSingleQuote?.data?.Placement?.name}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Number of Colors:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {getSingleQuote?.data?.no_color === null
                          ? "-"
                          : getSingleQuote?.data?.no_color}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Super Urgent:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {parseInt(getSingleQuote?.data?.urgent) === 1 ? "urgent" : "not-urgent"}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Status:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {parseInt(getSingleQuote?.data?.status) === 1
                          ? "Approved"
                          : "Pending"}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Price:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {parseInt(getSingleQuote?.data?.price) <= 0
                          ? "Price not selected"
                          : (getSingleQuote?.data?.Customer?.Country?.currency_symbol) + (getSingleQuote?.data?.price)}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="justify-content-around my-1">
                <Col md={11} className="m-1 detail_field">
                  <Row>
                    <Col md={12}>
                      <Typography variant="h6">
                        Additional Instruction:{" "}
                      </Typography>
                      <Typography>
                        {getSingleQuote?.data?.additional_instruction}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>


              {(getSingleQuote?.data?.attachments?.length > 0) && (
                <Row className="justify-content-around my-1">
                  <Col md={11} className="m-1">
                    <Typography variant="h4">Attachments:</Typography>
                  </Col>
                </Row>
              )}
              <Row className="justify-content-around my-1">
                {(getSingleQuote?.data?.attachments) &&
                  getSingleQuote?.data?.attachments.map(
                    (attachment: any, i: any) => (
                      <Col md={11} className="m-1 detail_field">
                        <Row className="align-items-center">
                          <Col md={4}>
                            <Typography variant="h6">File {i + 1}:</Typography>
                          </Col>
                          <Col md={4} className="text-center">
                            <Typography >
                              {attachment.attachment_name}
                            </Typography>
                          </Col>
                          <Col md={4} className="d-flex justify-content-end">
                            <button className="download_btn" onClick={() => downloadFile(`${cloudBaseURL}quote/${id}/${attachment.attachment_name}`, `${attachment.attachment_name}`)}>
                              <FaDownload /> Download
                            </button>
                          </Col>
                        </Row>
                      </Col>
                    )
                  )}
              </Row>

              <Row className="pt-4 justify-content-center">
                <Col xs={12} md={12} className='text-end'>
                  <button className="my-1 mx-2 details_btn bg-gray"
                    id={`${parseInt(getSingleQuote?.data?.status) === 1 ? 'disabled_btn' : null}`}
                    disabled={parseInt(getSingleQuote?.data?.status) === 1 ? true : false}
                    onClick={() =>
                      navigate(
                        `/admin/quote-approved/${getSingleQuote?.data?.id}`
                      )
                    }
                  >
                    Approved Quote
                  </button>


                  <button className="my-1 mx-2 details_btn sent_order" onClick={() => navigate(`/admin/customer-details/${customerID}`)}>
                    Customer Details
                  </button>


                  <button className="my-1 details_btn bg-red" onClick={() => QuoteIdHandler()}>
                    {
                      DeletedLoading ? <Spinner animation="border" size='sm' /> : "Delete Quote"
                    }

                  </button>
                </Col>
              </Row>
            </Card.Body>
          )}
        </Card>
      </div>
      {modal}
    </div>
  );
};

export default QuoteDetails;
