import React, { useEffect } from "react";
import { Button, Col, Form, FormGroup, FormLabel, Row, Spinner } from "react-bootstrap";
import "./Register.css";
import { useForm, SubmitHandler } from "react-hook-form";
import { RegistrationInput } from "../../../Utils/interfaces";
import { registerValidation } from "../../../Utils/validation";
import Logo from "../../../Assets/images/new-logo.png";
import { errorNotify, successNotify } from "../../../Utils/toast";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { RegisterCustomer } from "../../../Redux/Actions/Auth";
type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const Register = () => {
  const dispatch: AppDispatch = useDispatch();
  const { error, loading, isRegistered } = useSelector((state: any) => state.Register);
  const {register,handleSubmit,reset,formState: { errors }} = useForm<RegistrationInput>();
  useEffect(() => {
    if (isRegistered?.message) {
      successNotify(isRegistered?.message)
      reset();
      dispatch({ type: "REGISTER_RESET" }) 
    }
    if (error) {
      errorNotify(error)
      dispatch({ type: "CLEAR_ERRORS" })
    }
  }, [isRegistered, error])

  const registerFormSubmit: SubmitHandler<RegistrationInput> = async (data: any) => {
    const registerData = {
      username: data.username,
      email: data.email,
      password: data.password,
      company_name: data.company_name,
      company_type: data.company_type,
      reference: data.reference,
      address: data.address,
      phone: data.phone_number,
      cell: data.cell_number,
      fax: data.fax,
      city: data.city,
      state: data.state,
      country: data.country,
      zip_code: data.zip_code,
      postal_code: data.postal_code,
      first_name: data.first_name,
      last_name: data.last_name,
    };
    dispatch(RegisterCustomer(registerData));
  };

  return (
    <div className="register-page-container">
      <div className="col-md-8 col-lg-6 register-form-container p-3 p-md-4">
        <Row>
          <Col md={12}>
            <img src={Logo} alt="" />
            <Form
              className="registration-form"
              onSubmit={handleSubmit(registerFormSubmit)}
            >
              <Row>
                <Col lg={6}>
                  <FormGroup className="py-2 w-100">
                    <FormLabel className="text-muted">User Name:</FormLabel>
                    <Form.Control
                      type="text"
                      placeholder={"Enter your username *"}
                      {...register("username", registerValidation.username)}
                    />
                    {errors.username && (
                      <span className="error_msg">
                        {errors.username.message}
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup className="py-2 w-100">
                    <FormLabel className="text-muted">Password:</FormLabel>
                    <Form.Control
                      type="password"
                      placeholder={"Enter your password *"}
                      {...register("password", registerValidation.password)}
                    />
                    {errors.password && (
                      <span className="error_msg">
                        {errors.password.message}
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <FormGroup className="py-2 w-100">
                    <FormLabel className="text-muted">Email:</FormLabel>
                    <Form.Control
                      type="email"
                      placeholder={"Enter your email *"}
                      {...register("email", registerValidation.email)}
                    />
                    {errors.email && (
                      <span className="error_msg">{errors.email.message}</span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup className="py-2 w-100">
                    <FormLabel className="text-muted">
                      Alternative Email:
                    </FormLabel>
                    <Form.Control
                      type="email"
                      placeholder={"Enter your alternative email *"}
                      {...register(
                        "alternate_email",
                        registerValidation.alternate_email
                      )}
                    />
                    {errors.alternate_email && (
                      <span className="error_msg">
                        {errors.alternate_email.message}
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <FormGroup className="py-2 w-100">
                    <FormLabel className="text-muted">First Name:</FormLabel>
                    <Form.Control
                      type="text"
                      placeholder={"Enter your first name *"}
                      {...register("first_name", registerValidation.first_name)}
                    />
                    {errors.first_name && (
                      <span className="error_msg">
                        {errors.first_name.message}
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup className="py-2 w-100">
                    <FormLabel className="text-muted">Last Name:</FormLabel>
                    <Form.Control
                      type="text"
                      placeholder={"Enter your last name *"}
                      {...register("last_name", registerValidation.last_name)}
                    />
                    {errors.last_name && (
                      <span className="error_msg">
                        {errors.last_name.message}
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <FormGroup className="py-2 w-100">
                    <FormLabel className="text-muted">Company Name:</FormLabel>
                    <Form.Control
                      type="text"
                      placeholder={"Enter your company name *"}
                      {...register(
                        "company_name",
                        registerValidation.company_name
                      )}
                    />
                    {errors.company_name && (
                      <span className="error_msg">
                        {errors.company_name.message}
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup className="py-2 w-100">
                    <FormLabel className="text-muted">Company Type:</FormLabel>

                    <Form.Select
                      {...register(
                        "company_type",
                        registerValidation.company_type
                      )}
                    >
                      <option>Company Type</option>
                      <option value={1}>Distributor</option>
                      <option value={2}>Promotional</option>
                      <option value={3}>Marketing</option>
                      <option value={4}>Manufacturer</option>
                      <option value={5}>Uniform/ Apparels</option>
                      <option value={6}>Other</option>
                    </Form.Select>
                    {errors.company_type && (
                      <span className="error_msg">
                        {errors.company_type.message}
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <FormGroup className="py-2 w-100">
                    <FormLabel className="text-muted">
                      Select Reference:
                    </FormLabel>
                    <Form.Select
                      placeholder="Select Reference *"
                      {...register("reference", registerValidation.reference)}
                    >
                      <option defaultValue={""}></option>
                      <option value={1}> Search Engine</option>
                      <option value={2}>Sales Man</option>
                      <option value={3}>Customer Reference</option>
                      <option value={4}>Other</option>
                    </Form.Select>
                    {errors.reference && (
                      <span className="error_msg">
                        {errors.reference.message}
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup className="py-2 w-100">
                    <FormLabel className="text-muted">Fax:</FormLabel>
                    <Form.Control
                      type="text"
                      placeholder={"Enter your fax *"}
                      {...register("fax", registerValidation.fax)}
                    />
                    {errors.fax && (
                      <span className="error_msg">{errors.fax.message}</span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              {/* <Col lg={6}> */}
              <FormGroup className="py-2 w-100">
                <FormLabel className="text-muted">Select Country:</FormLabel>
                <Form.Select
                  {...register("country", registerValidation.country)}
                >
                  <option>Choose Your Country *</option>
                  <option value={256}>USA</option>
                  <option value={255}>UK</option>
                </Form.Select>
                {errors.country && (
                  <span className="error_msg">{errors.country.message}</span>
                )}
              </FormGroup>
              {/* </Col> */}
              <Row>
                <Col lg={6}>
                  <FormGroup className="py-2 w-100">
                    <FormLabel className="text-muted">Phone Number:</FormLabel>
                    <Form.Control
                      type="text"
                      placeholder={"Enter your phone *"}
                      {...register(
                        "phone_number",
                        registerValidation.phone_number
                      )}
                    />
                    {errors.phone_number && (
                      <span className="error_msg">
                        {errors.phone_number.message}
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup className="py-2 w-100">
                    <FormLabel className="text-muted">Cell Number:</FormLabel>
                    <Form.Control
                      type="text"
                      placeholder={"Enter your cell Number *"}
                      {...register(
                        "cell_number",
                        registerValidation.cell_number
                      )}
                    />
                    {errors.cell_number && (
                      <span className="error_msg">
                        {errors.cell_number.message}
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup className="py-2 w-100">
                <FormLabel className="text-muted">Address:</FormLabel>
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="Enter Your Address"
                  {...register("address", registerValidation.address)}
                />
                {errors.address && (
                  <span className="error_msg">{errors.address.message}</span>
                )}
              </FormGroup>
              <Row>
                <Col lg={6}>
                  <FormGroup className="py-2 w-100">
                    <FormLabel className="text-muted">Postal Code:</FormLabel>
                    <Form.Control
                      type="text"
                      placeholder={"Enter your postal code *"}
                      {...register(
                        "postal_code",
                        registerValidation.postal_code
                      )}
                    />
                    {errors.postal_code && (
                      <span className="error_msg">
                        {errors.postal_code.message}
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup className="py-2 w-100">
                    <FormLabel className="text-muted">Zip Code:</FormLabel>
                    <Form.Control
                      type="text"
                      placeholder={"Enter your zip code *"}
                      {...register("zip_code", registerValidation.zip_code)}
                    />
                    {errors.zip_code && (
                      <span className="error_msg">
                        {errors.zip_code.message}
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <FormGroup className="py-2 w-100">
                    <FormLabel className="text-muted">State:</FormLabel>
                    <Form.Control
                      type="text"
                      placeholder={"Enter your state *"}
                      {...register("state", registerValidation.state)}
                    />
                    {errors.state && (
                      <span className="error_msg">{errors.state.message}</span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup className="py-2 w-100">
                    <FormLabel className="text-muted">City:</FormLabel>
                    <Form.Control
                      type="text"
                      placeholder={"Enter your city *"}
                      {...register("city", registerValidation.city)}
                    />
                    {errors.city && (
                      <span className="error_msg">{errors.city.message}</span>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <Row></Row>

              <div className="text-center">
                <Button type="submit" className={"mt-3 sign-up-btn"}>
                  {
                    loading ? <Spinner animation="border" size="sm" /> : "Sign Up"
                  }
                </Button>
              </div>
            </Form>
            <p className="text-center pt-4">
              Already have an account? <Link to="/user/login"> Sign In</Link>
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Register;
