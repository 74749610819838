import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import { Card, Col, Row } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";
import "./DigitizingOrderDetails.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { errorNotify } from "../../../../../Utils/toast";
import { getUserOrderDetails } from "../../../../../Redux/Actions/UserOrders";
import Loader from "../../../../../Utils/Loader";
import moment from 'moment'
import { downloadFile, cloudBaseURL, CurrentUserInfo } from '../../../../../Utils/helper'
import OrderIcon from "../../../../../Assets/images/orders.png";


type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;
const DigitizingOrderDetails = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const { id } = useParams();
  const { error, loading, orderDetails } = useSelector((state: any) => state.userOrderDetails);
  const dispatch: AppDispatch = useDispatch();
  const CurrencySymbol = CurrentUserInfo()?.country?.currency_symbol;
  useEffect(() => {
    if (error) {
      errorNotify(error);
      dispatch({ type: "CLEAR_ERRORS" });
    }
    dispatch(getUserOrderDetails(id));
  }, []);

  return (
    <div className="page_responsive">
      <div className="card d-flex">
        <div className="send_quotes_head_container" id="digitizer_order">
          <div className='send_digitizing_order_div_shorter'>
            <img src={OrderIcon} alt="pencil-logo" />
          </div>
          <h4>Digitizing Order Details</h4>
        </div>
      </div>
      <div className="user_details_card">
        <Card className="order_details_card">
          {loading ? (
            <Loader />
          ) : (
            <Card.Body>
              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Order ID:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">{orderDetails?.data?.order_id}</Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Order Name:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">{orderDetails?.data?.order_name}</Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Order Date:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">{moment(orderDetails?.data?.createdAt).utc().format('DD-MM-YYYY')}</Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Customer Username:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {orderDetails?.data?.Customer?.username}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Customer Full Name:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {orderDetails?.data?.Customer?.first_name}{" "}
                        {orderDetails?.data?.Customer?.last_name}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Required Format:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {orderDetails?.data?.Format?.name}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Height:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">{orderDetails?.data?.height}</Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Width:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">{orderDetails?.data?.width}</Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Fabric:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {orderDetails?.data?.Fabric?.name}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Placement:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {orderDetails?.data?.Placement?.name}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Number of Colors:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">{orderDetails?.data?.no_color}</Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Super Urgent:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {parseInt(orderDetails?.data?.urgent) === 0
                          ? "not-urgent"
                          : "urgent"}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Status:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {parseInt(orderDetails?.data?.status) === 0 ? "pending" : parseInt(orderDetails?.data?.status) === 1 ? "in-process" : parseInt(orderDetails?.data?.status) === 2 ? "completed" : '-'}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Price:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">{orderDetails?.data?.price === 0 ? "Price not selected" : `${CurrencySymbol}${orderDetails?.data?.price}`}</Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="justify-content-around my-1">
                <Col md={11} className="m-1 detail_field">
                  <Row>
                    <Col md={12}>
                      <Typography variant="h6">
                        Additional Instruction:{" "}
                      </Typography>
                      <Typography >
                        {orderDetails?.data?.additional_instruction}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {(orderDetails?.data?.attachments?.length > 0) && (
                <Row className="justify-content-around my-1">
                  <Col md={11} className="m-1">
                    <Typography variant="h4">Customer Attachments:</Typography>
                  </Col>
                </Row>
              )}
              <Row className="justify-content-around my-1">
                {(orderDetails?.data?.attachments) &&
                  orderDetails?.data?.attachments.map(
                    (attachment: any, i: any) => {
                      if (parseInt(attachment?.attachment_type) === 0) {
                        return (
                          <Col md={11} className="m-1 detail_field">
                            <Row className="align-items-center">
                              <Col md={4}>
                                <Typography variant="h6">File {i + 1}:</Typography>
                              </Col>
                              <Col md={4}>
                                <Typography className="text-center">
                                  {attachment.attachment_name}
                                </Typography>
                              </Col>
                              <Col md={4} className="d-flex justify-content-end">
                                <button className="download_btn" onClick={() => downloadFile(`${cloudBaseURL}digitizing/${id}/${attachment.attachment_name}`, `${attachment.attachment_name}`)}>
                                  <FaDownload /> Download
                                </button>
                              </Col>
                            </Row>
                          </Col>
                        )
                      }
                    }
                  )}
              </Row>

              {(parseInt(orderDetails?.data?.status) === 2 && orderDetails?.data?.attachments) && (
                <Row className="justify-content-around my-1">
                  <Col md={11} className="m-1">
                    <Typography variant="h4">Digitizer Attachments:</Typography>
                  </Col>
                </Row>
              )}
              <Row className="justify-content-around my-1">
                {(orderDetails?.data?.attachments && parseInt(orderDetails?.data?.status) === 2) &&
                  orderDetails?.data?.attachments.map(
                    (attachment: any, i: any) => {
                      if (parseInt(attachment?.attachment_type) === 1) {
                        return (
                          <Col md={11} className="m-1 detail_field">
                            <Row className="align-items-center">
                              <Col md={4}>
                                <Typography variant="h6">File {i + 1}:</Typography>
                              </Col>
                              <Col md={4}>
                                <Typography className="text-center">
                                  {attachment.attachment_name}
                                </Typography>
                              </Col>
                              <Col md={4} className="d-flex justify-content-end">
                                <button className="download_btn" onClick={() => downloadFile(`${cloudBaseURL}digitizing/${id}/${attachment.attachment_name}`, `${attachment.attachment_name}`)}>
                                  <FaDownload /> Download
                                </button>
                              </Col>
                            </Row>
                          </Col>
                        )
                      }
                    }
                  )}
              </Row>
              <Row className="pt-4 justify-content-end">
                <Col md={11} className="p-0 button_container_main">
                  <button
                    className="my-1 details_btn bg-gray"
                    onClick={() => navigate("/user/all-orders")}
                  >
                    Go Back
                  </button>

                  {
                    parseInt(orderDetails?.data?.status) === 2 ?
                      <button className="my-1 details_btn edit_order" onClick={() => navigate(`/user/customer/update-order-details/${id}`, {
                        state: { orderDetails }
                      })}>Edit Order</button>
                      :
                      <button id='disabled_btn' className="my-1 details_btn" disabled={true}>
                        Edit Order
                      </button>
                  }
                </Col>
              </Row>
            </Card.Body>
          )}
        </Card>
      </div>
    </div>
  );
};

export default DigitizingOrderDetails;
