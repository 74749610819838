const regex = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

export const authValidation = {
  email: {
    required: {
      value: true,
      message: "Email cannot be empty",
    },
    pattern: {
      value: regex.email,
      message: "Email is not valid",
    },
  },
  username: {
    required: {
      value: true,
      message: "username or email cannot be empty",
    },
  },
  password: {
    required: {
      value: true,
      message: "Password cannot be empty",
    },
  },
};

export const registerValidation = {
  username: {
    required: {
      value: true,
      message: "Username cannot be empty",
    },
  },
  password: {
    required: {
      value: true,
      message: "Password cannot be empty",
    },
    minLength: {
      value: 8,
      message: "Password length must be greater than 7",
    },
  },
  email: {
    required: {
      value: true,
      message: "Email cannot be empty",
    },
    pattern: {
      value: regex.email,
      message: "Email is not valid",
    },
  },
  alternate_email: {
    required: {
      value: true,
      message: "Email cannot be empty",
    },
    pattern: {
      value: regex.email,
      message: "Email is not valid",
    },
  },
  first_name: {
    required: {
      value: true,
      message: "First Name cannot be empty",
    },
  },
  last_name: {
    required: {
      value: true,
      message: "Last Name cannot be empty",
    },
  },
  company_name: {
    required: {
      value: true,
      message: "Company Name cannot be empty",
    },
  },
  company_type: {
    required: {
      value: true,
      message: "Company type cannot be empty",
    },
  },
  reference: {
    required: {
      value: true,
      message: "Reference cannot be empty",
    },
  },
  fax: {
    required: {
      value: true,
      message: "Fax cannot be empty",
    },
  },
  country: {
    required: {
      value: true,
      message: "Country cannot be empty",
    },
  },
  phone_number: {
    required: {
      value: true,
      message: "Phone Number cannot be empty",
    },
  },
  cell_number: {
    required: {
      value: true,
      message: "Cell Number cannot be empty",
    },
  },
  address: {
    required: {
      value: true,
      message: "Address cannot be empty",
    },
  },
  postal_code: {
    required: {
      value: true,
      message: "Postal Code cannot be empty",
    },
  },
  zip_code: {
    required: {
      value: true,
      message: "Zip Code cannot be empty",
    },
  },
  state: {
    required: {
      value: true,
      message: "State cannot be empty",
    },
  },
  city: {
    required: {
      value: true,
      message: "City cannot be empty",
    },
  },
};

export const sendQuotes = {
  quote_name: {
    required: {
      value: true,
      message: "Quote Name cannot be empty",
    },
  },

  format_id: {
    required: {
      value: true,
      message: "Required Format cannot be empty",
    },
  },
  height: {
    required: {
      value: true,
      message: "Height cannot be empty",
    },
  },
  width: {
    required: {
      value: true,
      message: "Width Name cannot be empty",
    },
  },
  fabric_id: {
    required: {
      value: true,
      message: " Fabric cannot be empty",
    },
  },
  placement_id: {
    required: {
      value: true,
      message: " Placement cannot be empty",
    },
  },
  no_color: {
    required: {
      value: true,
      message: " No of colors cannot be empty",
    },
  },
  additional_instruction: {
    required: {
      value: true,
      message: " Additional instruction cannot be empty",
    },
  },
  file1: {
    required: {
      value: true,
      message: " File 1 cannot be empty",
    },
  },

  file2: {
    required: {
      value: true,
      message: " File 2 cannot be empty",
    },
  },
};
export const sendOrder = {
  order_name: {
    required: {
      value: true,
      message: "Order Name cannot be empty",
    },
  },

  format_id: {
    required: {
      value: true,
      message: "Required Format cannot be empty",
    },
  },
  height: {
    required: {
      value: true,
      message: "Height cannot be empty",
    },
  },
  width: {
    required: {
      value: true,
      message: "Width Name cannot be empty",
    },
  },
  fabric_id: {
    required: {
      value: true,
      message: " Fabric cannot be empty",
    },
  },
  placement_id: {
    required: {
      value: true,
      message: " Placement cannot be empty",
    },
  },
  no_color: {
    required: {
      value: true,
      message: " No of colors cannot be empty",
    },
  },
  additional_instruction: {
    required: {
      value: true,
      message: " Additional instruction cannot be empty",
    },
  },
  file1: {
    required: {
      value: true,
      message: " File 1 cannot be empty",
    },
  },

  file2: {
    required: {
      value: true,
      message: " File 2 cannot be empty",
    },
  },
};

export const VectorOrderValidation = {
  vector_name: {
    required: {
      value: true,
      message: "Vector name cannot be empty",
    },
  },

  format_id: {
    required: {
      value: true,
      message: "Required Format cannot be empty",
    },
  },

  no_color: {
    required: {
      value: true,
      message: " No of colors cannot be empty",
    },
  },
  additional_instruction: {
    required: {
      value: true,
      message: " Additional instruction cannot be empty",
    },
  },
  file1: {
    required: {
      value: true,
      message: " File 1 cannot be empty",
    },
  },

  file2: {
    required: {
      value: true,
      message: " File 2 cannot be empty",
    },
  },
};

export const changePassValidation = {
  current_password: {
    required: {
      value: true,
      message: "Old Password cannot be empty",
    },
  },

  new_password: {
    required: {
      value: true,
      message: "New Password cannot be empty",
    },
  },

  conf_password: {
    required: {
      value: true,
      message: "Confirm Password cannot be empty",
    },
  },
};



export const AssingAnOrderValidtion = {
  order_name: {
    required: {
      value: true,
      message: "Order name cannot be empty",
    },
  },

  digitizer_id: {
    required: {
      value: true,
      message: "Digitizer cannot be empty",
    },
  },

};
export const ApprovedQuoteValidtion = {
  price: {
    required: {
      value: true,
      message: "Price cannot be empty",
    },
  },

  status: {
    required: {
      value: true,
      message: "Status cannot be empty",
    },
  },

};

export const AddUserValidation = {
  first_name: {
    required: {
      value: true,
      message: "First name cannot be empty",
    },
  },
  last_name: {
    required: {
      value: true,
      message: "First name cannot be empty",
    },
  },
  status: {
    required: {
      value: true,
      message: "Status cannot be empty",
    },
  },
  country: {
    required: {
      value: true,
      message: "Country cannot be empty",
    },
  },
  role: {
    required: {
      value: true,
      message: "Role cannot be empty",
    },
  },
  address: {
    required: {
      value: true,
      message: "Address cannot be empty",
    },
  },
  email: {
    required: {
      value: true,
      message: "Email cannot be empty",
    },
    pattern: {
      value: regex.email,
      message: "Email is not valid",
    },
  },
  username: {
    required: {
      value: true,
      message: "username or email cannot be empty",
    },
  },
  password: {
    required: {
      value: true,
      message: "Password cannot be empty",
    },
    // minLength: {
    //   value: 8,
    //   message: "Password length must be greater than 7",
    // },
  },
}


// Invoice generate validation

export const generateInvoiceValidation = {
  start_date: {
    required: {
      value: true,
      message: "Start date cannot be empty",
    },
  },
  end_date: {
    required: {
      value: true,
      message: "End date cannot be empty",
    },
  },
};

export const MOOrderSaveValidation = {
  order_name: {
    required: {
      value: true,
      message: "Order name cannot be empty",
    },
  },
  additional_instruction: {
    required: {
      value: true,
      message: "Additional instructions cannot be empty",
    },
  },
  expire_in: {
    required: {
      value: true,
      message: "Expire in cannot be empty",
    },
  },
}



