import React, { useState, useEffect } from 'react'
import { Card, Col, Container, Row } from "react-bootstrap";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";
import OrderIcon from "../../../../Assets/images/allorders-updated.png";
import pendingIcon from "../../../../Assets/images/pendingorders_updated.png";
import completedIcon from "../../../../Assets/images/completedorder_updated.png";
import approvedOrderIcon from "../../../../Assets/images/approvedorders_updated.png";
import Loader from '../../../../Utils/Loader';
import { getOperatorDashboardData } from '../../../../Redux/Actions/Operator';
import dashbordIcon from '../../../../Assets/images/dashboard-icon.png';
import OrderList from '../OrderList/OrderList';
import moment from 'moment';

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const Dashboard = () => {
  const dispatch: AppDispatch = useDispatch()
  const { dashboard, loading } = useSelector((state: any) => state.operatorDashboardCount);
  const [text, setText] = useState<any>('');
  const date = new Date();

  useEffect(() => {
    dispatch(getOperatorDashboardData())
  }, [])

  return (
    <div className='page_responsive'>
      <div className="dashboard_div_main card">
        <div className="dashboard_container">
          <img src={dashbordIcon} alt='dashboaord' />
          <h4>Dashboard</h4>
          <div className="w-100 text-end">
            <h4> {moment(`${new Date(date)}`).utc().format('YYYY-MM-DD hh:mm A')}</h4>
          </div>
        </div>
      </div>
      {!text && loading ? <Loader /> :
        <Container fluid>

          <Row className="justify-content-around my-2">
            <Col lg={5}>
              <Card className="details-card" id='detailed1' >
                <Card.Body>
                  <Row className="align-items-center">
                    <Col md={8}>
                      <div className="all_icon_div_text">
                        <h5>All Orders:</h5>

                        <div className="d-flex">
                          <div className="left_total">
                            <div className='circle_main'>
                              <div className='circle_inner'></div>
                              <p>Total Orders</p>
                            </div>
                            <h6>{dashboard?.data?.counts[0]?.total}</h6>
                          </div>
                          <div>
                            <div className='circle_main'>
                              <div className='circle_inner'></div>
                              <p>Today's Orders</p>
                            </div>
                            <h6>{dashboard?.data?.counts[0]?.today === null ? 0 : dashboard?.data?.counts[0]?.today}</h6>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={4} className={'d-flex justify-content-end'}>
                      <div className="all_order_icon_div">
                        <img src={OrderIcon} alt="" />
                      </div>
                    </Col>
                  </Row>
                </Card.Body>

              </Card>

            </Col>
            <Col lg={5}>
              <Card
                className="details-card" id='detailed2'
              >
                <Card.Body>
                  <Row className="align-items-center">
                    <Col md={8}>
                      <div className="all_icon_div_text">
                        <h5>Pending Orders:</h5>

                        <div className="d-flex">
                          <div className="left_total" style={{ borderRight: '0' }}>
                            <div className='circle_main'>
                              <div className='circle_inner'></div>
                              <p>Pending Orders</p>
                            </div>
                            <h6>{dashboard?.data?.counts[0]?.new === null ? 0 : dashboard?.data?.counts[0]?.new}</h6>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={4} className={'d-flex justify-content-end'}>
                      <div className="all_vector_icon_div">
                        <img src={pendingIcon} alt="" />
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={5}>
              <Card
                className="details-card" id='detailed3'
              >
                <Card.Body>
                  <Row className="align-items-center">
                    <Col md={8}>
                      <div className="all_icon_div_text">
                        <h5>Completed Orders:</h5>

                        <div className="d-flex">
                          <div className="left_total" style={{ borderRight: '0' }}>
                            <div className='circle_main'>
                              <div className='circle_inner'></div>
                              <p>Completed Orders</p>
                            </div>
                            <h6>{dashboard?.data?.counts[0]?.completed === null ? 0 : dashboard?.data?.counts[0]?.completed}</h6>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={4} className={'d-flex justify-content-end'}>
                      <div className="all_invoices_icon_div">
                        <img src={completedIcon} alt="invoice-icon" />
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={5}>
              <Card
                className="details-card" id='detailed4'
              >
                <Card.Body>
                  <Row className="align-items-center">
                    <Col md={8}>
                      <div className="all_icon_div_text">
                        <h5>Approved Orders:</h5>

                        <div className="d-flex">
                          <div className="left_total" style={{ borderRight: '0' }}>
                            <div className='circle_main'>
                              <div className='circle_inner'></div>
                              <p>Approved Orders</p>
                            </div>
                            <h6>{dashboard?.data?.counts[0]?.approved === null ? 0 : dashboard?.data?.counts[0]?.approved}</h6>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={4} className={'d-flex justify-content-end'}>
                      <div className="all_invoices_icon_div">
                        <img src={approvedOrderIcon} alt="" />
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="my-3">
              <OrderList style="none" today={1} />
            </Col>
          </Row>

        </Container>
      }
    </div>
  )
}
export default Dashboard
