import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { Card, Col, Row } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { errorNotify } from "../../../../../Utils/toast";
import { operatorOrderDetails } from "../../../../../Redux/Actions/Operator";
import Loader from "../../../../../Utils/Loader";
import moment from 'moment';
import { downloadFile, cloudBaseURL } from '../../../../../Utils/helper'
import OrderIcon from "../../../../../Assets/images/orders.png";

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const OrderDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { error, loading, orderDetails } = useSelector((state: any) => state.operatorOrderDetails);
  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    if (error) {
      errorNotify(error);
      dispatch({ type: "CLEAR_ERRORS" });
    }
    dispatch(operatorOrderDetails(id));
  }, []);


  return (
    <div className="page_responsive">
      <div className="card">
        <div className="send_quotes_head_container" id="digitizer_order">
          <div className='send_digitizing_order_div_shorter'>
            <img src={OrderIcon} alt="pencil-logo" />
          </div>
          <h4>Order Details</h4>
        </div>
      </div>
      <div className="user_details_card">
        <Card className="order_details_card">
          {loading ? (
            <Loader />
          ) : (
            <Card.Body>
              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1 detail_field">
                  <Row className="card_content">
                    <Col md={6}>
                      <Typography variant="h6">Order ID:</Typography>
                    </Col>
                    <Col md={6} className='text-end'>
                      <Typography>{orderDetails?.data?.order_id}</Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1 detail_field">
                  <Row className="card_content">
                    <Col md={6}>
                      <Typography variant="h6">Order Name:</Typography>
                    </Col>
                    <Col md={6} className='text-end'>
                      <Typography>{orderDetails?.data?.order_name}</Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1 detail_field">
                  <Row className="card_content">
                    <Col md={6}>
                      <Typography variant="h6">Order Date:</Typography>
                    </Col>
                    <Col md={6} className='text-end'>
                      <Typography>{moment(new Date(`${orderDetails?.data?.createdAt} UTC`)).format("DD-MM-YYYY hh:mm:ss A")}</Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1 detail_field">
                  <Row className="card_content">
                    <Col md={6}>
                      <Typography variant="h6">Task Expiry:</Typography>
                    </Col>
                    <Col md={6} className='text-end'>
                      <Typography>
                        {
                          (parseInt(orderDetails?.data?.status) === 0 && new Date() > new Date(`${orderDetails?.data?.task_expiry} UTC`)) || parseInt(orderDetails?.data?.status) !== 0 && new Date(`${orderDetails?.data?.submittedAt} UTC`) > new Date(`${orderDetails?.data?.task_expiry} UTC`) ?
                            (
                              <div className='expirted_date'>
                                {moment(new Date(`${orderDetails?.data?.task_expiry} UTC`)).format("DD-MM-YYYY hh:mm:ss A")}

                              </div>
                            )
                            :
                            moment(new Date(`${orderDetails?.data?.task_expiry} UTC`)).format("DD-MM-YYYY hh:mm:ss A")
                        }
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1 detail_field">
                  <Row className="card_content">
                    <Col md={6}>
                      <Typography variant="h6">Status:</Typography>
                    </Col>
                    <Col md={6} className='text-end'>
                      <Typography>{parseInt(orderDetails?.data?.status) === 0 ? "Pending" : null || parseInt(orderDetails?.data?.status) === 1 ? "Completed" : null || parseInt(orderDetails?.data?.status) === 2 ? "Approved" : null}</Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1">
                </Col>
              </Row>

              <Row className="justify-content-around my-1">
                <Col md={11} className="m-1 detail_field">
                  <Row className="card_content">
                    <Col md={12}>
                      <Typography variant="h6">
                        Additional Instruction:{" "}
                      </Typography>
                      <Typography>
                        {orderDetails?.data?.additional_instruction}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="justify-content-around my-1">
                <Col md={11} className="m-1 mt-2">
                  <Typography variant="h4">Printing Attachments:</Typography>
                </Col>
              </Row>

              <Row className="justify-content-around my-1">
                {orderDetails?.data?.PrintingAttachment ?
                  orderDetails?.data?.PrintingAttachment.map(
                    (attachment: any, i: any) => (
                      <Col md={11} className="m-1 detail_field">
                        <Row className="align-items-center">
                          <Col md={4}>
                            <Typography variant="h6">File {i + 1}:</Typography>
                          </Col>
                          <Col md={4}>
                            <Typography className="text-center">
                              {attachment.attachment_name}
                            </Typography>
                          </Col>
                          <Col md={4} className="d-flex justify-content-end">
                            <button className="download_btn" onClick={() => downloadFile(`${cloudBaseURL}printing/${id}/${attachment.attachment_name}`, `${attachment.attachment_name}`)}>
                              <FaDownload /> Download
                            </button>
                          </Col>
                        </Row>
                      </Col>
                    )
                  )
                  : null
                }
              </Row>


              {parseInt(orderDetails?.data?.status) !== 0 && orderDetails?.data?.attachments && (
                <Row className="justify-content-around my-1">
                  <Col md={11} className="m-1 mt-2">
                    <Typography variant="h4">Attachments:</Typography>
                  </Col>
                </Row>
              )}
              <Row className="justify-content-around my-1">
                {orderDetails?.data?.attachments ?
                  orderDetails?.data?.attachments.map(
                    (attachment: any, i: any) => (
                      <Col md={11} className="m-1 detail_field">
                        <Row className="align-items-center">
                          <Col md={4}>
                            <Typography variant="h6">File {i + 1}:</Typography>
                          </Col>
                          <Col md={4}>
                            <Typography className="text-center">
                              {attachment.attachment_name}
                            </Typography>
                          </Col>
                          <Col md={4} className="d-flex justify-content-end">
                            <button className="download_btn" onClick={() => downloadFile(`${cloudBaseURL}printing/${id}/${attachment.attachment_name}`, `${attachment.attachment_name}`)}>
                              <FaDownload /> Download
                            </button>
                          </Col>
                        </Row>
                      </Col>
                    )
                  )
                  : null
                }
              </Row>

              <Row className="pt-4 justify-content-end">
                <Col xs={12} md={5} className='button_container_main '>
                  <button
                    className="my-1 details_btn bg-gray"
                    onClick={() => navigate("/machine-operator/all-orders")}
                  >
                    Go Back
                  </button>

                  {parseInt(orderDetails?.data?.status) === 0 && (
                    <button
                      className="my-1 details_btn bg-gray"
                      onClick={() => navigate(`/machine-operator/upload-attachments/${id}`)}
                    >
                      Upload Attachments
                    </button>
                  )}
                </Col>
              </Row>
            </Card.Body>
          )}
        </Card>
      </div>
    </div>
  );
};

export default OrderDetails;
