const initialState = {
    user: {}
}

export const AuthReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case "LOGIN_REQUEST":
            return {
                loading: true,
                isAuthenticated: false,
                error: false
            }

        case "LOGIN_SUCCESS":
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                user: action.payload,
                error: false
            }

        case "LOGIN_RESET":
            return {
                ...state,
                user: {}
            }

        case "LOGIN_FAILED":
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                user: null,
                error: action.payload
            }

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}


export const registerReducer = (state = {}, action: any) => {
    switch (action.type) {
        case "REGISTER_REQUEST":
            return {
                ...state,
                loading: true,
            };

        case "REGISTER_SUCCESS":
            return {
                ...state,
                loading: false,
                isRegistered: action.payload,
            };
        case "REGISTER_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "REGISTER_RESET":
            return {
                ...state,
                isRegistered: null,
            };
        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}


export const ChangePassReducer = (state = {}, action: any) => {
    switch (action.type) {
        case "CHANGE_PASSWORD_REQUEST":
            return {
                ...state,
                loading: true,
            };

        case "CHANGE_PASSWORD_SUCCESS":
            return {
                ...state,
                loading: false,
                isChangedPass: action.payload,
            };
        case "CHANGE_PASSWORD_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "CHANGE_PASSWORD_RESET":
            return {
                ...state,
                isChangedPass: {},
            };
        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}