import { MdDashboard, MdOutlineManageAccounts, MdPassword } from 'react-icons/md';
import { BiSpreadsheet } from 'react-icons/bi';
import { IRoutesLink, ISideBarRoutes } from "../../../Utils/interfaces";
import { CgProfile } from "react-icons/cg";


// Vector Order Operator Routes

import Dashboard from "../Pages/Dashboard/Dashboard";
import AllOrder from "../Pages/AllOrders/AllOrders";
import OrderDetails from '../Pages/AllOrders/AllOrderDetails/AllOrderDetails';
import MyProfile from '../../Customer/Pages/MyProfile/MyProfile';
import ChangePassword from '../../Customer/Pages/MyProfile/ChangePassword/ChangePassword';
import UploadAttachment from '../Pages/AllOrders/AllOrderDetails/AttachmentUpload/AttachmentUpload';

// Icons

import dashboardIcon from '../../../Assets/images/dashboard-nav-icon.png';
import AllOrdersIcon from '../../../Assets/images/digitizing-nav-icon-records.png';
import myAccountIcon from '../../../Assets/images/my-account-nav-icon.png';
import LockICon from '../../../Assets/images/lock-nav-icon.png';

// Vector Order  Routes

export const vectorOperatorRoutes: IRoutesLink[] = [
    {
        path: "/designer/dashboard",
        component: <Dashboard />,
    },

    {
        path: "/designer/orders",
        component: <AllOrder />,
    },

    {
        path: "/designer/vector/order/:id",
        component: <OrderDetails />,
    },

    {
        path: "/designer/upload/order/:id",
        component: <UploadAttachment />,
    },

    {
        path: "/designer/profile",
        component: <MyProfile />,
    },

    {
        path: "/designer/reset-password",
        component: <ChangePassword />,
    },
];

export const vectorOperatorSidebarItems: ISideBarRoutes[] = [
    {
        path: "/designer/dashboard",
        icon: <img src={dashboardIcon} alt="dashboard" />,
        title: "Dashboard",
        isSubNav: false,
    },
    {
        path: "/designer/orders",
        icon: <img src={AllOrdersIcon} alt="dashboard" />,
        title: "All Orders",
        isSubNav: false,
    },

    {
        path: "",
        icon: <img src={myAccountIcon} alt="account" />,
        title: "My Account",
        isSubNav: true,
        subNav: [
            {
                path: "/designer/profile",
                component: <MyProfile />,
                icon: <img src={myAccountIcon} alt="profile" />,
                title: "My Profile"
            },
            {
                path: "/designer/reset-password",
                component: <ChangePassword />,
                icon: <img src={LockICon} alt="profile" />,
                title: "Change Password"
            },
        ]
    },

];