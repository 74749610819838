import api from "../../Utils/interceptors";


// Sales Quotes Records And Detail Page

export const getSalesQuotesRecord = (pageNO: number, perPage: number, text: string, today: any) => async (dispatch: any) => {

    try {
        dispatch({
            type: "GET_SALES_QUOTES_LIST_REQUEST"
        })

        const { data } = await api.get(`/sales/quote/list?page_no=${pageNO + 1}&per_page=${perPage}&term=${text}&today=${today}`)

        dispatch({
            type: "GET_SALES_QUOTES_LIST_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e: any) {
        dispatch({
            type: "GET_SALES_QUOTES_LIST_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
}


export const getSalesQuotesDetails = (quote_id: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: "GET_SALES_QUOTES_DETAIL_REQUEST"
        })

        const { data } = await api.get(`/sales/quote/${quote_id}`)


        dispatch({
            type: "GET_SALES_QUOTES_DETAIL_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e: any) {
        dispatch({
            type: "GET_SALES_QUOTES_DETAIL_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
}

// Sales Order List and Detail Page

export const getSalesOrdersRecord = (pageNO: number, perPage: number, text: string, today: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: "SALES_DIGITIZING_ORDER_LIST_REQUEST"
        })

        const { data } = await api.get(`sales/digitizing/list?page_no=${pageNO + 1}&per_page=${perPage}&term=${text}&today=${today}`)

        dispatch({
            type: "SALES_DIGITIZING_ORDER_LIST_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e: any) {
        dispatch({
            type: "SALES_DIGITIZING_ORDER_LIST_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
}


export const getSalesOrderDetails = (order_id: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: "SALES_DIGITIZING_ORDER_DETAIL_REQUEST"
        })

        const { data } = await api.get(`/sales/digitizing/order/${order_id}`)

        dispatch({
            type: "SALES_DIGITIZING_ORDER_DETAIL_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e: any) {
        dispatch({
            type: "SALES_DIGITIZING_ORDER_DETAIL_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
}


// SALES VECTOR ORDER LIST

export const getSalesVectorOrdersRecord = (pageNO: number, perPage: number, text: string, today: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: "SALES_VECTOR_ORDER_LIST_REQUEST"
        })

        const { data } = await api.get(`sales/vector/list?page_no=${pageNO + 1}&per_page=${perPage}&term=${text}&today=${today}`)

        dispatch({
            type: "SALES_VECTOR_ORDER_LIST_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e: any) {
        dispatch({
            type: "SALES_VECTOR_ORDER_LIST_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
}

// SALES VECTOR ORDER DETAILS


export const getSalesVectorOrderDetails = (order_id: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: "SALES_VECTOR_ORDER_DETAIL_REQUEST"
        })

        const { data } = await api.get(`sales/vector/order/${order_id}`)

        dispatch({
            type: "SALES_VECTOR_ORDER_DETAIL_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e: any) {
        dispatch({
            type: "SALES_VECTOR_ORDER_DETAIL_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
}


// SALES CUSTOMERS LIST

export const getSalesCustomerRecords = (pageNO: number, perPage: number, text: string, today: any) => async (dispatch: any) => {

    try {
        dispatch({
            type: "GET_SALES_CUSTOMERS_LIST",
        });

        const { data } = await api.get(`/sales/customer/list?page_no=${pageNO + 1}&per_page=${perPage}&term=${text}&today=${today}`);

        dispatch({
            type: "GET_SALES_CUSTOMERS_SUCCESS",
            payload: data,
            success: true,
        });
    } catch (e: any) {
        dispatch({
            type: "GET_SALES_CUSTOMERS_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
};


// SALES CUSTOMER DETAILS

export const getSalesCustomerDetails = (user_id: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: "GET_SALES_CUSTOMER_DETAILS_REQUEST"
        })

        const { data } = await api.get(`sales/customer/${user_id}/profile`)

        dispatch({
            type: "GET_SALES_CUSTOMER_DETAILS_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e: any) {
        dispatch({
            type: "GET_SALES_CUSTOMER_DETAILS_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
}


// SALES DASHBOARD CARD COUNT

export const getSalesDashboardData = () => async (dispatch: any) => {
    try {
        dispatch({
            type: "SALES_DASHBOARD_DATA_REQUEST",
        });
        const { data } = await api.get("/sales/dashboard")
        dispatch({
            type: "SALES_DASHBOARD_DATA_SUCCESS",
            payload: data,
        });
    } catch (e: any) {
        dispatch({
            type: "SALES_DASHBOARD_DATA_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
}


// SALES MONTHLY REPORT GENERATION

export const getSalesMonthlyReport = (startDate: any, endDate: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: "SALES_MONTHY_REPORT_GENERATE_REQUEST"
        })

        const { data } = await api.get(`/sales/report?startdate=${startDate}&enddate=${endDate}`)

        dispatch({
            type: "SALES_MONTHY_REPORT_GENERATE_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e: any) {
        dispatch({
            type: "SALES_MONTHY_REPORT_GENERATE_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
}


