import api from "../../Utils/interceptors";
import { getToken } from "../../Utils/helper";

export const getDashboardData = () => async (dispatch: any) => {
  try {
    dispatch({
      type: "DASHBOARD_DATA_REQUEST",
    });
    const { data } = await api.get("/admin/dashboard")
    dispatch({
      type: "DASHBOARD_DATA_SUCCESS",
      payload: data,
    });
  } catch (e: any) {
    dispatch({
      type: "DASHBOARD_DATA_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
}

export const getAdminQuotesRecords = (pageNO: number, perPage: number, text: string, today: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ALL_QUOTES_LIST_REQUEST",
    });

    const { data } = await api.get(
      `/admin/quote/list?page_no=${pageNO + 1}&per_page=${perPage}&term=${text}&today=${today}`
    );

    dispatch({
      type: "ALL_QUOTES_LIST_SUCCESS",
      payload: data,
      success: true,
    });
  } catch (e: any) {
    dispatch({
      type: "ALL_QUOTES_LIST_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

export const deleteAdminQuotesRecords = (id: string) => async (dispatch: any) => {

  try {
    dispatch({
      type: "ALL_QUOTES_DELETE_REQUEST",
    });

    const { data } = await api.delete(`/admin/quote/${id}`);

    dispatch({
      type: "ALL_QUOTES_DELETE_SUCCESS",
      payload: data,
      success: true,
    });
  } catch (e: any) {
    dispatch({
      type: "ALL_QUOTES_DELETE_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};


export const getAdminOrdersRecords =
  (pageNO: number, perPage: number, text: string, today: any) => async (dispatch: any) => {
    try {
      dispatch({
        type: "ALL_ORDERS_LIST_REQUEST",
      });

      const { data } = await api.get(
        `/admin/digitizing/list?page_no=${pageNO + 1}&per_page=${perPage}&term=${text}&today=${today}`
      );

      dispatch({
        type: "ALL_ORDERS_LIST_SUCCESS",
        payload: data,
        success: true,
      });
    } catch (e: any) {
      dispatch({
        type: "ALL_ORDERS_LIST_FAILED",
        payload: e.response.data.message,
        success: false,
      });
    }
  };

export const deleteAdminOrdersRecords = (id: string) => async (dispatch: any) => {

  try {
    dispatch({
      type: "ALL_ORDERS_DELETE_REQUEST",
    });

    const { data } = await api.delete(`admin/digitizing/${id}`);

    dispatch({
      type: "ALL_ORDERS_DELETE_SUCCESS",
      payload: data,
      success: true,
    });
  } catch (e: any) {
    dispatch({
      type: "ALL_ORDERS_DELETE_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

export const getAdminVectorsRecords = (pageNO: number, perPage: number, text: string, today: any) => async (dispatch: any) => {

  try {
    dispatch({
      type: "ALL_VECTORS_LIST_REQUEST",
    });

    const { data } = await api.get(
      `/admin/vector/list?page_no=${pageNO + 1}&per_page=${perPage}&term=${text}&today=${today}`
    );

    dispatch({
      type: "ALL_VECTORS_LIST_SUCCESS",
      payload: data,
      success: true,
    });
  } catch (e: any) {
    dispatch({
      type: "ALL_VECTORS_LIST_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

export const deleteAdminVectorsRecords = (id: string) => async (dispatch: any) => {

  try {
    dispatch({
      type: "ALL_VECTORS_DELETE_REQUEST",
    });

    const { data } = await api.delete(`admin/vector/${id}`);

    dispatch({
      type: "ALL_VECTORS_DELETE_SUCCESS",
      payload: data,
      success: true,
    });
  } catch (e: any) {
    dispatch({
      type: "ALL_VECTORS_DELETE_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
}
export const sendVectorOrderToCustomer = (id: any, sendData: any) => async (dispatch: any) => {

  try {
    dispatch({
      type: "SEND_VECTORS_ORDER_REQUEST",
    });

    const { data } = await api.post(`admin/vector/order/${id}/upload`, sendData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: getToken()
      }
    });

    dispatch({
      type: "SEND_VECTORS_ORDER_SUCCESS",
      payload: data,
    });
  } catch (e: any) {
    dispatch({
      type: "SEND_VECTORS_ORDER_FAILED",
      payload: e.response.data.message,
    });
  }
};


export const getUsersByRoles = (name: any, pageNO: number, perPage: number, text: string, showall: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ALL_USERS_BY_ROLE_REQUEST",
    });
    const { data } = await api.get(
      `admin/user/list/${name}?page_no=${pageNO + 1}&per_page=${perPage}&term=${text}&showall=${showall}`
    );
    dispatch({
      type: "ALL_USERS_BY_ROLE_SUCCESS",
      payload: data,
      success: true,
    });
  } catch (e: any) {
    dispatch({
      type: "ALL_USERS_BY_ROLE_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

export const getRolesList = () => async (dispatch: any) => {
  try {
    dispatch({
      type: "GET_ALL_ROLES_LIST_REQUEST",
    });

    const { data } = await api.get("/admin/role/list");

    dispatch({
      type: "GET_ALL_ROLES_LIST_SUCCESS",
      payload: data,
      success: true,
    });
  } catch (e: any) {
    dispatch({
      type: "GET_ALL_ROLES_LIST_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

export const deleteAllUsers = (id: number) => async (dispatch: any) => {

  try {
    dispatch({
      type: "DELETE_USER_REQUEST",
    });

    const { data } = await api.delete(`/admin/user/${id}`);

    dispatch({
      type: "DELETE_USER_SUCCESS",
      payload: data,
      success: true,
    });
  } catch (e: any) {
    dispatch({
      type: "DELETE_USER_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};


export const quoteApproved =
  (id: any, approvedData: any) => async (dispatch: any) => {
    try {
      dispatch({
        type: "QUOTE_APPROVED_REQUEST",
      });
      const { data } = await api.put(
        `/admin/quote/approve/${id}`,
        approvedData
      );
      dispatch({
        type: "QUOTE_APPROVED_SUCCESS",
        payload: data,
        success: true,
      });
    } catch (e: any) {
      dispatch({
        type: "QUOTE_APPROVED_FAILED",
        payload: e.response.data.message,
        success: false,
      });
    }
  };

export const AddUserAction = (addUserData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADD_USER_REQUEST",
    });

    const { data } = await api.post(
      "/admin/user",
      addUserData
    );

    dispatch({
      type: "ADD_USER_SUCCESS",
      payload: data,
      success: true,
    });
  } catch (e: any) {
    dispatch({
      type: "ADD_USER_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};
export const orderAssigned = (order_id: any, digitizer_id: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ORDER_ASSIGNED_REQUEST",
    });
    const { data } = await api.put(
      `/admin/digitizing/order/${order_id}/assign/digitizer/${digitizer_id}`,
      {}
    );
    dispatch({
      type: "ORDER_ASSIGNED_SUCCESS",
      payload: data,
      success: true,
    });
  } catch (e: any) {
    dispatch({
      type: "ORDER_ASSIGNED_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

export const AllUsersAction = (pageNO: number, perPage: number, text: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ALL_USERS_LIST_REQUEST",
    });

    const { data } = await api.get(
      `/admin/user/list?page_no=${pageNO + 1}&per_page=${perPage}&term=${text}`
    );

    dispatch({
      type: "ALL_USERS_LIST_SUCCESS",
      payload: data,
      success: true,
    });
  } catch (e: any) {
    dispatch({
      type: "ALL_USERS_LIST_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};


export const getUserDetails = (user_id: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "GET_USER_DETAILS_REQUEST",
    });

    const { data } = await api.get(
      `/admin/user/${user_id}/profile`
    );

    dispatch({
      type: "GET_USER_DETAILS_SUCCESS",
      payload: data,
      success: true,
    });
  } catch (e: any) {
    dispatch({
      type: "GET_USER_DETAILS_FAILED",
      payload: e.response?.data?.message,
      success: false,
    });
  }
};

export const updateUserDetails = (user_id: any, userData: any) => async (dispatch: any) => {

  try {
    dispatch({
      type: "UPDATE_USER_DETAILS_REQUEST",
    });

    const { data } = await api.put(`/admin/user/${user_id}`, userData);

    dispatch({
      type: "UPDATE_USER_DETAILS_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "UPDATE_USER_DETAILS_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// Order Sent To Customer

export const orderSentToCustomer = (order_id: any, orderData: any) => async (dispatch: any) => {

  try {
    dispatch({
      type: "ORDER_SENT_TO_CUSTOMER_REQUEST",
    });

    const { data } = await api.put(`/admin/digitizing/order/${order_id}/completed`, orderData);

    dispatch({
      type: "ORDER_SENT_TO_CUSTOMER_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "ORDER_SENT_TO_CUSTOMER_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};


// Invoicing

export const getGeneratedInvoice = (customer_id: any, start_date: String, end_date: String) => async (dispatch: any) => {
  try {
    dispatch({
      type: "GENERATE_INVOICE_REQUEST",
    });

    const { data } = await api.get(`/admin/invoice/${customer_id}/generate?start_date=${start_date}&end_date=${end_date}`);

    dispatch({
      type: "GENERATE_INVOICE_SUCCESS",
      payload: data,
      success: true,
    });
  }

  catch (e: any) {
    dispatch({
      type: "GENERATE_INVOICE_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
}

export const invoiceCreate = (customer_id: any, invoiceData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "CREATE_INVOICE_REQUEST",
    });

    const { data } = await api.post(`/admin/invoice/${customer_id}/create`, { invoice_ids: invoiceData });

    dispatch({
      type: "CREATE_INVOICE_SUCCESS",
      payload: data,
      success: true,
    });
  }
  catch (e: any) {
    dispatch({
      type: "CREATE_INVOICE_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

export const getInvoicesList = (pageNO: number, perPage: number, text: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: "GET_INVOICE_LIST_REQUEST",
    });

    const { data } = await api.get(`/admin/invoice/list?page_no=${pageNO + 1}&per_page=${perPage}&term=${text}`);

    dispatch({
      type: "GET_INVOICE_LIST_SUCCESS",
      payload: data,
      success: true,
    });
  }

  catch (e: any) {
    dispatch({
      type: "GET_INVOICE_LIST_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
}

export const invoiceDetails = (invoice_id: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "GET_INVOICE_DETAIL_REQUEST",
    });

    const { data } = await api.get(`/admin/invoice/${invoice_id}`);

    dispatch({
      type: "GET_INVOICE_DETAIL_SUCCESS",
      payload: data,
      success: true,
    });
  }

  catch (e: any) {
    dispatch({
      type: "GET_INVOICE_DETAIL_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
}

// User Assign To Sales Person

export const userAssignToSalesPerson = (customer_id: any, sales_id: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "USER_ASSIGNED_TO_SALESPERSON_REQUEST",
    });
    const { data } = await api.put(`admin/user/${customer_id}/assign_to/${sales_id}`, {});
    dispatch({
      type: "USER_ASSIGNED_TO_SALESPERSON_SUCCESS",
      payload: data,
      success: true,
    });
  } catch (e: any) {
    dispatch({
      type: "USER_ASSIGNED_TO_SALESPERSON_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// Admin MO Order Save

export const MoOrderSave = (MoOrderData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "MO_ORDER_SAVE_REQUEST",
    });

    const { data } = await api.post("/admin/printing/order", MoOrderData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: getToken()
      }
    });

    dispatch({
      type: "MO_ORDER_SAVE_SUCCESS",
      payload: data,
      success: true,
    });
  }
  catch (e: any) {
    dispatch({
      type: "MO_ORDER_SAVE_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};


// Admin Mo Order List 

export const getMoOrderList = (pageNO: number, perPage: number, text: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: "MO_ORDER_LIST_REQUEST",
    });

    const { data } = await api.get(`admin/printing/order/list?page_no=${pageNO + 1}&per_page=${perPage}&term=${text}`);

    dispatch({
      type: "MO_ORDER_LIST_SUCCESS",
      payload: data,
      success: true,
    });
  }

  catch (e: any) {
    dispatch({
      type: "MO_ORDER_LIST_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
}

// Admin MO Order Details

export const getMoOrderDetails = (order_id: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "GET_MO_ORDER_DETAILS_REQUEST",
    });

    const { data } = await api.get(`/admin/printing/order/${order_id}`);

    dispatch({
      type: "GET_MO_ORDER_DETAILS_SUCCESS",
      payload: data,
      success: true,
    });
  }

  catch (e: any) {
    dispatch({
      type: "GET_MO_ORDER_DETAILS_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
}

// Admin MO Order Approve

export const adminMOOrderApprove = (order_id: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "ADMIN_MO_ORDER_APPROVE_REQUEST",
    });
    const { data } = await api.put(`/admin/printing/order/${order_id}/approve`, {});
    dispatch({
      type: "ADMIN_MO_ORDER_APPROVE_SUCCESS",
      payload: data,
      success: true,
    });
  } catch (e: any) {
    dispatch({
      type: "ADMIN_MO_ORDER_APPROVE_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// Vector Order Assigned

export const VecotOrderAssigned = (order_id: any, designer_id: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "VECTOR_ORDER_ASSIGNED_REQUEST",
    });
    const { data } = await api.put(
      `admin/vector/order/${order_id}/assign/designer/${designer_id}`,
      {}
    );
    dispatch({
      type: "VECTOR_ORDER_ASSIGNED_SUCCESS",
      payload: data,
      success: true,
    });
  } catch (e: any) {
    dispatch({
      type: "VECTOR_ORDER_ASSIGNED_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// VECTOR ORDER UPLOAD ATTACHMENTS

export const vectorOrderUploadAttachments = (order_id: any, sendData: any) => async (dispatch: any) => {

  try {
    dispatch({
      type: "VECTOR_ORDER_ATTACHMENT_REQUEST",
    });

    const { data } = await api.post(`designer/order/${order_id}/upload`, sendData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: getToken()
      }
    });

    dispatch({
      type: "VECTOR_ORDER_ATTACHMENT_SUCCESS",
      payload: data,
    });
  } catch (e: any) {
    dispatch({
      type: "VECTOR_ORDER_ATTACHMENT_FAILED",
      payload: e.response.data.message,
    });
  }
}

// VECTOR ORDER SEND TO CUSTOMER

export const VecotOrderSentToCustomer = (order_id: any, orderData: any) => async (dispatch: any) => {

  try {
    dispatch({
      type: "VECTOR_ORDER_SENT_TO_CUSTOMER_REQUEST",
    });

    const { data } = await api.put(`admin/vector/order/${order_id}/completed`, orderData);

    dispatch({
      type: "VECTOR_ORDER_SENT_TO_CUSTOMER_SUCCESS",
      payload: data,
      success: true,
    });

  }
  catch (e: any) {
    dispatch({
      type: "VECTOR_ORDER_SENT_TO_CUSTOMER_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};