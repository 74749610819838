import React, { useState, useEffect } from 'react'
import MuiDataTable from "../../../../Components/MUIDataTable/MUIDataTable";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Loader from '../../../../Utils/Loader';
import { getCustomerInvoicesList } from '../../../../Redux/Actions/CustomerInvoices';
import moment from 'moment';
import eyeIcon from '../../../../Assets/images/eye.png';

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const Invoices = () => {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [text, setText] = useState("");
    const dispatch: AppDispatch = useDispatch();
    const { loading, customerInvoicesList, customerInvoicesCount } = useSelector((state: any) => state?.customerAllInvoices);
    const navigation = useNavigate();
    let userAuth: any = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "") : null;
    let customerCurrency = userAuth?.country?.currency_symbol;

    useEffect(() => {
        dispatch(getCustomerInvoicesList(page, rowsPerPage, text));
    }, [page, rowsPerPage, text, dispatch]);

    const invoicesRecordsColumns = [
        {
            name: "id",
            options: {
                display: false,
            },
        },
        {
            name: "sno",
            label: "SNO",
        },
        {
            name: "invoice_no", label: "INVOICE NO",
            options: {
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (
                        value
                    );
                },
            },
        },
        {
            name: "createdAt", label: "ISSUE DATE",
            options: {
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    if (!value) {
                        return '-'
                    }
                    return  moment(new Date(`${value} UTC`)).format("DD-MM-YYYY hh:mm:ss");
                },
            },
        },
        {
            name: "total_amount", label: "TOTAL AMOUNT",
            options: {
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (
                        <span className='currency'>{customerCurrency}{value}</span>
                    )

                },
            },
        },
        {
            name: "payment_status",
            label: "PAYMENT STATUS",
            options: {
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    if (parseInt(value) === 0) {
                        return (
                            <div className="action_container">
                                <button
                                    className="status-pending"
                                    type="button"
                                >
                                    payable
                                </button>
                            </div>
                        );
                    }

                },
            },
        },

        {
            name: "DETAILS",
            options: {
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (
                        <div className="action_container">
                            <button
                                className="view-btn"
                                type="button"
                                onClick={() =>
                                    navigation(`/user/invoice-details/${tableMeta.rowData[0]}`)
                                }
                            >
                                <img src={eyeIcon} alt="eye" />
                            </button>
                        </div>
                    );
                },
            },
        },
    ];

    let newData: any = [];
    customerInvoicesList?.data?.rows?.map((item: any, index: any) => {
        newData.push({ sno: rowsPerPage * page + index + 1, ...item });
    });

    return (
        <div className='page_responsive'>
            <div className="card d-flex">
                <div className="send_quotes_head_container">
                    <h3 style={{ color: "#3077F2", fontSize: "25px", fontWeight: "600" }}>All Invoices</h3>
                </div>
            </div>
            <div className='quotes-table'>
                {!text && loading ? (
                    <Loader />
                ) : (
                    <MuiDataTable
                        title={"Invoices Record"}
                        data={newData}
                        columns={invoicesRecordsColumns}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        count={customerInvoicesCount}
                        setText={setText}
                        text={text}
                    />
                )}
            </div>
        </div>
    )
}

export default Invoices
