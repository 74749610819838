import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Col, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { useNavigate } from "react-router-dom";
import { AnyAction } from "redux";
import MUIDataTable, { FilterType } from "mui-datatables";
import Loader from "../../../../../Utils/Loader";
import "./GenerateInvoice.css";
import { invoiceCreate } from "../../../../../Redux/Actions/admin";
import { errorNotify, successNotify } from "../../../../../Utils/toast";
import moment from 'moment';
import phoneIcon from '../../../../../Assets/images/phone.png'
import mailIcon from '../../../../../Assets/images/mail-white.png'
import worldIcon from '../../../../../Assets/images/world.png'
import Button from "../../../../../Components/Button/Button";

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const GenerateInvoice = () => {
  const { loading, generateInvoice } = useSelector((state: any) => state.generateInvoice);
  const { loading: createInvoiceLoading, invoiceData } = useSelector((state: any) => state.invoiceCreate);
  const { id } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const navigation = useNavigate();
  const [page, setPage] = useState(0);
  const [text, setText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [calculatedPrice, setCalculatedPrice] = useState(0);
  let data: any = [];

  generateInvoice?.data?.invoices?.rows?.map((item: any, index: any) => {
    data.push({
      id: item?.id,
      order_id: item?.Digitizing
        ? item?.Digitizing?.id
        : item?.Vector
          ? item?.Vector?.id
          : undefined,
      createdAt: item?.Digitizing
        ? item?.Digitizing?.createdAt
        : item?.Vector
          ? item?.Vector?.createdAt
          : undefined,
      order_name: item?.Digitizing
        ? item?.Digitizing?.order_name
        : item?.Vector
          ? item?.Vector?.vector_name
          : undefined,
      price: item?.Digitizing
        ? item?.Digitizing?.price
        : item?.Vector
          ? item?.Vector?.price
          : undefined,
    });
  });

  const values = data?.map((item: any) => item?.price);

  const totalPrice = values?.reduce((acc: any, curr: any) => {
    return acc + curr;
  }, 0);

  let invoice_ids: any = [];
  for (let Invoice of data) {
    invoice_ids.push(Invoice.id);
  }

  useEffect(() => {
    if (invoiceData?.status === 1) {
      successNotify("Invoice Sent Successfully!");
      dispatch({ type: "CREATE_INVOICE_RESET" });
      navigation(-1);
    }
  }, [invoiceData?.status]);

  const createInvoice = () => {
    if (selectedItems.length <= 0) {
      errorNotify("Please select any invoice!")
    }
    else {
      dispatch(invoiceCreate(id, selectedItems));
    }
  };

  const quotesColumns = [
    { name: "sno", label: "S:NO" },
    {
      name: "order_id",
      label: "ORDER ID",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          if (value !== null) {
            return value;
          }
        },
      },
    },

    {
      name: "createdAt",
      label: "Order Date",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          if (value !== null) {
            return (
              moment(value).utc().format("DD-MM-YYYY hh:mm:ss")
            )
          }
        },
      },
    },

    {
      name: "order_name",
      label: "ORDER NAME",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          if (value !== null) {
            return value;
          }
        },
      },
    },

    {
      name: "price",
      label: "PRICE",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          if (value !== null) {
            return (
              generateInvoice?.data?.customer?.Country?.currency_symbol +
              " " +
              value
            );
          }
        },
      },
    },
  ];

  let newData: any = [];
  data?.map((item: any, index: any) => {
    newData?.push({ sno: rowsPerPage * page + index + 1, ...item });
  });

  // let result
  const option: FilterType | any = {
    filterType: "checkbox",
    download: false,
    expandableRowsOnClick: false,
    search: false,
    selectableRows: "multiple",
    selectableRowsHeader: true,
    selectToolbarPlacement: "none",
    rowsPerPage: 100,
    rowsSelected: selectedRows,
    onRowsSelect: (rowsSelected: any, allRows: any) => {
      const selected = allRows.map((item: any) => item.index);
      const SelectedResult = allRows.map((item: any) => {
        return data.at(item.index);
      });
      const selectedIds = SelectedResult.map((item: any) => {
        return item.id;
      });
      const selectedPrice = SelectedResult.map((item: any) => {
        return item.price;
      });

      let TotalAmount = 0
      for (let price of selectedPrice) {
        TotalAmount = TotalAmount + price
      }
      setSelectedRows(selected);
      setSelectedItems(selectedIds);
      setCalculatedPrice(TotalAmount);
    },
  };
  
  return (
    <div className="page_responsive generate_invoices">
      <div className="card">
        <div className="send_quotes_head_container">
          <h3 style={{ color: "#3077F2", fontSize: "25px", fontWeight: "600" }}>Generate Invoice</h3>
        </div>
      </div>

      <Row>
        <Col md={12} className="p-2 quotes-table" id="generating_invoice">
          {!text && loading ? (
            <Loader />
          ) : (
            <MUIDataTable
              title={"GENERATED INVOICE RECORDS"}
              data={newData}
              columns={quotesColumns}
              options={option}
            />
          )}
        </Col>
      </Row>
      <Row className="justify-content-end">
        <Col md={5}>
          <div className="total_table">
            <div className="cards">
              <div className="total_head">
                <div>
                  <h5>SUBTOTAL</h5>
                </div>

                <div>
                  <p>
                    {generateInvoice?.data?.customer?.Country?.currency_symbol}{" "}
                    {calculatedPrice}
                  </p>
                </div>
              </div>

              <div className="card-body">
                <div>
                  <h5>TOTAL</h5>
                </div>

                <div>
                  <p>

                    {generateInvoice?.data?.customer?.Country?.currency_symbol}{" "}
                    {calculatedPrice}
                  </p>
                </div>
              </div>

              <div className="card-body">
                <div>
                  <h5>CHECKOUT</h5>
                </div>

                <div>
                  <button className="status-pending">Pay Now</button>
                </div>
              </div>

              <div className="d-flex justify-content-end mt-4">
                <Button onClick={createInvoice}>
                  {createInvoiceLoading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "GENERATE INVOICE"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md={12}>
          <div className="mail_container" id="admin_invoices_container">
            <h4>Thanks for your business with Climax Digitizing!</h4>
            <p className="query_text">
              If you have any questions about this invoice, do not
              hesitate to contact us.
            </p>

            <div className="container_main">
              <div className="phone_mail_container_main">
                <div className="tel_container">
                  <img src={phoneIcon} alt='phone' />
                </div>
                <p> +1 602 635 1843</p>
              </div>

              <div className="phone_mail_container_main">
                <div className="Mail_Container">
                  <img src={mailIcon} alt='mail' />
                </div>
                <a href="mailto:accounts@climaxdigitizing.net">
                  accounts@climaxdigitizing.net
                </a>
              </div>

              <div className="phone_mail_container_main">
                <div className="world_container">
                  <img src={worldIcon} alt='world' />
                </div>
                <p>www.climaxdigitizing.net</p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default GenerateInvoice;
