import React, { useEffect, useState } from "react";
import MuiDataTable from "../../../../Components/MUIDataTable/MUIDataTable";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { getSalesVectorOrdersRecord } from "../../../../Redux/Actions/Sales";
import Loader from "../../../../Utils/Loader";
import moment from "moment";
import { CurrentUserInfo } from '../../../../Utils/helper';
import eyeIcon from '../../../../Assets/images/eye.png';
import VectorIcon from "../../../../Assets/images/vector.png";

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;
const VectorRecords: React.FC<any> = ({ style, today = '' }) => {
    const navigation = useNavigate();
    const { loading, salesVectorOrderList, vectorsCount } = useSelector((state: any) => state.salesVectorList);
    const dispatch: AppDispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [text, setText] = useState('');
    const currencySymbol = CurrentUserInfo()?.country?.currency_symbol;
    useEffect(() => {
        dispatch(getSalesVectorOrdersRecord(page, rowsPerPage, text, today));
    }, [page, rowsPerPage, text]);// eslint-disable-line react-hooks/exhaustive-deps

    const vectorRecordsColumns = [
        { name: "sno", label: "S:NO" },
        { name: "vector_no", label: "ORDER NO" },
        { name: "vector_name", label: "VECTOR NAME" },
        {
            name: "send_date",
            label: "SEND DATE",
            options: {
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return moment(new Date(`${value} UTC`)).format("DD-MM-YYYY hh:mm:ss");
                },
            },
        },
        {
            name: "status",
            label: "STATUS",
            options: {
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    if (parseInt(value) === 0) {
                        return (
                            <div className="status-pending">
                                <p className="mb-0">pending</p>
                            </div>
                        )
                    }

                    else if (parseInt(value) === 1) {
                        return (
                            <div className="status_in_process">
                                <p className="mb-0">In process</p>
                            </div>
                        )
                    }
                    else if (parseInt(value) === 2) {
                        return (
                            <div className="status_completed">
                                <p className="mb-0">completed</p>
                            </div>
                        )
                    }
                },
            },
        },
        {
            name: 'price',
            label: 'PRICE',
            options: {
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    if (!value || null || 0) {
                        return '-'
                    }
                    else {
                        return <span className='currency'>{currencySymbol}{value}</span>
                    }
                },
            },
        },

        {
            name: "Order Details",
            label: "DETAILS",
            options: {
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (
                        <div className="action_container">
                            <Link to={`/sales/vector-order-details/${tableMeta.rowData[1]}`}>
                                <button
                                    className="view-btn"
                                    type="button"
                                >
                                    <img src={eyeIcon} alt="eye" />
                                </button>
                            </Link>
                        </div>
                    );
                },
            },
        },
    ];

    let newData: any = [];
    salesVectorOrderList?.data?.rows?.map((item: any, index: any) => {
        newData.push({ sno: rowsPerPage * page + index + 1, ...item });
    });
    return (
        <div className={`${style ? style : "page_responsive"}`}>
            <div className="card">
                <div className="send_quotes_head_container" id="vector_order_div">
                    <div className='send_vector_order_div_shorter'>
                        <img src={VectorIcon} alt="pencil-logo" />
                    </div>
                    <h4>{today !== '' ? 'Todays All Vector Orders' : "All Vector Orders"}</h4>
                </div>
            </div>
            <div className="vector-order-table">
                {!text && loading ? (
                    <Loader />
                ) : (
                    <MuiDataTable
                        title={today !== '' ? 'Todays All Vector Orders' : "All Vector Orders"}
                        data={newData}
                        columns={vectorRecordsColumns}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        count={vectorsCount}
                        text={text}
                        setText={setText}
                    />
                )}
            </div>
        </div>
    );
};

export default VectorRecords;
