export const userSendVectorOrderReducer = (state = {}, action: any) => {
    switch (action.type) {
      case "SEND_VECTOR_ORDER_REQUEST":
        return {
          ...state,
          loading: true,
        };
      case "SEND_VECTOR_ORDER_SUCCESS":
        return {
          ...state,
          loading: false,
          vectorCreated: action.payload,
        };
      case "SEND_VECTOR_ORDER_FAILED":
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case "SEND_VECTOR_ORDER_RESET":
        return{
          ...state,
          vectorCreated:null,
        }
        case "CLEAR_ERRORS":
          return{
              ...state,
              error:null
          }
      default:
        return state;
    }
  };
  export const userVectorOrderListReducer = (state = [], action: any) => {
    switch (action.type) {
      case "VECTOR_ORDER_LIST_REQUEST":
        return {
          ...state,
          loading: true,
        };
      case "VECTOR_ORDER_LIST_SUCCESS":
        return {
          ...state,
          loading: false,
          vectorList: action.payload,
          vectorsCount:action.payload.data.count,

        };
      case "VECTOR_ORDER_LIST_FAILED":
        return {
          ...state,
          loading: false,
          error: action.payload,
          vectorsCount:0
        };
      case "VECTOR_ORDER_LIST_RESET":
        return{
          ...state,
          vectorList:null
        }
        case "CLEAR_ERRORS":
          return{
              ...state,
              error:null
          }
      default:
        return state;
    }
  };
  export const userVectorOrderDetailsReducer = (state = {}, action: any) => {
    switch (action.type) {
      case "VECTOR_ORDER_DETAIL_REQUEST":
        return {
          ...state,
          loading: true,
        };
      case "VECTOR_ORDER_DETAIL_SUCCESS":
        return {
          ...state,
          loading: false,
          vectorDetails: action.payload,
        };
      case "VECTOR_ORDER_DETAIL_FAILED":
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case "VECTOR_ORDER_DETAIL_RESET":
        return{
          ...state,
          vectorDetails:null
        }
        case "CLEAR_ERRORS":
          return{
              ...state,
              error:null
          }
      default:
        return state;
    }
  };


  // CUSTOMER VECTOR ORDER DELETED

export const customerVectorDeleteReducer = (state = [], action: any) => {
  switch (action.type) {
    case "CUSTOMER_VECTOR_ORDER_DELETE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "CUSTOMER_VECTOR_ORDER_DELETE_SUCCESS":
      return {
        ...state,
        loading: false,
        deleteCustomerVector: action.payload,
      };
    case "CUSTOMER_VECTOR_ORDER_DELETE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "CUSTOMER_VECTOR_ORDER_DELETE_RESET":
      return {
        ...state,
        deleteCustomerVector: {}
      }

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};