import React, { useEffect } from 'react'
import { Card, Col, Container, Row } from "react-bootstrap";
import './Dashboard.css';
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";
import OrderIcon from "../../../../Assets/images/allorders-updated.png";
import InvoicesIcon from "../../../../Assets/images/todays order.png";
import Loader from '../../../../Utils/Loader';
import { DigitizerDashboardCount } from '../../../../Redux/Actions/digitizer';
import dashbordIcon from '../../../../Assets/images/dashboard-icon.png';
import AllOrder from '../AllOrders/AllOrder';
import moment from 'moment';

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const Dashboard = () => {
  const dispatch: AppDispatch = useDispatch()
  const { digitizerDashboardCounts, loading } = useSelector((state: any) => state.digitizerDashboardCountTotal);
  const date = new Date(); 
  useEffect(() => {
    dispatch(DigitizerDashboardCount())
  }, [])

  return (
    <div className='page_responsive'>
      <div className="dashboard_div_main card">
        <div className="dashboard_container">
          <img src={dashbordIcon} alt='dashboaord' />
          <h4>Dashboard</h4>
          <div className="w-100 text-end">
            <h4> {moment(`${new Date(date)}`).utc().format('YYYY-MM-DD hh:mm A')}</h4>
          </div>
        </div>
      </div>

      <Container fluid>
        {loading ? <Loader /> :
          <Row className="justify-content-around my-2">
            <Col md={5}>
              <Card className="details-card" id='card1' >
                <Card.Body>
                  <Row className="align-items-center">
                    <Col md={8} className='p-0'>
                      <div className="all_icon_div_text">
                        <h5>All Orders: {digitizerDashboardCounts?.data[0]?.total}</h5>
                        <div className="d-flex">
                          <div className="left_total">
                            <div className='circle_main'>
                              <div className='circle_inner'></div>
                              <p>Pending Orders</p>
                            </div>
                            <h6>{digitizerDashboardCounts?.data[0]?.new}</h6>
                          </div>
                          <div>
                            <div className='circle_main'>
                              <div className='circle_inner'></div>
                              <p>Completed Orders</p>
                            </div>
                            <h6>{digitizerDashboardCounts?.data[0]?.completed}</h6>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={4} className={'d-flex justify-content-end'}>
                      <div className="all_order_icon_div">
                        <img src={OrderIcon} alt="" />
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col md={5}>
              <Card className="details-card"  id='card2'>
                <Card.Body>
                  <Row className="align-items-center">
                    <Col md={8} className='p-0'>
                      <div className="all_icon_div_text">
                        <h5>Today's Orders: {digitizerDashboardCounts?.data[0]?.today} </h5>
                        <div className="d-flex">
                          <div className="left_total">
                            <div className='circle_main'>
                              <div className='circle_inner'></div>
                              <p>Pending Orders</p>
                            </div>
                            <h6>{digitizerDashboardCounts?.data[0]?.today_new ? digitizerDashboardCounts?.data[0]?.today_new : 0}</h6>
                          </div>
                          <div>
                            <div className='circle_main'>
                              <div className='circle_inner'></div>
                              <p>Completed Orders</p>
                            </div>
                            <h6>{digitizerDashboardCounts?.data[0]?.today_completed ? digitizerDashboardCounts?.data[0]?.today_completed : 0}</h6>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={4} className={'d-flex justify-content-end'}>
                      <div className="all_invoices_icon_div">
                        <img src={InvoicesIcon} alt="" />
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        }
        <Row>
          <Col md={12} className="my-3">
            <AllOrder style="none" today={1} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}
export default Dashboard
