import React, { useEffect, useState } from "react";
import MuiDataTable from "../../../../Components/MUIDataTable/MUIDataTable";
import { Link, useNavigate } from "react-router-dom";
import { getSalesQuotesRecord } from "../../../../Redux/Actions/Sales";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import Loader from "../../../../Utils/Loader";
import moment from "moment";
import eyeIcon from '../../../../Assets/images/eye.png';
import QuoteIcon from "../../../../Assets/images/quote.png";

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const SalesAllQuotes: React.FC<any> = ({ style, today = '' }) => {
  const { loading, getSalesQuotes, totalCount } = useSelector((state: any) => state.salesTotalQuotes);
  const dispatch: AppDispatch = useDispatch();
  const navigation = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [text, setText] = useState('')

  useEffect(() => {
    dispatch(getSalesQuotesRecord(page, rowsPerPage, text, today));
  }, [page, rowsPerPage, text]);// eslint-disable-line react-hooks/exhaustive-deps

  const quoteRecordsColumns = [
    { name: "sno", label: "S:NO" },
    { name: "quote_no", label: "QUOTE NO" },
    { name: "quote_name", label: "QUOTE NAME" },
    {
      name: "send_date",
      label: "SEND DATE",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return moment(new Date(`${value} UTC`)).format("DD-MM-YYYY hh:mm:ss");
        },
      },
    },
    {
      name: "status",
      label: "STATUS",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <React.Fragment>
              {parseInt(tableMeta.rowData[4]) === 0 ? (
                <div className="status-pending">
                  <p className="mb-0">pending</p>
                </div>
              ) : (
                <div className="status_completed">
                  <p className="mb-0">approved</p>
                </div>
              )}
            </React.Fragment>
          );
        },
      },
    },

    {
      name: "Quote Details",
      label: "DETAILS",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <div className="action_container">
              <Link to={`/sales/quote-record-details/${tableMeta.rowData[1]}`}>
                <button
                  className="view-btn"
                  type="button"
                >
                  <img src={eyeIcon} alt="eye" />
                </button>
              </Link>
            </div>
          );
        },
      },
    },
  ];

  // Adding Seriel Number

  let newData: any = [];
  getSalesQuotes?.rows?.map((item: any, index: any) => {
    newData.push({ sno: rowsPerPage * page + index + 1, ...item });
  });

  return (
    <div className={`${style ? style : "page_responsive"}`}>
      <div className="card ">
        <div className="send_quotes_head_container">
          <div className='send_quote_div_shorter'>
            <img src={QuoteIcon} alt="pencil-logo" />
          </div>
          <h4>{today !== '' ? 'Todays All Quotes' : 'All Quotes'}</h4>
        </div>
      </div>
      <div className="quotes-table">
        {!text && loading ? (
          <Loader />
        ) : (
          <MuiDataTable
            title={today !== '' ? 'Todays All Quotes' : 'All Quotes'}
            data={newData}
            columns={quoteRecordsColumns}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            count={totalCount}
            setText={setText}
            text={text}
          />
        )}
      </div>
    </div>
  );
};

export default SalesAllQuotes;
