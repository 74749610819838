import { CgProfile } from "react-icons/cg";
import { MdDashboard, MdOutlineManageAccounts, MdPassword } from 'react-icons/md';
import { BsFillEnvelopeFill } from 'react-icons/bs';
import { BsFillFileEarmarkSpreadsheetFill } from 'react-icons/bs';
import { RiComputerLine } from 'react-icons/ri';
import { IRoutesLink, ISideBarRoutes } from "../../../Utils/interfaces";
import { HiPencil, HiPencilAlt } from 'react-icons/hi'
import { FaFileInvoice, FaShoppingCart } from 'react-icons/fa';

// Customer Routes

import Dashboard from "../Pages/Dashboard/Dashboard";
import SendQuotes from "../Pages/SendQuotes/SendQuotes";
import QuoteRecord from "../Pages/QuoteRecord/QuoteRecord";
import DigitizingOrder from "../Pages/DigitizingOrder/DigitizingOrder";
import DigitizingRecords from "../Pages/DigitizingRecords/DigitizingRecords";
import VectorOrder from "../Pages/VectorOrder/VectorOrder";
import VectorRecords from "../Pages/VectorRecords/VectorRecords";
import Invoices from "../Pages/Invoices/Invoices";
import MyProfile from "../Pages/MyProfile/MyProfile";
import DigitizingOrderDetails from "../Pages/DigitizingRecords/DigitizingOrderDetails/DigitizingOrderDetails";
import VectorRecordsDetail from "../Pages/VectorRecords/VectorRecordsDetail/VectorRecordsDetail";
import QuoteRecordDetails from "../Pages/QuoteRecord/QuoteRecordDetails/QuoteRecordDetails";
import InvoiceDetails from "../Pages/Invoices/InvoiceDetails/InvoiceDetails";
import EditDigitizingOrder from "../Pages/DigitizingRecords/EditDigitizingOrder/EditDigitizingOrder";
import ChangePassword from "../Pages/MyProfile/ChangePassword/ChangePassword";

// ICONS

import dashboardIcon from '../../../Assets/images/dashboard-nav-icon.png';
import sendQuotesIcon from '../../../Assets/images/quotes-nav-icon.png';
import quoteRecordsIcon from '../../../Assets/images/quote-records-Icon.png';
import digitizingOrderIcon from '../../../Assets/images/digitizing-order.png';
import digitizingRecordsIcon from '../../../Assets/images/digitizing-records-Icon.png';
import vectorOrdersIcon from '../../../Assets/images/all-vector-orders-nav-icon.png';
import vectorRecordsIcon from '../../../Assets/images/vector-records-sidebar.png';
import InvoicesIcon from '../../../Assets/images/all-invoices-nav-icon.png'
import myAccountIcon from '../../../Assets/images/my-account-nav-icon.png';
import LockICon from '../../../Assets/images/lock-nav-icon.png';


// Customer Routes

export const customerRoutes: IRoutesLink[] = [
    {
        path: "/user/dashboard",
        component: <Dashboard />,
    },

    {
        path: "/user/send-quote",
        component: <SendQuotes />,
    },

    {
        path: "/user/quote-all-record",
        component: <QuoteRecord />,
    },
    {
        path: "/user/quote-record-details/:id",
        component: <QuoteRecordDetails />,
    },
    {
        path: "/user/customer-order-form",
        component: <DigitizingOrder />,
    },
    {
        path: "/user/all-orders",
        component: <DigitizingRecords />,
    },
    {
        path: "/user/customer/order-details/:id",
        component: <DigitizingOrderDetails />,
    },
    {
        path: "/user/customer/update-order-details/:id",
        component: <EditDigitizingOrder />,
    },
    {
        path: "/user/vector-form",
        component: <VectorOrder />,
    },
    {
        path: "/user/all-vector-record",
        component: <VectorRecords />,
    },
    {
        path: "/user/customer/vector-order-details/:id",
        component: <VectorRecordsDetail />,
    },
    {
        path: "/user/all-invoices-record",
        component: <Invoices />,
    },
    {
        path: "/user/invoice-details/:id",
        component: <InvoiceDetails />,
    },
    {
        path: "/user/my-profile",
        component: <MyProfile />,
    },

    {
        path: "/user/reset-password",
        component: <ChangePassword />,
    },
];

export const customerSideBarItems: ISideBarRoutes[] = [
    {
        path: "/user/dashboard",
        icon: <img src={dashboardIcon} alt="dashboard-icon" />,
        title: "Dashboard",
        isSubNav: false,
    },
    {
        path: "/user/send-quote",
        icon: <img src={sendQuotesIcon} alt="quotes-icon" />,
        title: "Send Quotes",
        isSubNav: false,
    },
    {
        path: "/user/quote-all-record",
        icon: <img src={quoteRecordsIcon} alt="quotes-records-icon" />,
        title: "Quote Record",
        isSubNav: false,
    },
    {
        path: "/user/customer-order-form",
        icon: <img src={digitizingOrderIcon} alt="digitizing-order-icon" />,
        title: "Digitizing Order",
        isSubNav: false,
    },

    {
        path: "/user/all-orders",
        icon: <img src={digitizingRecordsIcon} alt="digitizing-records-icon" />,
        title: "Digitizing Records",
        isSubNav: false,
    },
    {
        path: "/user/vector-form",
        icon: <img src={vectorOrdersIcon} alt="vector-orders-icon" />,
        title: "Vector Order",
        isSubNav: false,
    },
    {
        path: "/user/all-vector-record",
        icon: <img src={vectorRecordsIcon} alt="vector-orders-icon" />,
        title: "Vector Records",
        isSubNav: false,
    },
    {
        path: "/user/all-invoices-record",
        icon: <img src={InvoicesIcon} alt="vector-orders-icon" />,
        title: "Invoices",
        isSubNav: false,
    },

    {
        path: "",
        icon: <img src={myAccountIcon} alt="my-account-icon" />,
        title: "My Account",
        isSubNav: true,
        subNav: [
            {
                path: "/user/my-profile",
                component: <MyProfile />,
                icon: <img src={myAccountIcon} alt="profile-icon" />,
                title: "My Profile"
            },
            {
                path: "/user/reset-password",
                component: <ChangePassword />,
                icon: <img src={LockICon} alt="lock-icon" />,
                title: "Change Password"
            },
        ]
    },
];