import React, { useEffect } from "react";
import { Container, Col, Row, Form, Spinner } from "react-bootstrap";
import Input from "../../../../../Components/Input/Input";
import { IChangePasswordForm } from "../../../../../Utils/interfaces";
import { useForm } from "react-hook-form";
import { changePassValidation } from "../../../../../Utils/validation";
import { changePassword } from "../../../../../Redux/Actions/Auth";
import { errorNotify, successNotify } from "../../../../../Utils/toast";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import Button from "../../../../../Components/Button/Button";
import { AnyAction } from "redux";
import LockICon from '../../../../../Assets/images/lock-pass.png'

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const ChangePassword = () => {

    const dispatch: AppDispatch = useDispatch();
    const { register: passRegister, handleSubmit, reset, formState: { errors } } = useForm<IChangePasswordForm>();
    const { loading: passChangedLoading, isChangedPass, error } = useSelector((state: any) => state.passChanged);

    useEffect(() => {
        if (isChangedPass?.status === 1) {
            successNotify(isChangedPass?.message)
            dispatch({ type: "CHANGE_PASSWORD_RESET" })
            reset()
        }
        if (error) {
            errorNotify(error)
            dispatch({ type: "CLEAR_ERRORS" })
        }
    }, [isChangedPass?.status, error]);

    const onSubmitHandler = handleSubmit(async (data: any) => {
        if (data.new_password !== data.conf_password) {
            errorNotify("New password & confirm password must be same!")
        }
        else {
            dispatch(changePassword(data))
        }
    });

    return (
        <div className="page_responsive">
            <Container>
                <div className="card d-flex">
                    <div className="send_quotes_head_container" id="profile_Div">
                        <img src={LockICon} alt="pencil-logo" />
                        <h4>Change Password</h4>
                    </div>
                </div>
            </Container>
            <div className="create_emp_bg_background">
                <Container>
                    <Form onSubmit={onSubmitHandler}>
                        <Row>
                            <Col md={12}>
                                <div className="personal_details_container">
                                    <div className="details_head">
                                        <h5>Reset Your Password</h5>
                                    </div>

                                    <div className="details_container">
                                        <Row>

                                            <Col md={12}>
                                                <Input>
                                                    <Form.Label>Old Password:</Form.Label>
                                                    <Form.Control
                                                        type="password"
                                                        placeholder="please enter your old password"
                                                        {...passRegister("current_password", changePassValidation.current_password)}
                                                    />
                                                </Input>
                                                {errors.current_password ? (
                                                    <small className="text-danger">
                                                        {errors.current_password && errors.current_password.message}
                                                    </small>
                                                ) : null}
                                            </Col>
                                            <Col md={12}>
                                                <Input>
                                                    <Form.Label>New Password:</Form.Label>
                                                    <Form.Control
                                                        type="password"
                                                        placeholder="please enter your new password"
                                                        {...passRegister("new_password", changePassValidation.new_password)}
                                                    />
                                                </Input>
                                                {errors.new_password ? (
                                                    <small className="text-danger">
                                                        {errors.new_password && errors.new_password.message}
                                                    </small>
                                                ) : null}
                                            </Col>

                                            <Col md={12}>
                                                <Input>
                                                    <Form.Label>Confirm Password:</Form.Label>
                                                    <Form.Control
                                                        type="password"
                                                        placeholder="Re-enter new password"
                                                        {...passRegister("conf_password", changePassValidation.conf_password)}
                                                    />
                                                </Input>
                                                {errors.conf_password ? (
                                                    <small className="text-danger">
                                                        {errors.conf_password && errors.conf_password.message}
                                                    </small>
                                                ) : null}
                                            </Col>

                                            <div className="update_pass_btn_container">
                                                <Button type="submit">
                                                    {
                                                        passChangedLoading ? <Spinner animation="border" size="sm" /> : "UPDATE"
                                                    }
                                                </Button>
                                            </div>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>
        </div>
    )
}

export default ChangePassword
