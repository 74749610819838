
export const adminDashboard = (state = [], action: any) => {
  switch (action.type) {
    case "DASHBOARD_DATA_REQUEST":
      return {
        ...state,
        loading: true,
      }
    case "DASHBOARD_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        dashboard: action.payload,
      }
    case "DASHBOARD_DATA_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state;
  }
}
export const adminQuotesListReducer = (state = [], action: any) => {
  switch (action.type) {
    case "ALL_QUOTES_LIST_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "ALL_QUOTES_LIST_SUCCESS":
      return {
        ...state,
        loading: false,
        quotes: action.payload,
        quotesCount: action.payload.data.count
      };
    case "ALL_QUOTES_LIST_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
        quotesCount: 0
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};

export const adminQuotesDeleteReducer = (state = [], action: any) => {
  switch (action.type) {
    case "ALL_QUOTES_DELETE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "ALL_QUOTES_DELETE_SUCCESS":
      return {
        ...state,
        loading: false,
        deletequotes: action.payload,
      };
    case "ALL_QUOTES_DELETE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "QUOTES_DELETE_RESET":
      return {
        ...state,
        deletequotes: {}
      }

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};


export const adminOrderListReducer = (state = [], action: any) => {
  switch (action.type) {
    case "ALL_ORDERS_LIST_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "ALL_ORDERS_LIST_SUCCESS":
      return {
        ...state,
        loading: false,
        orders: action.payload,
        ordersCount: action.payload.data.count
      };
    case "ALL_ORDERS_LIST_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
        ordersCount: 0
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};

export const adminOrderDeleteReducer = (state = [], action: any) => {
  switch (action.type) {
    case "ALL_ORDERS_DELETE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "ALL_ORDERS_DELETE_SUCCESS":
      return {
        ...state,
        loading: false,
        deleteOrders: action.payload,

      };
    case "ALL_ORDERS_DELETE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,

      };

    case "ALL_ORDERS_DELETE_RESET":
      return {
        ...state,
        deleteOrders: {}
      }

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};


export const adminVectorListReducer = (state = [], action: any) => {
  switch (action.type) {
    case "ALL_VECTORS_LIST_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "ALL_VECTORS_LIST_SUCCESS":
      return {
        ...state,
        loading: false,
        vectors: action.payload,
        vectorsCount: action.payload.data.count
      };
    case "ALL_VECTORS_LIST_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
        vectorsCount: 0
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};
export const adminVectorSendReducer = (state = [], action: any) => {
  switch (action.type) {
    case "SEND_VECTORS_ORDER_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "SEND_VECTORS_ORDER_SUCCESS":
      return {
        ...state,
        loading: false,
        order: action.payload,
      };
    case "SEND_VECTORS_ORDER_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "SEND_VECTORS_ORDER_RESET":
      return {
        ...state,
        order: null
      }

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};


export const adminVectorDeleteReducer = (state = [], action: any) => {
  switch (action.type) {
    case "ALL_VECTORS_DELETE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "ALL_VECTORS_DELETE_SUCCESS":
      return {
        ...state,
        loading: false,
        deleteVectors: action.payload,
      };
    case "ALL_VECTORS_DELETE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "ALL_VECTORS_DELETE_RESET":
      return {
        ...state,
        deleteVectors: {}
      }
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};


export const allUsersByRoleReducer = (state = [], action: any) => {
  switch (action.type) {
    case "ALL_USERS_BY_ROLE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "ALL_USERS_BY_ROLE_SUCCESS":
      return {
        ...state,
        loading: false,
        users: action.payload,
        userCount: action.payload.data.count
      };
    case "ALL_USERS_BY_ROLE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
        userCount: null
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};
export const quoteApprovedReducer = (state = [], action: any) => {
  switch (action.type) {
    case "QUOTE_APPROVED_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "QUOTE_APPROVED_SUCCESS":
      return {
        ...state,
        loading: false,
        approved: action.payload
      };
    case "QUOTE_APPROVED_RESET":
      return {
        ...state,
        loading: false,
        approved: null
      };
    case "QUOTE_APPROVED_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};
export const orderAssignedReducer = (state = {}, action: any) => {
  switch (action.type) {
    case "ORDER_ASSIGNED_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "ORDER_ASSIGNED_SUCCESS":
      return {
        ...state,
        loading: false,
        assigned: action.payload
      };
    case "ORDER_ASSIGNED_RESET":
      return {
        ...state,
        loading: false,
        assigned: null
      };
    case "ORDER_ASSIGNED_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};


export const getAllRolesListReducer = (state = { rolesList: [] }, action: any) => {
  switch (action.type) {
    case "GET_ALL_ROLES_LIST_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GET_ALL_ROLES_LIST_SUCCESS":
      return {
        ...state,
        loading: false,
        rolesList: action.payload,
      };
    case "GET_ALL_ROLES_LIST_FAILED":
      return {
        ...state,
        rolesList: null,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};




export const addUserReducer = (state = {}, action: any) => {
  switch (action.type) {
    case "ADD_USER_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "ADD_USER_SUCCESS":
      return {
        ...state,
        loading: false,
        addUser: action.payload,
      };
    case "ADD_USER_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "ADD_USER_RESET":
      return {
        ...state,
        addUser: null,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
}


export const AllUsersListReducer = (state = [], action: any) => {
  switch (action.type) {
    case "ALL_USERS_LIST_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "ALL_USERS_LIST_SUCCESS":
      return {
        ...state,
        loading: false,
        users: action.payload,
        usersCount: action.payload.data.count
      };
    case "ALL_USERS_LIST_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
        usersCount: 0
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};


export const allUsersDeleteReducer = (state = [], action: any) => {
  switch (action.type) {
    case "DELETE_USER_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "DELETE_USER_SUCCESS":
      return {
        ...state,
        loading: false,
        deleteUsers: action.payload,
      };
    case "DELETE_USER_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "DELETE_USER_RESET":
      return {
        ...state,
        deleteUsers: {}
      }

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};

export const getUserDetailsReducer = (state = { userDetails: [] }, action: any) => {
  switch (action.type) {
    case "GET_USER_DETAILS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GET_USER_DETAILS_SUCCESS":
      return {
        ...state,
        loading: false,
        userDetails: action.payload,
      };
    case "GET_USER_DETAILS_FAILED":
      return {
        ...state,
        userDetails: null,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};

export const updateUserDetailsReducer = (state = { userDetails: [] }, action: any) => {
  switch (action.type) {
    case "UPDATE_USER_DETAILS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "UPDATE_USER_DETAILS_SUCCESS":
      return {
        ...state,
        loading: false,
        updateUser: action.payload,
      };
    case "UPDATE_USER_DETAILS_FAILED":
      return {
        ...state,
        userDetails: null,
        loading: false,
        error: action.payload,
      };

    case "UPDATE_USER_DETAILS_RESET":
      return {
        updateUser: {},
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};

// Order Sent To Customer

export const orderSentToCustomerReducer = (state = {}, action: any) => {
  switch (action.type) {
    case "ORDER_SENT_TO_CUSTOMER_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "ORDER_SENT_TO_CUSTOMER_SUCCESS":
      return {
        ...state,
        loading: false,
        sentToCustomer: action.payload,
      };
    case "ORDER_SENT_TO_CUSTOMER_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "ORDER_SENT_TO_CUSTOMER_RESET":
      return {
        ...state,
        loading: false,
        sentToCustomer: null
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};

// INVOICING 


export const generateInvoiceReducer = (state = { generateInvoice: [] }, action: any) => {
  switch (action.type) {
    case "GENERATE_INVOICE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GENERATE_INVOICE_SUCCESS":
      return {
        ...state,
        loading: false,
        generateInvoice: action.payload,
      };
    case "GENERATE_INVOICE_FAILED":
      return {
        ...state,
        generateInvoice: null,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};


export const invoiceCreateReducer = (state = {}, action: any) => {
  switch (action.type) {
    case "CREATE_INVOICE_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "CREATE_INVOICE_SUCCESS":
      return {
        ...state,
        loading: false,
        invoiceData: action.payload,
      };
    case "CREATE_INVOICE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CREATE_INVOICE_RESET":
      return {
        ...state,
        invoiceData: null,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
}

export const AllInvoicesListReducer = (state = [], action: any) => {
  switch (action.type) {
    case "GET_INVOICE_LIST_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GET_INVOICE_LIST_SUCCESS":
      return {
        ...state,
        loading: false,
        invoicesList: action.payload,
        invoicesCount: action.payload.data.count
      };
    case "GET_INVOICE_LIST_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
        invoicesCount: 0
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};


export const getInvoicesDetailsReducer = (state = { invoiceDetail: [] }, action: any) => {
  switch (action.type) {
    case "GET_INVOICE_DETAIL_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GET_INVOICE_DETAIL_SUCCESS":
      return {
        ...state,
        loading: false,
        invoiceDetail: action.payload,
      };
    case "GET_INVOICE_DETAIL_FAILED":
      return {
        ...state,
        invoiceDetail: null,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};


// USER ASSIGN TO SALES PERSON

export const userAssignedToSalesReducer = (state = {}, action: any) => {
  switch (action.type) {
    case "USER_ASSIGNED_TO_SALESPERSON_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "USER_ASSIGNED_TO_SALESPERSON_SUCCESS":
      return {
        ...state,
        loading: false,
        assignedToSales: action.payload
      };
    case "USER_ASSIGNED_TO_SALESPERSON_RESET":
      return {
        ...state,
        loading: false,
        assignedToSales: null
      };
    case "USER_ASSIGNED_TO_SALESPERSON_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};


// MO Order Save Reducer

export const MoOrderSaveReducer = (state = {}, action: any) => {
  switch (action.type) {
    case "MO_ORDER_SAVE_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "MO_ORDER_SAVE_SUCCESS":
      return {
        ...state,
        loading: false,
        moOrderData: action.payload,
      };
    case "MO_ORDER_SAVE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "MO_ORDER_SAVE_RESET":
      return {
        ...state,
        moOrderData: null,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
}

// MO Order List

export const MoOrderListReducer = (state = [], action: any) => {
  switch (action.type) {
    case "MO_ORDER_LIST_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "MO_ORDER_LIST_SUCCESS":
      return {
        ...state,
        loading: false,
        moOrderList: action.payload,
        moOrderCount: action.payload.data.count
      };
    case "MO_ORDER_LIST_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
        moOrderCount: 0
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};

// MO Order Details

export const getMoOrderDetailsReducer = (state = { moOrderDetails: [] }, action: any) => {
  switch (action.type) {
    case "GET_MO_ORDER_DETAILS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GET_MO_ORDER_DETAILS_SUCCESS":
      return {
        ...state,
        loading: false,
        moOrderDetails: action.payload,
      };
    case "GET_MO_ORDER_DETAILS_FAILED":
      return {
        ...state,
        moOrderDetails: null,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};


// MO Order Approve

export const moOrderApproveReducer = (state = {}, action: any) => {
  switch (action.type) {
    case "ADMIN_MO_ORDER_APPROVE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "ADMIN_MO_ORDER_APPROVE_SUCCESS":
      return {
        ...state,
        loading: false,
        moOrderApprove: action.payload
      };
    case "ADMIN_MO_ORDER_APPROVE_RESET":
      return {
        ...state,
        loading: false,
        moOrderApprove: null
      };
    case "ADMIN_MO_ORDER_APPROVE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};


// VECTOR ORDER ASSIGNED

export const VectorOrderAssignedReducer = (state = {}, action: any) => {
  switch (action.type) {
    case "VECTOR_ORDER_ASSIGNED_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "VECTOR_ORDER_ASSIGNED_SUCCESS":
      return {
        ...state,
        loading: false,
        assignedVector: action.payload
      };
    case "VECTOR_ORDER_ASSIGNED_RESET":
      return {
        ...state,
        loading: false,
        assignedVector: null
      };
    case "VECTOR_ORDER_ASSIGNED_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};


// VECTOR ORDER ATTACHMENTS UPLOAD

export const vectorOrderAttachmentReducer = (state = [], action: any) => {
  switch (action.type) {
    case "VECTOR_ORDER_ATTACHMENT_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "VECTOR_ORDER_ATTACHMENT_SUCCESS":
      return {
        ...state,
        loading: false,
        vectorOrder: action.payload,
      };
    case "VECTOR_ORDER_ATTACHMENT_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "VECTOR_ORDER_ATTACHMENT_RESET":
      return {
        ...state,
        vectorOrder: null
      }

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};

// VECTOR ORDER SENT TO CUSTOMER COMPLETED

export const VectorOrderSentToCustomerReducer = (state = {}, action: any) => {
  switch (action.type) {
    case "VECTOR_ORDER_SENT_TO_CUSTOMER_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "VECTOR_ORDER_SENT_TO_CUSTOMER_SUCCESS":
      return {
        ...state,
        loading: false,
        vectorSentToCustomer: action.payload,
      };
    case "VECTOR_ORDER_SENT_TO_CUSTOMER_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "VECTOR_ORDER_SENT_TO_CUSTOMER_RESET":
      return {
        ...state,
        loading: false,
        vectorSentToCustomer: null
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};