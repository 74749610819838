import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { getUserVectorOrderDetails } from "../../../../../Redux/Actions/UserVectorOrder";
import { deleteAdminVectorsRecords } from "../../../../../Redux/Actions/admin";
import { errorNotify, successNotify } from "../../../../../Utils/toast";
import Loader from "../../../../../Utils/Loader";
import moment from "moment";
import Modal from "../../../../../Components/SiteModal/SiteModal";
import { downloadFile, cloudBaseURL } from '../../../../../Utils/helper'
import VectorIcon from "../../../../../Assets/images/vector.png";

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const VectorOrderDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { error, loading, vectorDetails } = useSelector((state: any) => state.userVectorDetails);
  const { deleteVectors, loading: deleteLoading } = useSelector((state: any) => state.adminDeleteVectors);
  const customerID = vectorDetails?.data?.Customer?.id;
  const dispatch: AppDispatch = useDispatch();
  const [show, setShow] = React.useState(false);

  useEffect(() => {
    if (error) {
      errorNotify(error);
      dispatch({ type: "CLEAR_ERRORS" });
    }
    dispatch(getUserVectorOrderDetails(id));
  }, []);

  useEffect(() => {
    if (deleteVectors && deleteVectors.status === 1) {
      successNotify("Vector Deleted Successfully");
      setShow(false);
      navigate(-1);
      dispatch({ type: "ALL_VECTORS_DELETE_RESET" });
    }
  }, [deleteVectors]);

  const deleteOrderRecords = (id: any) => {
    dispatch(deleteAdminVectorsRecords(id));
  };

  const VectorOrderIdHandler = () => {
    setShow(!show);
  };

  const modal = (
    <Modal
      title="Delete"
      size={"lg"}
      show={show}
      onCloseModal={() => setShow(!show)}
    >
      <React.Fragment>
        <p>Are u sure you want to delete this vector order?</p>
        <div className="modal_decision">
          <button
            className="my-1 details_btn bg-red"
            onClick={() => deleteOrderRecords(id)}
          >
            {deleteLoading ? <Spinner animation="border" size="sm" /> : "Yes"}
          </button>
          <button
            className="my-1 details_btn sent_order"
            onClick={() => setShow(false)}
          >
            No
          </button>
        </div>
      </React.Fragment>
    </Modal>
  );

  return (
    <div className="page_responsive">
      <div className="card">
        <div className="send_quotes_head_container" id="vector_order_div">
          <div className='send_vector_order_div_shorter'>
            <img src={VectorIcon} alt="pencil-logo" />
          </div>
          <h4>Vector Order Details</h4>
        </div>
      </div>
      <div className="user_details_card">
        <Card className="order_details_card">
          {loading ? (
            <Loader />
          ) : (
            <Card.Body>
              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Order ID:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">{vectorDetails?.data?.vector_no}</Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Order Name:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {vectorDetails?.data?.vector_name}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Order Date:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {moment(vectorDetails?.data?.send_date)
                          .utc()
                          .format("DD-MM-YYYY")}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Customer Username:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {vectorDetails?.data?.Customer?.username}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Customer Full Name:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {vectorDetails?.data?.Customer?.first_name}{" "}
                        {vectorDetails?.data?.Customer?.last_name}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Required Format:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {vectorDetails?.data?.Format?.name}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Number of Colors:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">{vectorDetails?.data?.no_color}</Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Super Urgent:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {parseInt(vectorDetails?.data?.urgent) === 1
                          ? "Urgent"
                          : "Not Urgent"}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-around my-1">
                <Col md={11} className="m-1 detail_field">
                  <Row>
                    <Col md={12}>
                      <Typography variant="h6">
                        Additional Instruction:{" "}
                      </Typography>
                      <Typography>
                        {vectorDetails?.data?.additional_instruction}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Status:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {parseInt(vectorDetails?.data?.status) === 0 ? "Pending" : parseInt(vectorDetails?.data?.status) === 1 ? "In process" : "Completed"}
                          
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Price:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {
                          parseInt(vectorDetails?.data?.price) <= 0
                            ? "Price not selected"
                            : vectorDetails?.data?.Customer?.Country
                              ?.currency_symbol + vectorDetails?.data?.price}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {(vectorDetails?.data?.attachments?.length > 0) && (
                <Row className="justify-content-around my-1">
                  <Col md={11} className="m-1">
                    <Typography variant="h4">Customer Attachments:</Typography>
                  </Col>
                </Row>
              )}
              <Row className="justify-content-around my-1">
                {(vectorDetails?.data?.attachments) &&
                  vectorDetails?.data?.attachments.map(
                    (attachment: any, i: any) => {
                      if (parseInt(attachment?.attachment_type) === 0) {
                        return (
                          <Col md={11} className="m-1 detail_field">
                            <Row className="align-items-center">
                              <Col md={4}>
                                <Typography variant="h6">File {i + 1}:</Typography>
                              </Col>
                              <Col md={4}>
                                <Typography className="text-center">
                                  {attachment.attachment_name}
                                </Typography>
                              </Col>
                              <Col md={4} className="d-flex justify-content-end">
                                <button className="download_btn" onClick={() => downloadFile(`${cloudBaseURL}vector/${id}/${attachment.attachment_name}`, `${attachment.attachment_name}`)}>
                                  <FaDownload /> Download
                                </button>
                              </Col>
                            </Row>
                          </Col>
                        )
                      }
                    }
                  )}
              </Row>

              {(parseInt(vectorDetails?.data?.designer_status) === 1 && vectorDetails?.data?.attachments) && (
                <Row className="justify-content-around my-1">
                  <Col md={11} className="m-1">
                    <Typography variant="h4">Designer Attachments:</Typography>
                  </Col>
                </Row>
              )}
              <Row className="justify-content-around my-1">
                {(vectorDetails?.data?.attachments && parseInt(vectorDetails?.data?.designer_status) === 1) &&
                  vectorDetails?.data?.attachments.map(
                    (attachment: any, i: any) => {
                      if (parseInt(attachment?.attachment_type) === 1) {
                        return (
                          <Col md={11} className="m-1 detail_field">
                            <Row className="align-items-center">
                              <Col md={4}>
                                <Typography variant="h6">File {i + 1}:</Typography>
                              </Col>
                              <Col md={4}>
                                <Typography className="text-center">
                                  {attachment.attachment_name}
                                </Typography>
                              </Col>
                              <Col md={4} className="d-flex justify-content-end">
                                <button className="download_btn" onClick={() => downloadFile(`${cloudBaseURL}vector/${id}/${attachment.attachment_name}`, `${attachment.attachment_name}`)}>
                                  <FaDownload /> Download
                                </button>
                              </Col>
                            </Row>
                          </Col>
                        )
                      }
                    }
                  )}
              </Row>

              <Row className="pt-4 justify-content-center">
                <Col xs={12} md={12} className='button_container_main'>
                  {
                    parseInt(vectorDetails?.data.status) === 1 &&  parseInt(vectorDetails?.data.designer_status) === 1 ?
                      <button
                        className="my-1 details_btn bg-gray"
                        id={`${parseInt(vectorDetails?.data.status) === 2
                          ? "disabled_btn"
                          : null
                          }`}
                        disabled={
                          parseInt(vectorDetails?.data.status) === 2 ? true : false
                        }
                        onClick={() =>
                          navigate(
                            `/admin/vector-order-attachment/${vectorDetails?.data?.vector_no}`,
                            {
                              state: {
                                vectorName: vectorDetails?.data?.vector_name,
                              },
                            }
                          )
                        }
                      >
                        {parseInt(vectorDetails?.data.status) === 2
                          ? "Order Sent"
                          : "Sent To Customer"}
                      </button>
                      : null
                  }

                  <button
                    className="my-1 details_btn bg-gray"
                    id={`${parseInt(vectorDetails?.data.status) === 1 || parseInt(vectorDetails?.data.designer_status) === 1
                      ? "disabled_btn"
                      : null
                      }`}
                    disabled={
                      parseInt(vectorDetails?.data.designer_status) === 1 ? true : false
                    }
                    onClick={() =>
                      navigate(
                        `/admin/vector-assign-order/${vectorDetails?.data?.vector_no}`,
                        { state: { name: vectorDetails?.data?.vector_name } }
                      )
                    }
                  >
                    {`${parseInt(vectorDetails?.data.designer_status) === 1
                      ? "Order Assigned"
                      : "Assign Order"
                      }`}
                  </button>




                  <button
                    className="my-1 details_btn sent_order"
                    onClick={() =>
                      navigate(`/admin/customer-details/${customerID}`)
                    }
                  >
                    Customer Detail
                  </button>
                  <button
                    onClick={() => VectorOrderIdHandler()}
                    className="my-1 details_btn bg-red"
                  >
                    {deleteLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Delete Order"
                    )}
                  </button>
                </Col>
              </Row>
            </Card.Body>
          )}
        </Card>
      </div>
      {modal}
    </div>
  );
};

export default VectorOrderDetails;
