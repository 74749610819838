import React from 'react';
import UserLogin from '../Auth/UserLogin';

const Home = () => {
    return (
        <React.Fragment>
           <UserLogin />
        </React.Fragment>
    )
}

export default Home
