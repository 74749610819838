import api from "../../Utils/interceptors";
import { getToken } from "../../Utils/helper";


export const userSendOrder = (orderData: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: "SEND_DIGITIZING_ORDER_REQUEST",
        });

        const { data } = await api.post("/digitizing/order", orderData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: getToken()
            }
        });

        dispatch({
            type: "SEND_DIGITIZING_ORDER_SUCCESS",
            payload: data,
            success: true,
        });
    }
    catch (e: any) {
        dispatch({
            type: "SEND_DIGITIZING_ORDER_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
};
export const getUserOrdersRecord = (pageNO: number, perPage: number, text: string) => async (dispatch: any) => {
    try {
        dispatch({
            type: "DIGITIZING_ORDER_LIST_REQUEST"
        })

        const { data } = await api.get(`/digitizing/list?page_no=${pageNO + 1}&per_page=${perPage}&term=${text}`)

        dispatch({
            type: "DIGITIZING_ORDER_LIST_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e: any) {
        dispatch({
            type: "DIGITIZING_ORDER_LIST_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
}

export const getUserOrderDetails = (order_id: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: "DIGITIZING_ORDER_DETAIL_REQUEST"
        })

        const { data } = await api.get(`/digitizing/order/${order_id}`)

        dispatch({
            type: "DIGITIZING_ORDER_DETAIL_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e: any) {
        dispatch({
            type: "DIGITIZING_ORDER_DETAIL_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
}

// Customer Delete Orders

export const deleteCustomerOrderRecords = (order_id: string) => async (dispatch: any) => {

    try {
        dispatch({
            type: "CUSTOMER_ORDER_DELETE_REQUEST",
        });

        const { data } = await api.delete(`/digitizing/${order_id}`);

        dispatch({
            type: "CUSTOMER_ORDER_DELETE_SUCCESS",
            payload: data,
            success: true,
        });
    } catch (e: any) {
        dispatch({
            type: "CUSTOMER_ORDER_DELETE_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
};

