import React, { useEffect, useState } from "react";
import MuiDataTable from "../../../../Components/MUIDataTable/MUIDataTable";
import { Link, useNavigate } from "react-router-dom";
import { getUserQuotesRecord, quoteConvertIntoOrder, deleteCustomerQuotesRecords } from "../../../../Redux/Actions/UserQuotes";
import { useDispatch, useSelector } from "react-redux";
import { errorNotify, successNotify } from "../../../../Utils/toast";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { Spinner } from "react-bootstrap";
import "./QuoteRecord.css";
import Loader from "../../../../Utils/Loader";
import moment from "moment";
import eyeIcon from '../../../../Assets/images/eye.png';
import deleteIcon from '../../../../Assets/images/delete-icon.png';
import Modal from '../../../../Components/SiteModal/SiteModal';

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const QuoteRecord = () => {
  const { loading, getQuotes, totalCount } = useSelector((state: any) => state.getUserAllQuotes);
  const { error: convertError, loading: convertLoading, covertQuote } = useSelector((state: any) => state.convertQuoteIntoOrder);
  const { error: deleteError, loading: deleteLoading, deleteCustomerQuote } = useSelector((state: any) => state.customerQuotesDelete);
  const dispatch: AppDispatch = useDispatch();
  const navigation = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [text, setText] = useState('')
  const [show, setShow] = useState(false);
  const [orderID, setOrderID] = useState("");

  const convertIntoOrder = (id: any) => {
    dispatch(quoteConvertIntoOrder(id));
  };

  useEffect(() => {
    if (deleteCustomerQuote && deleteCustomerQuote?.status === 1) {
      successNotify('Quote Deleted Successfully')
      setShow(false)
      dispatch({ type: "CUSTOMER_QUOTES_DELETE_RESET" })
      dispatch(getUserQuotesRecord(page, rowsPerPage, text));
    }

  }, [deleteCustomerQuote])// eslint-disable-next-line

  const orderIDHandler = (id: string) => {
    setOrderID(id);
    setShow(!show);
  };

  const modal = (
    <Modal title="Delete" size={'lg'} show={show} onCloseModal={() => setShow(!show)}>
      <React.Fragment>
        <p>Are u sure you want to delete this quote?</p>
        <div className="modal_decision">
          <button className="my-1 details_btn bg-red" onClick={() => dispatch(deleteCustomerQuotesRecords(orderID))}>
            {deleteLoading ? <Spinner animation='border' size='sm' /> : "Yes"}
          </button>
          <button className="my-1 details_btn sent_order" onClick={() => setShow(false)}>No</button>
        </div>
      </React.Fragment>
    </Modal>
  )


  useEffect(() => {
    dispatch(getUserQuotesRecord(page, rowsPerPage, text));
    if (covertQuote?.message) {
      successNotify(covertQuote?.message);
      dispatch({ type: "QUOTE_CONVERT_RESET" });
    }
    if (convertError) {
      errorNotify(convertError);
      dispatch({ type: "CLEAR_ERRORS" });
    }
  }, [convertError, covertQuote, page, rowsPerPage, text]);// eslint-disable-line react-hooks/exhaustive-deps

  const quoteRecordsColumns = [
    { name: "sno", label: "S:NO" },
    { name: "quote_no", label: "QUOTE NO" },
    { name: "quote_name", label: "QUOTE NAME" },
    {
      name: "send_date",
      label: "SEND DATE",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return moment(new Date(`${value} UTC`)).format("DD-MM-YYYY hh:mm:ss");
        },
      },
    },
    {
      name: "status",
      label: "STATUS",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <React.Fragment>
              {parseInt(tableMeta.rowData[4]) === 0 ? (
                <div className="status-pending">
                  <p className="mb-0">pending</p>
                </div>
              ) : (
                <div className="status_completed">
                  <p className="mb-0">approved</p>
                </div>
              )}
            </React.Fragment>
          );
        },
      },
    },
    {
      name: "convert_into_order",
      label: "CONVERT TO ORDER",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <div className="action_container">
              {parseInt(tableMeta.rowData[4]) === 1 && parseInt(tableMeta.rowData[5]) === 0 ? (
                <button
                  className="convert_btn"
                  type="button"
                  onClick={() => convertIntoOrder(tableMeta.rowData[1])}
                >
                  {convertLoading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Order Now"
                  )}
                </button>
              ) : parseInt(tableMeta.rowData[4]) === 1 && parseInt(tableMeta.rowData[5]) === 1 ? (
                <button
                  id='disabled_btn'
                  className="convert_btn"
                  type="button"
                  disabled
                >
                  {convertLoading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Sent Order"
                  )}
                </button>
              ) : "-"}
            </div>
          );
        },
      },
    },
    {
      name: "Quote Details",
      label: "DETAILS",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <div className="action_container">

              {
                parseInt(tableMeta?.rowData[4]) === 0 ?
                  <button
                    className="delete-btn"
                    type="button"
                    onClick={() => orderIDHandler(tableMeta.rowData[1])}
                  >
                    <img src={deleteIcon} alt="eye" />
                  </button> : null
              }

              <Link to={`/user/quote-record-details/${tableMeta.rowData[1]}`}>
                <button
                  className="view-btn"
                  type="button"
                  style={{ cursor: "pointer" }}
                >
                  <img src={eyeIcon} alt="eye" />
                </button>
              </Link>
            </div>
          );
        },
      },
    },
  ];

  // Adding Seriel Number

  let newData: any = [];
  // rowsPerPage * (page - 1) + index + 1
  getQuotes?.rows?.map((item: any, index: any) => {
    newData.push({ sno: rowsPerPage * page + index + 1, ...item });
  });

  return (
    <div className="page_responsive">
      {/* <h4 className="text-end">Total Records: {getQuotes?.count}</h4> */}
      <div className="quotes-table">
        {!text && loading ? (
          <Loader />
        ) : (
          <MuiDataTable
            title={"QUOTE RECORDS"}
            data={newData}
            columns={quoteRecordsColumns}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            count={totalCount}
            setText={setText}
            text={text}
          />
        )}
      </div>
      {modal}
    </div>
  );
};

export default QuoteRecord;
