import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import MuiDataTable from "../../../../Components/MUIDataTable/MUIDataTable";
import { getAdminOrdersRecords, deleteAdminOrdersRecords } from "../../../../Redux/Actions/admin";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import Loader from "../../../../Utils/Loader";
import moment from "moment";
import { successNotify } from "../../../../Utils/toast";
import Modal from "../../../../Components/SiteModal/SiteModal";
import OrderIcon from "../../../../Assets/images/orders.png";
import deleteIcon from '../../../../Assets/images/delete-icon.png';
import eyeIcon from '../../../../Assets/images/eye.png';

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const AllOrders: React.FC<any> = ({ style, today = '' }) => {
  const { loading, orders, ordersCount } = useSelector((state: any) => state?.adminAllOrders);
  const { deleteOrders, loading: deleteLoading } = useSelector((state: any) => state?.adminDeleteOrders);
  const dispatch: AppDispatch = useDispatch();
  const navigation = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [text, setText] = useState("");
  const [show, setShow] = useState(false);
  const [orderID, setOrderID] = useState("");

  useEffect(() => {
    dispatch(getAdminOrdersRecords(page, rowsPerPage, text, today));
  }, [page, rowsPerPage, text, dispatch]);

  useEffect(() => {
    if (deleteOrders && deleteOrders?.status === 1) {
      successNotify("Order Deleted Successfully");
      setShow(false);
      dispatch({ type: "ALL_ORDERS_DELETE_RESET" });
      dispatch(getAdminOrdersRecords(page, rowsPerPage, text, today));
    }
  }, [deleteOrders]); // eslint-disable-line react-hooks/exhaustive-deps

  const orderIDHandler = (id: string) => {
    setOrderID(id);
    setShow(!show);
  };

  const modal = (
    <Modal
      title="Delete"
      size={"lg"}
      show={show}
      onCloseModal={() => setShow(!show)}
    >
      <React.Fragment>
        <p>Are u sure you want to delete this order?</p>
        <div className="modal_decision">
          <button
            className="my-1 details_btn bg-red"
            onClick={() => dispatch(deleteAdminOrdersRecords(orderID))}
          >
            {deleteLoading ? <Spinner animation="border" size="sm" /> : "Yes"}
          </button>
          <button
            className="my-1 details_btn sent_order"
            onClick={() => setShow(false)}
          >
            No
          </button>
        </div>
      </React.Fragment>
    </Modal>
  );

  const quotesColumns = [
    { name: "sno", label: "S:NO" },
    { name: "order_id", label: "ORDER NO" },
    { name: "order_name", label: "ORDER NAME" },
    {
      name: "Customer",
      label: "CUSTOMER NAME"
    },
    {
      name: "Digitizer",
      label: "DIGITIZER NAME",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return value ? value?.username : "-";
        },
      },
    },
    {
      name: "digitizer_order_status",
      label: "DIGITIZER STATUS",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <React.Fragment>
              {parseInt(tableMeta?.rowData[5]) === 0 ? (
                <div className="status-pending">
                  <p className="mb-0">pending</p>
                </div>
              ) : parseInt(tableMeta?.rowData[5]) === 1 ? (
                <div className="status_completed">
                  <p className="mb-0">completed</p>

                </div>
              ) : <div className="status_completed">
                <p className="mb-0">-</p>
              </div>}
            </React.Fragment>
          );
        },
      },
    },
    {
      name: "createdAt",
      label: "ORDER DATE",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return moment(new Date(`${value} UTC`)).format("DD-MM-YYYY hh:mm:ss");
        },
      },
    },
    {
      name: "price",
      label: "PRICE",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          if (!value || null || 0) {
            return '-'
          }
          return (
            <span>
              {tableMeta.rowData[3]?.Country?.currency_symbol}
              {value}
            </span>
          );
        },
      },
    },
    {
      name: "status",
      label: "ORDER STATUS",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <React.Fragment>
              {parseInt(tableMeta?.rowData[8]) === 0 ? (
                <div className="status-pending">
                  <p className="mb-0">pending</p>
                </div>
              ) : parseInt(tableMeta?.rowData[8]) === 1 ? (
                <div className="status-inProcess">
                  <p className="mb-0">In process</p>

                </div>
              ) : <div className="status_completed">
                <p className="mb-0">Completed</p>
              </div>}
            </React.Fragment>
          );
        },
      },
    },
    {
      name: "Order Details",
      label: "DETAILS",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <div className="action_container">
              <Link to={`/admin/order-details/${tableMeta.rowData[1]}`}>
                <button
                  className="view-btn"
                  type="button"
                >
                  <img src={eyeIcon} alt="eye" />
                </button>
              </Link>
              <button
                className="delete-btn"
                type="button"
                onClick={() => orderIDHandler(tableMeta.rowData[1])}
              >
                <img src={deleteIcon} alt="eye" />
              </button>
            </div>
          );
        },
      },
    },
  ];

  let newData: any = [];
  orders?.data?.rows?.map((item: any, index: any) => {
    newData.push({ sno: rowsPerPage * page + index + 1, ...item, Customer: item?.Customer?.username });
  });

  return (
    <div className={`${style ? style : "page_responsive"}`}>
      <div className="card">
        <div className="send_quotes_head_container" id="digitizer_order">
          <div className='send_digitizing_order_div_shorter'>
            <img src={OrderIcon} alt="pencil-logo" />
          </div>
          <h4>{today !== "" ? "Today's All Digitizing Orders" : "All Digitizing Orders"}</h4>
        </div>
      </div>
      <Row>
        <Col md={12} className="p-2 my-3 digitizing-table">
          {!text && loading ? (
            <Loader />
          ) : (
            <MuiDataTable
              title={today !== "" ? "Today's All Digitizing Orders" : "All Digitizing Orders"}
              data={newData}
              columns={quotesColumns}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              count={ordersCount}
              setText={setText}
              text={text}
            />
          )}
        </Col>
      </Row>
      {modal}
    </div>
  );
};

export default AllOrders;
