import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import MuiDataTable from "../../../../../Components/MUIDataTable/MUIDataTable";
import { getMoOrderList } from "../../../../../Redux/Actions/admin";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import Loader from "../../../../../Utils/Loader";
import moment from "moment";
import OrderIcon from "../../../../../Assets/images/orders.png";
import eyeIcon from '../../../../../Assets/images/eye.png';

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const MOOrderList: React.FC<any> = () => {
    const { loading, moOrderList, moOrderCount } = useSelector((state: any) => state?.MoAdminOrderList);

    const dispatch: AppDispatch = useDispatch();
    const navigation = useNavigate();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [text, setText] = useState("");

    useEffect(() => {
        dispatch(getMoOrderList(page, rowsPerPage, text));
    }, [page, rowsPerPage, text, dispatch]);

    const quotesColumns = [
        { name: "sno", label: "S:NO" },
        { name: "order_id", label: "ORDER ID" },
        { name: "order_name", label: "ORDER NAME" },
        {
            name: "submittedAt",
            options: {
                display: false
            }
        },
        {
            name: "createdAt",
            label: "ORDER DATE",
            options: {
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return moment(new Date(`${value} UTC`)).format("DD-MM-YYYY hh:mm:ss A");
                },
            },
        },
        {
            name: "task_expiry",
            label: "TASK EXPIRY",
            options: {
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    if ((parseInt(tableMeta.rowData[6]) === 0 && new Date() > new Date(`${value} UTC`)) || (parseInt(tableMeta.rowData[6]) !== 0 && new Date(`${tableMeta.rowData[3]} UTC`) > new Date(`${value} UTC`))) {
                        return (
                            <div className='status-expired'>
                                {moment(new Date(`${value} UTC`)).format("DD-MM-YYYY hh:mm:ss A")}
                            </div>
                        )
                    }
                    else {
                        return moment(new Date(`${value} UTC`)).format("DD-MM-YYYY hh:mm:ss A");
                    }
                },
            },
        },

        {
            name: "status", label: "STATUS",
            options: {
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    if (parseInt(value) === 0) {
                        return (
                            <div className='status-pending'>
                                Pending
                            </div>
                        );
                    }
                    else if (parseInt(value) === 1) {
                        return (
                            <div className='status_completed'>
                                Completed
                            </div>
                        )
                    }
                    else if (parseInt(value) === 2) {
                        return (
                            <div className='status_completed'>
                                Approved
                            </div>
                        )
                    }
                },
            },
        },
        {
            name: "Order Details",
            label: "DETAILS",
            options: {
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (
                        <div className="action_container">
                            <Link to={`/admin/mo-order-details/${tableMeta.rowData[1]}`}>
                                <button
                                    className="view-btn"
                                    type="button"
                                >
                                    <img src={eyeIcon} alt="eye" />
                                </button>
                            </Link>
                        </div>
                    );
                },
            },
        },
    ];

    let newData: any = [];
    moOrderList?.data?.data?.map((item: any, index: any) => {
        newData.push({ sno: rowsPerPage * page + index + 1, ...item });
    });

    return (
        <div className="page_responsive">
            <div className="card">
                <div className="send_quotes_head_container" id="digitizer_order">
                    <div className='send_digitizing_order_div_shorter'>
                        <img src={OrderIcon} alt="pencil-logo" />
                    </div>
                    <h4>{"All MO Orders List"}</h4>
                </div>
            </div>
            <Row>
                <Col md={12} className="p-2 my-3 digitizing-table">
                    {!text && loading ? (
                        <Loader />
                    ) : (
                        <MuiDataTable
                            title={"Machine Operator Orders"}
                            data={newData}
                            columns={quotesColumns}
                            page={page}
                            setPage={setPage}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            count={moOrderCount}
                            setText={setText}
                            text={text}
                        />
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default MOOrderList;
