import React, { useEffect, useCallback } from "react";
import MUIDataTable, { FilterType } from "mui-datatables";
import { debounce } from "lodash"

const MuiDataTable: React.FC<any> = ({
  data,
  columns,
  title,
  setPage,
  page,
  rowsPerPage,
  setRowsPerPage,
  count,
  setText,
  text
}) => {

  const option: FilterType | any = {
    filter: false,
    filterType: "checkbox",
    search: true,
    viewColumns: false,
    responsive: "standard",
    selectableRows: "none",
    rowsPerPageOptions: [5, 10, 15, 20,50,100],
    rowsPerPage,
    page,
    count,
    serverSide: true,
    sort: false,
    jumpToPage: false,
    searchText: text,

    onChangePage(currentPage: any) {
      setPage(currentPage)
    },
    onChangeRowsPerPage(numberOfRows: any) {
      setRowsPerPage(numberOfRows);
    },

    onSearchChange: (searchText: any) => {
      if (searchText === null) {
        setText('')
      }
      else {
        debouncedSave(searchText)
      }
    },
  };

  const debouncedSave = useCallback(
    debounce(nextValue => setText(nextValue), 1000),
    [],
  );

  useEffect(() => {
    return () => {
      debouncedSave.cancel();
    };
  }, [debouncedSave]);

  return (
    <MUIDataTable title={title} data={data} columns={columns} options={option} />
  );
};

export default MuiDataTable;
