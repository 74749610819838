export const userSendQuotesReducer = (state = {}, action: any) => {
    switch (action.type) {
        case "SEND_QUOTES_REQUEST":
            return {
                ...state,
                loading: true,
            };

        case "SEND_QUOTES_SUCCESS":
            return {
                ...state,
                loading: false,
                quotesData: action.payload,
            };
        case "SEND_QUOTES_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "SEND_QUOTES_RESET":
            return {
                ...state,
                quotesData: null,
            };
        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}


export const getAllSendQuotesReducer: any = (state = { getQuotes: [] }, action: any) => {
    switch (action.type) {
        case "GET_SEND_QUOTES_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "GET_SEND_QUOTES_SUCCESS":
            return {
                ...state,
                loading: false,
                getQuotes: action.payload?.data,
                totalCount: action.payload?.data?.count,
                error: false
            }

        case "GET_SEND_QUOTES_FAILED":
            return {
                ...state,
                loading: false,
                getQuotes: null,
                totalCount: 0,
                error: action.payload
            }

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

export const getIndivisualQuotesReducer: any = (state = { getSingleQuote: [] }, action: any) => {
    switch (action.type) {
        case "GET_QUOTES_DETAIL_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "GET_QUOTES_DETAIL_SUCCESS":
            return {
                ...state,
                loading: false,
                getSingleQuote: action.payload,
                error: false
            }

        case "GET_QUOTES_DETAIL_FAILED":
            return {
                ...state,
                loading: false,
                getSingleQuote: null,
                error: action.payload
            }

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}


export const getUserProfileDataReducer: any = (state = { getUserProfile: [] }, action: any) => {
    switch (action.type) {
        case "GET_USER_PROFILE_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "GET_USER_PROFILE_SUCCESS":
            return {
                ...state,
                loading: false,
                getUserProfile: action.payload,
                error: false
            }

        case "GET_USER_PROFILE_FAILED":
            return {
                ...state,
                loading: false,
                getUserProfile: null,
                error: action.payload
            }

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

export const convertQuoteIntoOrderReducer: any = (state = { covertQuote: [] }, action: any) => {
    switch (action.type) {
        case "QUOTE_CONVERT_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "QUOTE_CONVERT_SUCCESS":
            return {
                ...state,
                loading: false,
                covertQuote: action.payload,
                error: false
            }

        case "QUOTE_CONVERT_FAILED":
            return {
                ...state,
                loading: false,
                covertQuote: null,
                error: action.payload
            }

        case "QUOTE_CONVERT_RESET":
            return {
                ...state,
                covertQuote: null,
            };

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}


// CUSTOMER QUOTES DELETED

export const customerQuotesDeleteReducer = (state = [], action: any) => {
    switch (action.type) {
        case "CUSTOMER_QUOTES_DELETE_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "CUSTOMER_QUOTES_DELETE_SUCCESS":
            return {
                ...state,
                loading: false,
                deleteCustomerQuote: action.payload,
            };
        case "CUSTOMER_QUOTES_DELETE_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case "CUSTOMER_QUOTES_DELETE_RESET":
            return {
                ...state,
                deleteCustomerQuote: {}
            }

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};