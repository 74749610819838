export const digitizerDashboardCountReducer = (state = [], action: any) => {
    switch (action.type) {
        case "DIGITIZERS_DASHBOARD_COUNT_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "DIGITIZERS_DASHBOARD_COUNT_SUCCESS":
            return {
                ...state,
                loading: false,
                digitizerDashboardCounts: action.payload,
            };
        case "DIGITIZERS_DASHBOARD_COUNT_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};

export const digitizerAllOrdersListReducer = (state = [], action: any) => {
    switch (action.type) {
        case "ALL_DIGITIZER_ORDERS_LIST_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "ALL_DIGITIZER_ORDERS_LIST_SUCCESS":
            return {
                ...state,
                loading: false,
                digitizersAllOrder: action.payload,
                digitizerOrderCount: action.payload.data.count
            };
        case "ALL_DIGITIZER_ORDERS_LIST_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
                digitizerOrderCount: 0
            };
        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};

export const digitizerAttachmentReducer = (state = {}, action: any) => {
    switch (action.type) {
        case "DIGITIZER_ATTACHMENT_REQUEST":
            return {
                ...state,
                loading: true,
            };

        case "DIGITIZER_ATTACHMENT_SUCCESS":
            return {
                ...state,
                loading: false,
                attachments: action.payload,
            };
        case "DIGITIZER_ATTACHMENT_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "DIGITIZER_ATTACHMENT_RESET":
            return {
                ...state,
                attachments: null,
            };
        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}

