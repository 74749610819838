import React, { useEffect, useState } from "react";
import { Container, Col, Row, Form, Spinner } from "react-bootstrap";
import Input from "../../../../Components/Input/Input";
import Button from "../../../../Components/Button/Button";
import { ISendVectorOrder } from "../../../../Utils/interfaces";
import { useForm } from "react-hook-form";
import { VectorOrderValidation } from "../../../../Utils/validation";
import { AnyAction } from "redux";
import { useNavigate } from "react-router-dom";
import { errorNotify, successNotify } from "../../../../Utils/toast";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { userSendVectorOrder } from "../../../../Redux/Actions/UserVectorOrder";
import VectorIcon from "../../../../Assets/images/vector.png";
import { IoMdAdd } from 'react-icons/io';
import './VectorOrder.css'

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const VectorOrder = () => {
  const navigate = useNavigate()
  const { register, handleSubmit, reset, formState: { errors } } = useForm<ISendVectorOrder>({ defaultValues: { urgent: 0 } });
  const { error, loading, vectorCreated } = useSelector((state: any) => state.sendVectorOrder);
  const dispatch: AppDispatch = useDispatch();
  const [fileSelected, setFileSelected] = React.useState<any>(null); // also tried <string | Blob>
  const [fileSelected2, setFileSelected2] = React.useState<any>(null);

  // Dynamically Add Inputs
  const [inputValues, setInputValues] = useState<any>([]);
  const [counter, setCounter] = useState(0);
  const [btnCounter,setBtnCounter] = useState(2)

  const handleClick = () => {
    if(inputValues?.length >= btnCounter){
      setBtnCounter(btnCounter + 1)
    }
    if(inputValues?.length === btnCounter){
      setCounter(counter + 1);
    }  
  };

  const handleOnChange = (e: any) => {
    const fileList = e.target.files[0]
    setInputValues([...inputValues, fileList]);
  };

  const handleImageChange = (name: any) => (e: any) => {
    const fileList = e.target.files;

    if (!fileList) return;

    if (name === "file1") {
      setInputValues([...inputValues, fileList[0]]);
    }

    if (name === "file2") {
      setInputValues([...inputValues, fileList[0]]);
    }
  };
  useEffect(() => {
    if (vectorCreated?.message) {
      successNotify(vectorCreated?.message);
      reset();
      navigate("/user/all-vector-record")
      setFileSelected(null);
      setFileSelected2(null);
      dispatch({ type: "SEND_VECTOR_ORDER_RESET" });
    }
    if (error) {
      errorNotify(error);
      dispatch({ type: "CLEAR_ERRORS" });
    }
  }, [vectorCreated, error]);

  const onSubmitHandler = handleSubmit(async (data) => {
    // if (inputValues?.length <= 0) {
    //   errorNotify("Please select atleast 1 file!")
    // }
    // else {
      const fileData: any = new FormData();
      [...inputValues].forEach(image => {
        fileData.append("attachments[]", image);
      });
      fileData.append("vector_name", data.vector_name);
      fileData.append("format_id", data.format_id);
      fileData.append("no_color", data.no_color);
      fileData.append("additional_instruction", data.additional_instruction);
      fileData.append("urgent", data.urgent);
      
      dispatch(userSendVectorOrder(fileData));
    // }

    
  });

  return (
    <div className="page_responsive">
      <Container>
        <div className="card d-flex">
          <div className="send_quotes_head_container" id="vector_order_div">
            <div className='send_vector_order_div_shorter'>
              <img src={VectorIcon} alt="pencil-logo" />
            </div>
            <h4>Vector Order</h4>
          </div>
        </div>
      </Container>
      <div className="create_emp_bg_background">
        <Container>
          <Form onSubmit={onSubmitHandler}>
            <Row>
              <Col md={12}>
                <div className="personal_details_container">
                  <div className="details_head">
                    <h5>Order</h5>
                  </div>

                  <div className="details_container">
                    <Row>
                      <Col md={12}>
                        <Input>
                          <Form.Label>Vector Name:</Form.Label>
                          <Form.Control
                            type="text"
                            {...register(
                              "vector_name",
                              VectorOrderValidation.vector_name
                            )}
                            placeholder="Enter Vector Name"
                          />
                        </Input>
                        {errors.vector_name ? (
                          <small className="text-danger">
                            {errors.vector_name && errors.vector_name.message}
                          </small>
                        ) : null}
                      </Col>

                      <Col md={6}>
                        <Input>
                          <Form.Label>Required Format</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            {...register(
                              "format_id",
                              VectorOrderValidation.format_id
                            )}
                          >
                            <option defaultValue={-1} key={-1}>
                              Choose Format
                            </option>
                            <option value={1}>cdr</option>
                            <option value={2}>ai</option>
                            <option value={3}>eps</option>
                            <option value={4}>Others</option>
                          </Form.Select>
                        </Input>
                        {errors.format_id ? (
                          <small className="text-danger">
                            {errors.format_id && errors.format_id.message}
                          </small>
                        ) : null}
                      </Col>

                      <Col md={6}>
                        <Input>
                          <Form.Label>Number of Color</Form.Label>
                          <Form.Control
                            type="number"
                            onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                            {...register(
                              "no_color",
                            )}
                            placeholder="Enter No of colors"
                          />
                        </Input>
                      </Col>

                      <Col md={12}>
                        <Input>
                          <Form.Label>Additional Instruction</Form.Label>
                          <Form.Control
                            type="text"
                            {...register(
                              "additional_instruction",
                            )}
                            placeholder="Enter additional instructions"
                          />
                        </Input>
                      </Col>

                      <Col md={12}>
                        <Form.Label>Super Urgent</Form.Label>
                        <div key={`inline-radio-1`} className="mb-3">
                          <Form.Check
                            inline
                            className="mx-2"
                            value={1}
                            type={"checkbox"}
                            id={`inline-radio-1`}
                            {...register("urgent")}
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <Input>
                          <Form.Label>
                            File 1: File Not More than 15mb
                          </Form.Label>
                          <Form.Control
                            type="file"
                            placeholder="Enter width"
                            onChange={handleImageChange("file1")}
                          />
                        </Input>
                      </Col>

                      <Col md={6}>
                        <Input>
                          <Form.Label>
                            File 2: File Not More than 15mb
                          </Form.Label>
                          <Form.Control
                            type="file"
                            placeholder="Enter width"
                            onChange={handleImageChange("file2")}
                          />
                        </Input>
                      </Col>

                      {Array.from(Array(counter)).map((c, index: any) => {
                        return (
                          <Col md={6} key={index}>
                            <Input>
                              <Form.Label>
                                File {index + 3}: File Not More than 15mb
                              </Form.Label>
                              <Form.Control
                                type="file"
                                onChange={handleOnChange}
                                key={c}
                                className={index}
                                placeholder="Enter width"
                              />
                            </Input>
                          </Col>
                        );
                      })}

                      <div className="add_more_fields">
                        <button type="button" onClick={handleClick}><IoMdAdd /> Add more fields</button>
                      </div>
                    </Row>
                    <Col md={12}>
                      <div className="d-flex justify-content-end py-3">
                        <Button type="submit">
                          {loading ? (
                            <Spinner animation="border" size="sm" />
                          ) : (
                            "Send Order"
                          )}
                        </Button>
                      </div>
                    </Col>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </div>
  );
};

export default VectorOrder;
