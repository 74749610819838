import api from "../../Utils/interceptors";
import axios from "axios";

export const Login = (inputData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "LOGIN_REQUEST",
    });

    const { data } = await axios.post("login", inputData);

    dispatch({
      type: "LOGIN_SUCCESS",
      payload: data,
      success: true,
    });

    localStorage.setItem("user", JSON.stringify(data?.data));
  } catch (e: any) {
    dispatch({
      type: "LOGIN_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

export const RegisterCustomer = (registerData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "REGISTER_REQUEST",
    });

    const { data } = await axios.post("register", registerData);
    
    dispatch({
      type: "REGISTER_SUCCESS",
      payload: data,
      success: true,
    });
  } catch (e: any) {
    dispatch({
      type: "REGISTER_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};


export const changePassword = (passwordData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "CHANGE_PASSWORD_REQUEST",
    });
    const { data } = await api.put(`user/change_password`, passwordData);
    dispatch({
      type: "CHANGE_PASSWORD_SUCCESS",
      payload: data,
      success: true,
    });
  } catch (e: any) {
    dispatch({
      type: "CHANGE_PASSWORD_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};
