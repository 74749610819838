import React from 'react'

interface IButton {
    children: any;
    type?: 'submit' | 'reset' | 'button' | undefined;
    onClick?: () => void
}

const Button:React.FC<IButton> = (props) => {
    return (
        <button className='Button_style' type={props.type} onClick={props.onClick}>
            {props.children}
        </button>
    )
}

export default Button