import React, { useEffect } from "react";
import { Card, Col, Row, Container } from "react-bootstrap";
import OrderIcon from "../../../../Assets/images/allorders-updated.png";
import dashbordIcon from '../../../../Assets/images/dashboard-icon.png';
import InvoicesIcon from "../../../../Assets/images/send quotes.png";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import "./Dashboard.css";
import AllQuotes from '../QuotesRecords/SalesAllQuotes';
import AllOrders from '../DigitizingRecords/DigitizingRecords';
import VectorRecords from "../VectorRecords/VectorRecords";
import { getSalesDashboardData } from "../../../../Redux/Actions/Sales";
import Loader from "../../../../Utils/Loader";
import moment from "moment";

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const Dashboard = () => {
  const { loading, salesDashboard, error } = useSelector((state: any) => state?.salesDashboardCount)
  const dispatch: AppDispatch = useDispatch();
  const date = new Date()

  useEffect(() => {
    dispatch(getSalesDashboardData())
  }, [])

  return (
    <div className="page_responsive">
      <Container fluid style={{ maxWidth: "1500px" }}>
        <div className="dashboard_div_main card">
          <div className="dashboard_container">
            <img src={dashbordIcon} alt='dashboaord' />
            <h4>Dashboard</h4>
            <div className="w-100 text-end">
              <h4> {moment(`${new Date(date)}`).utc().format('YYYY-MM-DD hh:mm A')}</h4>
            </div>
          </div>
        </div>
        <Row className="justify-content-around my-2">
          <Col lg={5}>
            <Card
              id="card1"
              className="details-card"
            >
              {
                loading ? <Loader />
                  :
                  <Card.Body>
                    <Row className="align-items-center">
                      <Col md={8}>
                        <div className="all_icon_div_text">
                          <h5>All Orders:</h5>

                          <div className="d-flex">
                            <div className="left_total">
                              <div className="circle_main">
                                <div className="circle_inner"></div>
                                <p>Total Orders</p>
                              </div>
                              <h6>{salesDashboard?.data?.orders?.total}</h6>
                            </div>
                            <div>
                              <div className="circle_main">
                                <div className="circle_inner"></div>
                                <p>Today's Orders</p>
                              </div>
                              <h6>{salesDashboard?.data?.orders?.today}</h6>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={4} className={'d-flex justify-content-end'}>
                        <div className="all_order_icon_div">
                          <img src={OrderIcon} alt="" />
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
              }

            </Card>
          </Col>

          <Col lg={5}>
            <Card
              id="card2"
              className="details-card"
            >
              {
                loading ? <Loader />
                  :
                  <Card.Body>
                    <Row className="align-items-center">
                      <Col md={8}>
                        <div className="all_icon_div_text">
                          <h5>All Sales:</h5>

                          <div className="d-flex">
                            <div className="left_total">
                              <div className="circle_main">
                                <div className="circle_inner"></div>
                                <p>Total Sales:</p>
                              </div>
                              <h6>{salesDashboard?.data?.orders?.sales}</h6>
                            </div>
                            <div>
                              <div className="circle_main">
                                <div className="circle_inner"></div>
                                <p>Commision:</p>
                              </div>
                              <h6>{salesDashboard?.data?.orders?.commision}</h6>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={4} className={'d-flex justify-content-end'}>
                        <div className="all_sales_icon_div">
                          <img src={InvoicesIcon} alt="" />
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
              }
            </Card>
          </Col>
        </Row>


        <Row>
          <Col md={12} className="my-3">
            <AllQuotes style='none' today={1} />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="my-3">
            <AllOrders style='none' today={1} />
          </Col>
        </Row>

        <Row>
          <Col md={12} className="my-3">
            <VectorRecords style='none' today={1} />
          </Col>
        </Row>

      </Container>
    </div>
  );
};

export default Dashboard;
