import React, { useEffect } from 'react'
import { Container, Col, Row, Form, Spinner } from "react-bootstrap";
import Input from "../../../../../../Components/Input/Input";
import Button from "../../../../../../Components/Button/Button";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch, useSelector } from "react-redux";
import { successNotify, errorNotify } from '../../../../../../Utils/toast';
import { useNavigate, useParams } from 'react-router-dom';
import { vectorOrderUploadAttachments } from '../../../../../../Redux/Actions/admin';
import { IoMdAdd } from 'react-icons/io';

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const UploadAttachment = () => {
    const navigate = useNavigate()
    const { error, loading, vectorOrder } = useSelector((state: any) => state.vectorOrderAttachment);
    const dispatch: AppDispatch = useDispatch();

    // Dynamically Add Inputs
    const [inputValues, setInputValues] = React.useState<any>([]);
    const [counter, setCounter] = React.useState(0);
    const [btnCounter, setBtnCounter] = React.useState(6);
    const { id } = useParams();

    useEffect(() => {
        if (vectorOrder?.message) {
            successNotify("Order Uploaded Successfully");
            navigate("/designer/orders")
            setInputValues([])
            dispatch({ type: "VECTOR_ORDER_ATTACHMENT_RESET" });
        }
        if (error) {
            errorNotify(error);
            dispatch({ type: "CLEAR_ERRORS" });
        }
    }, [error, vectorOrder])// eslint-disable-line react-hooks/exhaustive-deps

    const handleClick = () => {
        if (inputValues?.length >= btnCounter) {
            setBtnCounter(btnCounter + 1)
        }
        if (inputValues?.length === btnCounter) {
            setCounter(counter + 1);
        }
    };

    const handleOnChange = (e: any) => {
        const fileList = e.target.files[0]
        setInputValues([...inputValues, fileList]);
    };

    const handleImageChange = (name: any) => (e: any) => {
        const fileList = e.target.files;
        if (!fileList) return;

        if (name === "file1") {
            setInputValues([...inputValues, fileList[0]]);
        }
        else if (name === "file2") {
            setInputValues([...inputValues, fileList[0]]);
        }
        else if (name === "file3") {
            setInputValues([...inputValues, fileList[0]]);
        }
        else if (name === "file4") {
            setInputValues([...inputValues, fileList[0]]);
        }
        else if (name === "file5") {
            setInputValues([...inputValues, fileList[0]]);
        }
        else if (name === "file6") {
            setInputValues([...inputValues, fileList[0]]);
        }
    };

    const onSubmitHandler = (e: any) => {
        e.preventDefault()
        if (inputValues?.length < 6) {
            errorNotify("Please select atleast 6 files!")
        }

        else {
            const fileData: any = new FormData();
            [...inputValues].forEach(image => {
                fileData.append("attachments[]", image);
              });
            dispatch(vectorOrderUploadAttachments(id, fileData))
        }
    }

    return (
        <div className='page_responsive'>
            <div className="create_emp_bg_background">
                <Container>
                    <Form onSubmit={onSubmitHandler}>
                        <Row>
                            <Col md={12}>
                                <div className="personal_details_container">
                                    <div className="details_head">
                                        <h5>Upload Attachment</h5>
                                    </div>

                                    <div className="details_container">
                                        <Row>
                                            <Col md={6}>
                                                <Input>
                                                    <Form.Label>File1:</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        onChange={handleImageChange("file1")}
                                                    />
                                                </Input>
                                            </Col>

                                            <Col md={6}>
                                                <Input>
                                                    <Form.Label>File2:</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        onChange={handleImageChange("file2")}
                                                    />
                                                </Input>
                                            </Col>

                                            <Col md={6}>
                                                <Input>
                                                    <Form.Label>File3:</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        onChange={handleImageChange("file3")}
                                                    />
                                                </Input>
                                            </Col>

                                            <Col md={6}>
                                                <Input>
                                                    <Form.Label>File4:</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        onChange={handleImageChange("file4")}
                                                    />
                                                </Input>
                                            </Col>

                                            <Col md={6}>
                                                <Input>
                                                    <Form.Label>File5:</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        onChange={handleImageChange("file5")}
                                                    />
                                                </Input>
                                            </Col>

                                            <Col md={6}>
                                                <Input>
                                                    <Form.Label>File6:</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        onChange={handleImageChange("file6")}
                                                    />
                                                </Input>
                                            </Col>

                                            {Array.from(Array(counter)).map((c, index: any) => {
                                                return (
                                                    <Col md={6} key={index}>
                                                        <Input>
                                                            <Form.Label>
                                                                File {index + 7}:
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="file"
                                                                onChange={handleOnChange}
                                                                key={c}
                                                                className={index}
                                                                placeholder="Enter width"
                                                            />
                                                        </Input>
                                                    </Col>
                                                );
                                            })}

                                            <div className="add_more_fields">
                                                <button className='mt-1 mb-2' type="button" onClick={handleClick}><IoMdAdd /> Add more fields</button>
                                            </div>
                                        </Row>
                                        <Col md={12}>
                                            <div className="d-flex justify-content-end py-3">
                                                <Button type="submit">
                                                    {
                                                        loading ? <Spinner animation='border' size='sm' /> : "Upload"
                                                    }
                                                </Button>
                                            </div>
                                        </Col>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>
        </div>
    )
}

export default UploadAttachment
