import React from 'react';
import { Form } from "react-bootstrap";
import './Input.css';

export interface IInput {
    children: any;
}

const Input: React.FC<IInput> = (props) => {
    return (
        <Form.Group className={'form_group'}>
            {props.children}
        </Form.Group>
    );
};
export default Input;