import React from "react";
import DigitizerNavbar from "../../Container/Digitizer/Routes/Navbar";
import { Outlet } from "react-router-dom";
import NotFound from "../../Container/Pages/NotFound/NotFound";

const DigitizerLayout = () => {
    let userAuth: any = localStorage.getItem("user")? JSON.parse(localStorage.getItem("user") || ""): null;

    return userAuth?.role?.name === "Digitizer" ? (
        <React.Fragment>
            <DigitizerNavbar />
            <Outlet />
        </React.Fragment>
    ) : (
        <NotFound />
    );
}
export default DigitizerLayout;
