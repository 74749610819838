import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import MuiDataTable from "../../../../Components/MUIDataTable/MUIDataTable";
import { getAdminVectorsRecords, deleteAdminVectorsRecords, } from "../../../../Redux/Actions/admin";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import Loader from "../../../../Utils/Loader";
import moment from "moment";
import { successNotify } from "../../../../Utils/toast";
import Modal from "../../../../Components/SiteModal/SiteModal";
import VectorIcon from "../../../../Assets/images/vector.png";
import deleteIcon from '../../../../Assets/images/delete-icon.png';
import eyeIcon from '../../../../Assets/images/eye.png';

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const AllVectorOrders: React.FC<any> = ({ style, today = '' }) => {
  const { loading, vectors, vectorsCount } = useSelector((state: any) => state.adminAllVectors);
  const { deleteVectors, loading: deleteLoading } = useSelector((state: any) => state.adminDeleteVectors);
  const dispatch: AppDispatch = useDispatch();
  const navigation = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [text, setText] = useState("");
  const [show, setShow] = useState(false);
  const [vectorID, setVectorID] = useState("");

  useEffect(() => {
    dispatch(getAdminVectorsRecords(page, rowsPerPage, text, today));
  }, [page, rowsPerPage, text]);

  useEffect(() => {
    if (deleteVectors && deleteVectors.status === 1) {
      successNotify("Vector Deleted Successfully");
      setShow(false);
      dispatch({ type: "ALL_VECTORS_DELETE_RESET" });
      dispatch(getAdminVectorsRecords(page, rowsPerPage, text, today));
    }
  }, [deleteVectors]);

  const VectorIdHandler = (id: string) => {
    setVectorID(id);
    setShow(!show);
  };

  const modal = (
    <Modal title="Delete" size={"lg"} show={show} onCloseModal={() => setShow(!show)}>
      <React.Fragment>
        <p>Are u sure you want to delete this vector order?</p>
        <div className="modal_decision">
          <button className="my-1 details_btn bg-red" onClick={() => dispatch(deleteAdminVectorsRecords(vectorID))}>
            {deleteLoading ? <Spinner animation="border" size="sm" /> : "Yes"}
          </button>
          <button className="my-1 details_btn sent_order" onClick={() => setShow(false)}>
            No
          </button>
        </div>
      </React.Fragment>
    </Modal>
  );

  const vectorOrdersColumns = [
    { name: "sno", label: "S:NO" },
    { name: "vector_no", label: "ORDER NO" },
    { name: "vector_name", label: "ORDER NAME" },
    {
      name: "Customer",
      label: "CUSTOMER NAME",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return value.username;
        },
      },
    },
    {
      name: "designer_status",
      label: "DESIGNER STATUS",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <React.Fragment>
              {parseInt(value) === 0 ? (
                <div className="status-pending">
                  <p className="mb-0">pending</p>
                </div>
              ) : parseInt(value) === 1 ? (
                <div className="status_completed">
                  <p className="mb-0">completed</p>

                </div>
              ) : null
              }
            </React.Fragment>
          );
        },
      },
    },
    {
      name: "send_date",
      label: "ORDER DATE",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return moment(new Date(`${value} UTC`)).format("DD-MM-YYYY hh:mm:ss");
        },
      },
    },
    {
      name: "price",
      label: "PRICE",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <span>
              {tableMeta.rowData[3]?.Country?.currency_symbol}
              {value}
            </span>
          );
        },
      },
    },
    {
      name: "status",
      label: "ORDER STATUS",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <React.Fragment>
              {parseInt(value) === 0 ? (
                <div className="status-pending">
                  <p className="mb-0">pending</p>
                </div>
              ) : parseInt(value) === 1 ? (
                <div className="status-inProcess">
                  <p className="mb-0">In process</p>

                </div>
              ) : <div className="status_completed">
                <p className="mb-0">Completed</p>
              </div>}
            </React.Fragment>
          );
        },
      },
    },
    {
      name: "Order Details",
      label: "DETAILS",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <div className="action_container">
              <Link to={`/admin/vector-order-details/${tableMeta.rowData[1]}`}>
                <button
                  className="view-btn"
                  type="button"
                >
                  <img src={eyeIcon} alt="eye" />
                </button>
              </Link>
              <button
                className="delete-btn"
                type="button"
                onClick={() => VectorIdHandler(tableMeta.rowData[1])}
              >
                <img src={deleteIcon} alt="delete" />
              </button>
            </div>
          );
        },
      },
    },
  ];

  let newData: any = [];
  vectors?.data?.rows?.map((item: any, index: any) => {
    newData.push({ sno: rowsPerPage * page + index + 1, ...item });
  });

  return (
    <div className={`${style ? style : "page_responsive"}`}>
      <div className="card">
        <div className="send_quotes_head_container" id="vector_order_div">
          <div className='send_vector_order_div_shorter'>
            <img src={VectorIcon} alt="pencil-logo" />
          </div>
          <h4>{today !== "" ? "Today's All Vector Orders" : "All Vector Orders"}</h4>
        </div>
      </div>
      <Row>
        <Col md={12} className="p-2 my-3 vector-order-table">
          {!text && loading ? (
            <Loader />
          ) : (
            <MuiDataTable
              title={today !== "" ? "Today's All Vector Orders" : "All Vector Orders"}
              data={newData}
              columns={vectorOrdersColumns}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              count={vectorsCount}
              setText={setText}
              text={text}
            />
          )}
        </Col>
      </Row>
      {modal}
    </div>
  );
};

export default AllVectorOrders;
