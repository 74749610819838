import React, { useEffect, useState } from "react";
import { Container, Col, Row, Form, Spinner } from "react-bootstrap";
import Input from "../../../../Components/Input/Input";
import Button from "../../../../Components/Button/Button";
import { ISendOrder } from "../../../../Utils/interfaces";
import { useForm } from "react-hook-form";
import { sendOrder } from "../../../../Utils/validation";
import { AnyAction } from "redux";
import { useNavigate } from "react-router-dom";
import { errorNotify, successNotify } from "../../../../Utils/toast";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { userSendOrder } from "../../../../Redux/Actions/UserOrders";
import OrderIcon from "../../../../Assets/images/orders.png";
import { IoMdAdd } from "react-icons/io";

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const DigitizingOrder = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<ISendOrder>({ defaultValues: { urgent: 0 } });
  const { error, loading, orderCreated } = useSelector(
    (state: any) => state.sendOrder
  );
  const dispatch: AppDispatch = useDispatch();
  const [fileSelected, setFileSelected] = React.useState<any>(null); // also tried <string | Blob>
  const [fileSelected2, setFileSelected2] = React.useState<any>(null);
  setValue("isNew", 1);
  // Dynamically Add Inputs
  const [inputValues, setInputValues] = React.useState<any>([]);
  const [counter, setCounter] = React.useState(0);
  const [btnCounter, setBtnCounter] = React.useState(2);

  const handleClick = () => {
    if (inputValues?.length >= btnCounter) {
      setBtnCounter(btnCounter + 1);
    }
    if (inputValues?.length === btnCounter) {
      setCounter(counter + 1);
    }
  };

  const handleOnChange = (e: any) => {
    const fileList = e.target.files[0];
    setInputValues([...inputValues, fileList]);
  };

  const handleImageChange = (name: any) => (e: any) => {
    const fileList = e.target.files;

    if (!fileList) return;

    if (name === "file1") {
      setInputValues([...inputValues, fileList[0]]);
    }

    if (name === "file2") {
      setInputValues([...inputValues, fileList[0]]);
    }
  };

  useEffect(() => {
    if (orderCreated?.status === 1) {
      successNotify(orderCreated?.message);
      reset();
      navigate("/user/all-orders");
      setFileSelected(null);
      setFileSelected2(null);
      dispatch({ type: "SEND_DIGITIZING_ORDER_RESET" });
    }
    if (error) {
      errorNotify(error);
      dispatch({ type: "CLEAR_ERRORS" });
    }
  }, [orderCreated, error]);

  const onSubmitHandler = handleSubmit(async (data) => {
    // if (inputValues?.length <= 0) {
    //   errorNotify("Please select atleast 1 file!")
    // }
    // else {
    const fileData: any = new FormData();
    inputValues?.length > 0 &&
      [...inputValues].forEach((image) => {
        fileData.append("attachments[]", image);
      });
    fileData.append("order_name", data.order_name);
    fileData.append("format_id", data.format_id);
    fileData.append("no_color", data.no_color);
    fileData.append("additional_instruction", data.additional_instruction);
    fileData.append("fabric_id", data.fabric_id);
    fileData.append("placement_id", data.placement_id);
    fileData.append("width", data.width);
    fileData.append("height", data.height);
    fileData.append("urgent", data.urgent);
    fileData.append("isNew", data.isNew);

    dispatch(userSendOrder(fileData));
    // }
  });

  return (
    <div className="page_responsive">
      <Container>
        <div className="card d-flex">
          <div className="send_quotes_head_container" id="digitizer_order">
            <div className="send_digitizing_order_div_shorter">
              <img src={OrderIcon} alt="pencil-logo" />
            </div>
            <h4>Digitizing Order</h4>
          </div>
        </div>
      </Container>
      <div className="create_emp_bg_background">
        <Container>
          <Form onSubmit={onSubmitHandler}>
            <Row>
              <Col md={12}>
                <div className="personal_details_container">
                  <div className="details_head">
                    <h5>Order</h5>
                  </div>

                  <div className="details_container">
                    <Row>
                      <Col md={12}>
                        <Input>
                          <Form.Label>Order Name:</Form.Label>
                          <Form.Control
                            type="text"
                            {...register("order_name", sendOrder.order_name)}
                            placeholder="Enter Your Design Name"
                          />
                        </Input>
                        {errors.order_name ? (
                          <small className="text-danger">
                            {errors.order_name && errors.order_name.message}
                          </small>
                        ) : null}
                      </Col>

                      <Col md={4}>
                        <Input>
                          <Form.Label>Required Format</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            {...register("format_id", sendOrder.format_id)}
                          >
                            <option defaultValue={-1} key={-1}>
                              Please select required format
                            </option>
                            <option value={5}>100</option>
                            <option value={6}>cnd</option>
                            <option value={7}>dsb</option>
                            <option value={8}>dst</option>
                            <option value={9}>dsz</option>
                            <option value={10}>emb</option>
                            <option value={11}>exp</option>
                            <option value={12}>jef</option>
                            <option value={13}>ksm</option>
                            <option value={14}>pes</option>
                            <option value={15}>pof</option>
                            <option value={16}>tap</option>
                            <option value={17}>xxx</option>
                            <option value={18}>ofm</option>
                            <option value={19}>pxf</option>
                            <option value={20}>HUS</option>
                            <option value={21}>Others</option>
                          </Form.Select>
                        </Input>
                        {errors.format_id ? (
                          <small className="text-danger">
                            {errors.format_id && errors.format_id.message}
                          </small>
                        ) : null}
                      </Col>

                      <Col md={4}>
                        <Input>
                          <Form.Label>Height</Form.Label>
                          <Form.Control
                            type="text"
                            {...register("height", sendOrder.height)}
                            placeholder="Enter height"
                          />
                        </Input>
                        {errors.height ? (
                          <small className="text-danger">
                            {errors.height && errors.height.message}
                          </small>
                        ) : null}
                      </Col>

                      <Col md={4}>
                        <Input>
                          <Form.Label>Width</Form.Label>
                          <Form.Control
                            type="text"
                            {...register("width", sendOrder.width)}
                            placeholder="Enter width"
                          />
                        </Input>
                        {errors.width ? (
                          <small className="text-danger">
                            {errors.width && errors.width.message}
                          </small>
                        ) : null}
                      </Col>

                      <Col md={4}>
                        <Input>
                          <Form.Label>Fabric</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            {...register("fabric_id", sendOrder.fabric_id)}
                          >
                            <option defaultValue={-1} key={-1}>
                              Choose Fabric
                            </option>
                            <option value={25}>Others</option>
                            <option value={24}>Moisture-wicking Pol</option>
                            <option value={23}>Dri-wicking Polo</option>
                            <option value={22}>Cotton Polo</option>
                            <option value={21}>Polyester Polo</option>
                            <option value={20}>Quilt</option>
                            <option value={19}>Blanket</option>
                            <option value={18}>Milliskin</option>
                            <option value={17}>Chenille</option>
                            <option value={16}>Velvet</option>
                            <option value={15}>Wool</option>
                            <option value={14}>Nylon</option>
                            <option value={13}>Canvas</option>
                            <option value={12}>Felt</option>
                            <option value={11}>Leather</option>
                            <option value={10}>Towel</option>
                            <option value={9}>Fleece</option>
                            <option value={8}>Flannel</option>
                            <option value={7}>Twill</option>
                            <option value={6}>Polyester</option>
                            <option value={5}>Silk</option>
                            <option value={4}>Denim</option>
                            <option value={3}>Cotton Woven</option>
                            <option value={2}>Single Jersey</option>
                            <option value={1}>Pique</option>
                          </Form.Select>
                        </Input>
                        {errors.fabric_id ? (
                          <small className="text-danger">
                            {errors.fabric_id && errors.fabric_id.message}
                          </small>
                        ) : null}
                      </Col>

                      <Col md={4}>
                        <Input>
                          <Form.Label>Placement</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            {...register(
                              "placement_id",
                              sendOrder.placement_id
                            )}
                          >
                            <option defaultValue={-1} key={-1}>
                              Choose Placement
                            </option>
                            <option value={23}>others</option>
                            <option value={22}>Beanies</option>
                            <option value={21}>Quilt</option>
                            <option value={20}>Blanket</option>
                            <option value={19}>Seat Cover</option>
                            <option value={18}>Bags</option>
                            <option value={17}>Patches</option>
                            <option value={16}>Towel</option>
                            <option value={15}>Sleeve</option>
                            <option value={14}>Jacket Back</option>
                            <option value={13}>Wrist Band</option>
                            <option value={12}>Gloves</option>
                            <option value={11}>Pocket </option>
                            <option value={10}>Center Chest</option>
                            <option value={9}>Left Chest</option>
                            <option value={8}>L.Chest/Cap</option>
                            <option value={7}>Cap Back</option>
                            <option value={6}>Cap Side</option>
                            <option value={5}>Cap/Visor</option>
                            <option value={4}>Cap</option>
                            <option value={3}>Visor</option>
                            <option value={2}>Applique</option>
                            <option value={1}>Apron</option>
                          </Form.Select>
                        </Input>
                        {errors.placement_id ? (
                          <small className="text-danger">
                            {errors.placement_id && errors.placement_id.message}
                          </small>
                        ) : null}
                      </Col>

                      <Col md={4}>
                        <Input>
                          <Form.Label>Number of Color</Form.Label>
                          <Form.Control
                            type="number"
                            {...register("no_color")}
                            placeholder="Enter No of colors"
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                          />
                        </Input>
                      </Col>

                      <Col md={12}>
                        <Input>
                          <Form.Label>Additional Instruction</Form.Label>
                          <Form.Control
                            type="text"
                            {...register(
                              "additional_instruction",
                            )}
                            placeholder="Enter additional instructions"
                          />
                        </Input>
                      </Col>

                      <Col md={12}>
                        <Form.Label>Super Urgent</Form.Label>
                        <div key={`inline-radio-1`} className="mb-3">
                          <Form.Check
                            inline
                            className="mx-2"
                            value={1}
                            type={"checkbox"}
                            id={`inline-radio-1`}
                            {...register("urgent")}
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <Input>
                          <Form.Label>
                            File 1: File Not More than 15mb
                          </Form.Label>
                          <Form.Control
                            type="file"
                            placeholder="Enter width"
                            onChange={handleImageChange("file1")}
                          />
                        </Input>
                      </Col>

                      <Col md={6}>
                        <Input>
                          <Form.Label>
                            File 2: File Not More than 15mb
                          </Form.Label>
                          <Form.Control
                            type="file"
                            placeholder="Enter width"
                            onChange={handleImageChange("file2")}
                          />
                        </Input>
                      </Col>

                      {Array.from(Array(counter)).map((c, index: any) => {
                        return (
                          <Col md={6} key={index}>
                            <Input>
                              <Form.Label>
                                File {index + 3}: File Not More than 15mb
                              </Form.Label>
                              <Form.Control
                                type="file"
                                onChange={handleOnChange}
                                key={c}
                                className={index}
                                placeholder="Enter width"
                              />
                            </Input>
                          </Col>
                        );
                      })}

                      <div className="add_more_fields">
                        <button type="button" onClick={handleClick}>
                          <IoMdAdd /> Add more fields
                        </button>
                      </div>
                    </Row>
                    <Col md={12}>
                      <div className="d-flex justify-content-end py-3">
                        <Button type="submit">
                          {loading ? (
                            <Spinner animation="border" size="sm" />
                          ) : (
                            "Send Order"
                          )}
                        </Button>
                      </div>
                    </Col>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </div>
  );
};

export default DigitizingOrder;
