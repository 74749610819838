import { IRoutesLink, ISideBarRoutes } from "../../../Utils/interfaces";


// ICONS
import dashboardIcon from '../../../Assets/images/dashboard-nav-icon.png';
import QuoteRecordsIcon from '../../../Assets/images/quotes-nav-icon.png';
import vectorRecordsIcon from '../../../Assets/images/all-vector-orders-nav-icon.png';
import myAccountIcon from '../../../Assets/images/my-account-nav-icon.png';
import allOrdersIcon from '../../../Assets/images/all-orders-nav-icon.png'
import LockICon from '../../../Assets/images/lock-nav-icon.png';
import plusIcon from '../../../Assets/images/plus.png';

// Pages
import Dashboard from "../Pages/Dashboard/Dashboard";
import OrderList from "../Pages/OrderList/OrderList";
import OrderDetails from "../Pages/OrderList/OrderDetails/OrderDetails";
import OrderUpload from "../Pages/OrderList/OrderUpload/OrderUpload";
import MyProfile from "../../Customer/Pages/MyProfile/MyProfile";
import ChangePassword from "../../Customer/Pages/MyProfile/ChangePassword/ChangePassword";


// Machine Operator Routes

export const operatorRoutes: IRoutesLink[] = [
  {
    path: "/machine-operator/dashboard",
    component: <Dashboard />,
  },
  {
    path: "/machine-operator/all-orders",
    component: <OrderList />,
  },
  {
    path: "/machine-operator/order-details/:id",
    component: <OrderDetails />,
  },
  {
    path: "/machine-operator/upload-attachments/:id",
    component: <OrderUpload />,
  },
  {
    path: "/machine-operator/profile",
    component: <MyProfile />,
  },
  {
    path: "/machine-operator/reset-password",
    component: <ChangePassword />,
  },

];

export const machineOperatorSideBarItems: ISideBarRoutes[] = [
  {
    path: "/machine-operator/dashboard",
    icon: <img src={dashboardIcon} alt="dashboard-icon" />,
    title: "Dashboard",
    isSubNav: false,
  },
  {
    path: "/machine-operator/all-orders",
    icon: <img src={allOrdersIcon} alt="dashboard-icon" />,
    title: "All Orders",
    isSubNav: false,
  },
  {
    path: "",
    icon: <img src={myAccountIcon} alt="account-icon" />,
    title: "My Account",
    isSubNav: true,
    subNav: [
      {
        path: "/machine-operator/profile",
        component: <MyProfile />,
        icon: <img src={myAccountIcon} alt="account-icon" />,
        title: "My Profile"
      },
      {
        path: "/machine-operator/reset-password",
        component: <ChangePassword />,
        icon: <img src={LockICon} alt="account-icon" />,
        title: "Change Password"
      },
    ]
  },

];
