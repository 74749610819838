import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { Link, useNavigate } from "react-router-dom";
import { AllUsersAction, deleteAllUsers } from '../../../../../Redux/Actions/admin';
import Loader from "../../../../../Utils/Loader";
import MuiDataTable from "../../../../../Components/MUIDataTable/MUIDataTable";
import { Col, Row, Spinner } from "react-bootstrap";
import deleteIcon from '../../../../../Assets/images/delete-icon.png';
import Modal from "../../../../../Components/SiteModal/SiteModal";
import editIcon from '../../../../../Assets/images/edit.png'
import { successNotify } from '../../../../../Utils/toast';

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const AllUsers = () => {
    const { users, loading, usersCount } = useSelector((state: any) => state.allUsers);
    const { deleteUsers, loading: deleteLoading } = useSelector((state: any) => state.allUsersDelete);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const dispatch: AppDispatch = useDispatch();
    const navigation = useNavigate()
    const [show, setShow] = useState(false);
    const [customerID, setCustomerID] = useState('');
    const [text, setText] = useState('');

    useEffect(() => {
        dispatch(AllUsersAction(page, rowsPerPage, text));
    }, [page, rowsPerPage, text, dispatch]);

    useEffect(() => {
        if (deleteUsers && deleteUsers?.status === 1) {
            successNotify("User Deleted Successfully");
            setShow(false);
            dispatch({ type: "DELETE_USER_RESET" });
            dispatch(AllUsersAction(page, rowsPerPage, text));
        }
    }, [deleteUsers]); // eslint-disable-line react-hooks/exhaustive-deps

    const orderIDHandler = (id: string) => {
        setCustomerID(id);
        setShow(!show);
    };


    const modal = (
        <Modal
            title="Delete"
            size={"lg"}
            show={show}
            onCloseModal={() => setShow(!show)}
        >
            <React.Fragment>
                <p>Are u sure you want to delete this User?</p>
                <div className="modal_decision">
                    <button
                        className="my-1 details_btn bg-red"
                        onClick={() => dispatch(deleteAllUsers(parseInt(customerID)))}
                    >
                        {deleteLoading ? <Spinner animation="border" size="sm" /> : "Yes"}
                    </button>
                    <button
                        className="my-1 details_btn sent_order"
                        onClick={() => setShow(false)}
                    >
                        No
                    </button>
                </div>
            </React.Fragment>
        </Modal>
    );

    const quotesColumns = [
        {
            name: "id",
            options: {
                display: false
            }

        },
        { name: "sno", label: "S:NO" },
        {
            name: "Role",
            label: "ROLE",
            options: {
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return value?.name;
                },
            },
        },

        { name: "first_name", label: "FIRST NAME" },
        { name: "last_name", label: "LAST NAME" },
        {
            name: "status", label: "STATUS",
            options: {
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    if (value === 1) {
                        return (
                            <div className="status_completed">
                                <p className="mb-0">Active</p>
                            </div>
                        )
                    }
                    else {
                        return (
                            <div className="status-pending">
                                <p className="mb-0">Pending</p>
                            </div>
                        )
                    }

                },
            },
        },
        {
            name: "ACTION",
            label: "ACTION",
            options: {
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (
                        <React.Fragment>
                            <div className="action_container">
                                <Link to={`/admin/edit-user/${tableMeta.rowData[0]}`}>
                                    <button
                                        className="view-btn"
                                        type="button">
                                            
                                        <img src={editIcon} width={20} height={20} alt='edit' />
                                    </button>
                                </Link>
                                <button
                                    className="delete-btn"
                                    type="button"
                                    onClick={() => orderIDHandler(tableMeta.rowData[0])}
                                >
                                    <img src={deleteIcon} alt="delete" />
                                </button>
                            </div>
                        </React.Fragment>
                    );
                },
            },
        },
    ];

    let newData: any = [];
    users?.data?.rows?.map((item: any, index: any) => {
        newData.push({ sno: rowsPerPage * page + index + 1, ...item });
    });



    return (
        <div className='page_responsive'>
            <Row>
                <Col md={12} className="p-2 my-3 all-users-table">
                    {!text && loading ? <Loader /> :
                        <MuiDataTable
                            title={"ALL USERS"}
                            data={newData}
                            columns={quotesColumns}
                            page={page}
                            setPage={setPage}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            count={usersCount}
                            setText={setText}
                            text={text}
                        />
                    }
                    {modal}
                </Col>
            </Row>
        </div>
    )
}

export default AllUsers
