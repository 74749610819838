import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from "react-bootstrap";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import MuiDataTable from "../../../../Components/MUIDataTable/MUIDataTable";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DigitizerAllOrderRecords } from '../../../../Redux/Actions/digitizer';
import moment from 'moment';
import Loader from '../../../../Utils/Loader';
import eyeIcon from '../../../../Assets/images/eye.png';
import OrderIcon from "../../../../Assets/images/orders.png";

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const AllOrder: React.FC<any> = ({ style, today = '' }) => {
  const [page, setPage] = useState(0);
  const [rowsperPage, setRowsPerpage] = useState(10);
  const dispatch: AppDispatch = useDispatch();
  const { loading, digitizersAllOrder, digitizerOrderCount } = useSelector((state: any) => state.digitizerOrdersList);
  const [text, setText] = useState<any>('')
  const navigation = useNavigate();

  useEffect(() => {
    dispatch(DigitizerAllOrderRecords(page, rowsperPage, text, today))
  }, [page, rowsperPage, text])

  const ordersColumns = [
    { name: 'sno', label: 'S:NO' },
    {
      name: 'order_id',
      label: 'ORDER ID',
      option: {
        display: false
      }
    },

    { name: 'order_name', label: "ORDER NAME" },
    {
      name: "createdAt",
      label: "ORDER DATE",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return moment(new Date(`${value} UTC`)).format("DD-MM-YYYY hh:mm:ss");
        },
      },
    },
    {
      name: "digitizer_order_status", label: "STATUS",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          if (parseInt(value) === 0) {
            return (
              <div className='status-pending'>
                New Order
              </div>
            );
          }
          else if (parseInt(value) === 1) {
            return (
              <div className='status_completed'>
                Completed
              </div>
            )
          }
        },
      },
    },
    {
      name: "ORDER DETAILS",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <div className="action_container">
              <Link to={`/digitizer/orders-details/${tableMeta.rowData[1]}`}>
                <button
                  className="view-btn"
                  type="button"
                >
                  <img src={eyeIcon} alt="eye" />
                </button>
              </Link>
            </div>
          );
        },
      },
    },
  ];

  let newData: any = [];
  digitizersAllOrder?.data?.rows?.map((item: any, index: any) => {
    newData.push({ sno: rowsperPage * page + index + 1, ...item });
  });

  return (
    <div className={`${style ? style : "page_responsive"}`}>
      <div className="card">
        <div className="send_quotes_head_container" id="digitizer_order">
          <div className='send_digitizing_order_div_shorter'>
            <img src={OrderIcon} alt="pencil-logo" />
          </div>
          <h4>{today !== "" ? "Today's All Orders" : "All Orders"}</h4>
        </div>
      </div>
      <Container fluid>
        {!text && loading ? <Loader /> :
          <Row>
            <Col md={12} className="p-2 my-3 digitizing-table">
              <MuiDataTable
                title={today !== "" ? "Today's All Orders" : "All Orders"}
                data={newData}
                columns={ordersColumns}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsperPage}
                setRowsPerPage={setRowsPerpage}
                count={digitizerOrderCount}
                setText={setText}
                text={text}
              />
            </Col>
          </Row>
        }
      </Container>
    </div>
  )
}

export default AllOrder
