export const userSendOrderReducer = (state = {}, action: any) => {
  switch (action.type) {
    case "SEND_DIGITIZING_ORDER_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "SEND_DIGITIZING_ORDER_SUCCESS":
      return {
        ...state,
        loading: false,
        orderCreated: action.payload,
      };
    case "SEND_DIGITIZING_ORDER_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "SEND_DIGITIZING_ORDER_RESET":
      return {
        ...state,
        orderCreated: null
      }
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};
export const userOrderListReducer = (state = [], action: any) => {
  switch (action.type) {
    case "DIGITIZING_ORDER_LIST_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "DIGITIZING_ORDER_LIST_SUCCESS":
      return {
        ...state,
        loading: false,
        orderList: action.payload,
        ordersCount: action.payload.data.count
      };
    case "DIGITIZING_ORDER_LIST_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
        ordersCount: 0
      };
    case "DIGITIZING_ORDER_LIST_RESET":
      return {
        ...state,
        orderList: null
      }
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};
export const userOrderDetailsReducer = (state = {}, action: any) => {
  switch (action.type) {
    case "DIGITIZING_ORDER_DETAIL_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "DIGITIZING_ORDER_DETAIL_SUCCESS":
      return {
        ...state,
        loading: false,
        orderDetails: action.payload,
      };
    case "DIGITIZING_ORDER_DETAIL_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "DIGITIZING_ORDER_DETAIL_RESET":
      return {
        ...state,
        orderDetails: null
      }
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};

// CUSTOMER ORDER DELETED

export const customerOrderDeleteReducer = (state = [], action: any) => {
  switch (action.type) {
    case "CUSTOMER_ORDER_DELETE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "CUSTOMER_ORDER_DELETE_SUCCESS":
      return {
        ...state,
        loading: false,
        deleteCustomerOrder: action.payload,
      };
    case "CUSTOMER_ORDER_DELETE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "CUSTOMER_ORDER_DELETE_RESET":
      return {
        ...state,
        deleteCustomerOrder: {}
      }

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};