import { combineReducers } from "redux";
import { AuthReducer, registerReducer, ChangePassReducer } from "./Auth";
import { userOrderDetailsReducer, userOrderListReducer, userSendOrderReducer, customerOrderDeleteReducer } from "./UserOrders";
import { userSendVectorOrderReducer, userVectorOrderDetailsReducer, userVectorOrderListReducer, customerVectorDeleteReducer } from "./userVectorOrders";
import { userSendQuotesReducer, getAllSendQuotesReducer, getIndivisualQuotesReducer, getUserProfileDataReducer, convertQuoteIntoOrderReducer, customerQuotesDeleteReducer } from "./UserQuotes";
import {
    adminOrderListReducer, updateUserDetailsReducer, adminQuotesListReducer, adminVectorListReducer, allUsersByRoleReducer, orderAssignedReducer, quoteApprovedReducer, getAllRolesListReducer,
    addUserReducer, AllUsersListReducer, getUserDetailsReducer, adminQuotesDeleteReducer, adminOrderDeleteReducer, adminVectorDeleteReducer, orderSentToCustomerReducer, generateInvoiceReducer, invoiceCreateReducer, AllInvoicesListReducer, getInvoicesDetailsReducer, adminDashboard, adminVectorSendReducer,
    userAssignedToSalesReducer, MoOrderSaveReducer, MoOrderListReducer, getMoOrderDetailsReducer, moOrderApproveReducer, allUsersDeleteReducer, vectorOrderAttachmentReducer, VectorOrderSentToCustomerReducer, VectorOrderAssignedReducer
} from "./admin";
import { digitizerAllOrdersListReducer, digitizerDashboardCountReducer, digitizerAttachmentReducer } from '../Reducers/digitizer';
import { CustomerInvoicesListReducer, CustomerInvoicesDetailsReducer } from './CustomerInvoices';
import { salesDashboard, salesOrderDetailsReducer, salesOrderListReducer, getSalesQuotesDetailReducer, getSalesQuotesListReducer, salesVectorOrderListReducer, salesVectorOrderDetailsReducer, getSalesCustomersListReducer, salesCustomerDetailsReducer, salesMonthlyReportReducer } from "./Sales";
import { operatorDashboardReducer, operatorAttachmentReducer, operatorOrderListReducer, operatorOrderDetailsReducer } from "./Operator";
import { designerAllOrdersListReducer, designerOrderDetailsReducer, designerDashboardCountReducer } from "../Reducers/designer";


const rootReducer = combineReducers({
    // Customer

    Auth: AuthReducer,
    Register: registerReducer,
    passChanged: ChangePassReducer,
    sendQuote: userSendQuotesReducer,
    getUserAllQuotes: getAllSendQuotesReducer,
    indivisualQuote: getIndivisualQuotesReducer,
    userOrders: userOrderListReducer,
    userOrderDetails: userOrderDetailsReducer,
    sendOrder: userSendOrderReducer,
    sendVectorOrder: userSendVectorOrderReducer,
    userVectorList: userVectorOrderListReducer,
    userVectorDetails: userVectorOrderDetailsReducer,
    getUserProfileData: getUserProfileDataReducer,
    convertQuoteIntoOrder: convertQuoteIntoOrderReducer,
    customerAllInvoices: CustomerInvoicesListReducer,
    customerInvoicesDetail: CustomerInvoicesDetailsReducer,
    customerQuotesDelete: customerQuotesDeleteReducer,
    customerOrderDelete: customerOrderDeleteReducer,
    customerVectorDelete: customerVectorDeleteReducer,

    // Admin 
    adminAllOrders: adminOrderListReducer,
    adminDeleteOrders: adminOrderDeleteReducer,
    adminAllQuotes: adminQuotesListReducer,
    adminDeleteQuotes: adminQuotesDeleteReducer,
    adminAllVectors: adminVectorListReducer,
    adminDeleteVectors: adminVectorDeleteReducer,
    quoteApproved: quoteApprovedReducer,
    allUsersByRole: allUsersByRoleReducer,
    assignedOrder: orderAssignedReducer,
    allRoles: getAllRolesListReducer,
    addUserData: addUserReducer,
    allUsers: AllUsersListReducer,
    allUsersDelete: allUsersDeleteReducer,
    UserDetails: getUserDetailsReducer,
    userAssignedToSales: userAssignedToSalesReducer,
    updateUserDetails: updateUserDetailsReducer,
    orderSentToCustomer: orderSentToCustomerReducer,
    adminDashboardData: adminDashboard,
    adminSendVectorOrder: adminVectorSendReducer,
    MoOrderSave: MoOrderSaveReducer,
    MoAdminOrderList: MoOrderListReducer,
    MoAdminOrderDetails: getMoOrderDetailsReducer,
    MoAdminOrderApprove: moOrderApproveReducer,
    vectorOrderAttachment: vectorOrderAttachmentReducer,
    vectorOrderAssigned: VectorOrderAssignedReducer,
    VectorOrderSent: VectorOrderSentToCustomerReducer,


    // Invoicing
    generateInvoice: generateInvoiceReducer,
    invoiceCreate: invoiceCreateReducer,
    invoicesList: AllInvoicesListReducer,
    invoiceDetails: getInvoicesDetailsReducer,

    // Digitizers
    digitizerOrdersList: digitizerAllOrdersListReducer,
    digitizerDashboardCountTotal: digitizerDashboardCountReducer,
    attachmentsData: digitizerAttachmentReducer,

    // Sales
    salesDashboardCount: salesDashboard,
    salesTotalQuotes: getSalesQuotesListReducer,
    salesQuoteDetails: getSalesQuotesDetailReducer,
    salesTotalOrders: salesOrderListReducer,
    salesOrderDetails: salesOrderDetailsReducer,
    salesVectorList: salesVectorOrderListReducer,
    salesVectorDetails: salesVectorOrderDetailsReducer,
    getSalesCustomersList: getSalesCustomersListReducer,
    salesCustomerDetails: salesCustomerDetailsReducer,
    salesReportGenerate: salesMonthlyReportReducer,


    // Operator
    operatorDashboardCount: operatorDashboardReducer,
    operatorOrderList: operatorOrderListReducer,
    operatorOrderDetails: operatorOrderDetailsReducer,
    operatorAttachments: operatorAttachmentReducer,

    // Designer
    designerAllOrdersList: designerAllOrdersListReducer,
    designerOrderdetails: designerOrderDetailsReducer,
    designerDashboard: designerDashboardCountReducer

})

export default rootReducer;