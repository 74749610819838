import api from "../../Utils/interceptors";
import { getToken } from "../../Utils/helper";


export const DigitizerDashboardCount = () => async (dispatch: any) => {
    try {
        dispatch({
            type: "DIGITIZERS_DASHBOARD_COUNT_REQUEST",
        });

        const { data } = await api.get('/digitizer/dashboard');

        dispatch({
            type: "DIGITIZERS_DASHBOARD_COUNT_SUCCESS",
            payload: data,
            success: true,
        });
    } catch (e: any) {
        dispatch({
            type: "DIGITIZERS_DASHBOARD_COUNT_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
};


export const DigitizerAllOrderRecords = (pageNO: number, perPage: number, text: string, today: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: "ALL_DIGITIZER_ORDERS_LIST_REQUEST",
        });

        const { data } = await api.get(`/digitizer/order/list?page_no=${pageNO + 1}&per_page=${perPage}&term=${text}&today=${today}`);


        dispatch({
            type: "ALL_DIGITIZER_ORDERS_LIST_SUCCESS",
            payload: data,
            success: true,
        });
    } catch (e: any) {
        dispatch({
            type: "ALL_DIGITIZER_ORDERS_LIST_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
};

export const digitizerAttachments = (order_id: any, attachmentsData: any,) => async (dispatch: any) => {
    try {
        dispatch({
            type: "DIGITIZER_ATTACHMENT_REQUEST",
        });

        const { data } = await api.post(`/digitizer/order/${order_id}/upload`, attachmentsData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: getToken()
            }
        });

        dispatch({
            type: "DIGITIZER_ATTACHMENT_SUCCESS",
            payload: data,
            success: true,
        });
    }
    catch (e: any) {
        dispatch({
            type: "DIGITIZER_ATTACHMENT_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
};