import api from "../../Utils/interceptors";
import { getToken } from "../../Utils/helper";

// Operator Dashboard Count

export const getOperatorDashboardData = () => async (dispatch: any) => {
    try {
        dispatch({
            type: "OPERATOR_DASHBOARD_DATA_REQUEST",
        });
        const { data } = await api.get("/printing/dashboard")
        dispatch({
            type: "OPERATOR_DASHBOARD_DATA_SUCCESS",
            payload: data,
        });
    } catch (e: any) {
        dispatch({
            type: "OPERATOR_DASHBOARD_DATA_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
}

// Operator Order List

export const operatorOrderList = (pageNO: number, perPage: number, text: string, today: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: "OPERATOR_ORDER_LIST_REQUEST",
        });

        const { data } = await api.get(
            `/printing/order/list?page_no=${pageNO + 1}&per_page=${perPage}&term=${text}&today=${today}`
        );

        dispatch({
            type: "OPERATOR_ORDER_LIST_SUCCESS",
            payload: data,
            success: true,
        });
    } catch (e: any) {
        dispatch({
            type: "OPERATOR_ORDER_LIST_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
};

// Operator Order Details

export const operatorOrderDetails = (order_id: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: "OPERATOR_ORDER_DETAILS_REQUEST",
        });

        const { data } = await api.get(`/printing/order/${order_id}`);

        dispatch({
            type: "OPERATOR_ORDER_DETAILS_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e: any) {
        dispatch({
            type: "OPERATOR_ORDER_DETAILS_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
}

// Operator Order Upload

export const operatorOrderAttachments = (order_id: any, attachmentsData: any,) => async (dispatch: any) => {
    try {
        dispatch({
            type: "OPERATOR_ATTACHMENTS_REQUEST",
        });

        const { data } = await api.post(`/printing/order/${order_id}/upload`, attachmentsData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: getToken()
            }
        });

        dispatch({
            type: "OPERATOR_ATTACHMENTS_SUCCESS",
            payload: data,
            success: true,
        });
    }
    catch (e: any) {
        dispatch({
            type: "OPERATOR_ATTACHMENTS_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
};
