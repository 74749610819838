import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { Container, Col, Row, Form, Spinner } from "react-bootstrap";
import { IAddUser } from '../../../../../Utils/interfaces';
import { AddUserValidation } from '../../../../../Utils/validation';
import { ThunkDispatch } from "redux-thunk";
import Input from "../../../../../Components/Input/Input";
import Button from "../../../../../Components/Button/Button";
import { AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { getRolesList, AddUserAction } from '../../../../../Redux/Actions/admin';
import { errorNotify, successNotify } from "../../../../../Utils/toast";

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const AddUser = () => {
    const { register, handleSubmit, reset, formState: { errors }, } = useForm<IAddUser>();
    const { rolesList } = useSelector((state: any) => state.allRoles);
    const { addUser, loading, error } = useSelector((state: any) => state.addUserData);
    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
        dispatch(getRolesList())
        if (addUser?.message) {
            successNotify(addUser?.message);
            reset();
            dispatch({ type: "ADD_USER_RESET" });
        }

        if (error) {
            errorNotify(error);
            dispatch({ type: "CLEAR_ERRORS" });
        }
    }, [error, addUser])

    const onSubmitHandler = handleSubmit(async (data: any) => {
        dispatch(AddUserAction(data))
    })

    return (
        <div className='page_responsive'>
            <div className="create_emp_bg_background">
                <Container>
                    <Form onSubmit={onSubmitHandler}>
                        <Row>
                            <Col md={12}>
                                <div className="personal_details_container">
                                    <div className="details_head">
                                        <h5>Add User</h5>
                                    </div>

                                    <div className="details_container">
                                        <Row>
                                            <Col md={6}>
                                                <Input>
                                                    <Form.Label>First Name:</Form.Label>
                                                    <Form.Control
                                                        placeholder='Enter first name'
                                                        type="text"
                                                        {...register("first_name", AddUserValidation.first_name)}
                                                    />
                                                </Input>
                                                {errors.first_name ? (
                                                    <small className="text-danger">
                                                        {errors.first_name && errors.first_name.message}
                                                    </small>
                                                ) : null}
                                            </Col>

                                            <Col md={6}>
                                                <Input>
                                                    <Form.Label>Last Name:</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder='Enter last name'
                                                        {...register("last_name", AddUserValidation.last_name)}
                                                    />
                                                </Input>
                                                {errors.last_name ? (
                                                    <small className="text-danger">
                                                        {errors.last_name && errors.last_name.message}
                                                    </small>
                                                ) : null}
                                            </Col>

                                            <Col md={6}>
                                                <Input>
                                                    <Form.Label>User Name:</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder='Enter username'
                                                        {...register("username", AddUserValidation.username)}
                                                    />
                                                </Input>
                                                {errors.username ? (
                                                    <small className="text-danger">
                                                        {errors.username && errors.username.message}
                                                    </small>
                                                ) : null}
                                            </Col>

                                            <Col md={6}>
                                                <Input>
                                                    <Form.Label>Status</Form.Label>
                                                    <Form.Select
                                                        aria-label="Default select example"
                                                        {...register("status", AddUserValidation.status)}
                                                    >
                                                        <option defaultValue={-1} key={-1}>
                                                            Choose Status
                                                        </option>
                                                        <option value={0}>Pending</option>
                                                        <option value={1}>Active</option>
                                                    </Form.Select>
                                                </Input>
                                                {errors.status ? (
                                                    <small className="text-danger">
                                                        {errors.status && errors.status.message}
                                                    </small>
                                                ) : null}
                                            </Col>

                                            <Col md={6}>
                                                <Input>
                                                    <Form.Label>Email:</Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        placeholder='Enter email address'
                                                        {...register("email", AddUserValidation.email)}
                                                    />
                                                </Input>
                                                {errors.email ? (
                                                    <small className="text-danger">
                                                        {errors.email && errors.email.message}
                                                    </small>
                                                ) : null}
                                            </Col>


                                            <Col md={6}>
                                                <Input>
                                                    <Form.Label>Password:</Form.Label>
                                                    <Form.Control
                                                        type="password"
                                                        autoComplete='off'
                                                        placeholder='Enter password'
                                                        {...register("password", AddUserValidation.password)}
                                                    />
                                                </Input>
                                                {errors.password ? (
                                                    <small className="text-danger">
                                                        {errors.password && errors.password.message}
                                                    </small>
                                                ) : null}
                                            </Col>

                                            <Col md={6}>
                                                <Input>
                                                    <Form.Label>Address:</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder='Enter address'
                                                        {...register("address", AddUserValidation.address)}
                                                    />
                                                </Input>
                                                {errors.address ? (
                                                    <small className="text-danger">
                                                        {errors.address && errors.address.message}
                                                    </small>
                                                ) : null}
                                            </Col>

                                            <Col md={6}>
                                                <Input>
                                                    <Form.Label>Role:</Form.Label>
                                                    <Form.Select
                                                        aria-label="Default select example"
                                                        {...register("role", AddUserValidation.role)}
                                                    >
                                                        <option defaultValue={-1} key={-1}>
                                                            Choose Role
                                                        </option>
                                                        {
                                                            rolesList?.data?.rows.map((rolesData: any, index: number) => (
                                                                <option key={index} value={rolesData?.id}>{rolesData?.name}</option>
                                                            ))
                                                        }
                                                    </Form.Select>
                                                </Input>
                                                {errors.role ? (
                                                    <small className="text-danger">
                                                        {errors.role && errors.role.message}
                                                    </small>
                                                ) : null}
                                            </Col>


                                            <Col md={6}>
                                                <Input>
                                                    <Form.Label>Country:</Form.Label>
                                                    <Form.Select
                                                        aria-label="Default select example"
                                                        {...register("country", AddUserValidation.country)}
                                                    >
                                                        <option defaultValue={-1} key={-1}>
                                                            Choose Country
                                                        </option>
                                                        <option value={256}>USA</option>
                                                        <option value={255}>UK</option>

                                                    </Form.Select>
                                                </Input>
                                                {errors.country ? (
                                                    <small className="text-danger">
                                                        {errors.country && errors.country.message}
                                                    </small>
                                                ) : null}
                                            </Col>
                                        </Row>
                                        <Col md={12}>
                                            <div className="d-flex justify-content-end py-3">
                                                <Button type="submit">

                                                    {loading ? (
                                                        <Spinner animation="border" size="sm" />
                                                    ) : (
                                                        "Submit"
                                                    )}
                                                </Button>
                                            </div>
                                        </Col>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>
        </div>
    )
}

export default AddUser
