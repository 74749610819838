import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import { Card, Col, Row } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { errorNotify } from "../../../../../Utils/toast";
import { DesignerOrderDetails } from "../../../../../Redux/Actions/designer";
import Loader from "../../../../../Utils/Loader";
import moment from 'moment'
import { downloadFile, cloudBaseURL, CurrentUserInfo } from '../../../../../Utils/helper'
import OrderIcon from "../../../../../Assets/images/orders.png";


type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;
const OrderDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { error, loading, designerOrderDetails } = useSelector((state: any) => state.designerOrderdetails);
  const dispatch: AppDispatch = useDispatch();
  const CurrencySymbol = CurrentUserInfo()?.country?.currency_symbol;
  useEffect(() => {
    if (error) {
      errorNotify(error);
      dispatch({ type: "CLEAR_ERRORS" });
    }
    dispatch(DesignerOrderDetails(id));
  }, []);
  
  return (
    <div className="page_responsive">
      <div className="card d-flex">
        <div className="send_quotes_head_container" id="digitizer_order">
          <div className='send_digitizing_order_div_shorter'>
            <img src={OrderIcon} alt="pencil-logo" />
          </div>
          <h4>Digitizing Order Details</h4>
        </div>
      </div>
      <div className="user_details_card">
        <Card className="order_details_card">
          {loading ? (
            <Loader />
          ) : (
            <Card.Body>
              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Order ID:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">{designerOrderDetails?.data?.id}</Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Order Name:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">{designerOrderDetails?.data?.vector_name}</Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Order Date:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">{moment(designerOrderDetails?.data?.createdAt).utc().format('DD-MM-YYYY')}</Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Customer Username:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {designerOrderDetails?.data?.Customer?.username}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Customer Full Name:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {designerOrderDetails?.data?.Customer?.first_name}{" "}
                        {designerOrderDetails?.data?.Customer?.last_name}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Required Format:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {designerOrderDetails?.data?.Format?.name}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Number of Colors:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">{designerOrderDetails?.data?.no_color}</Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Super Urgent:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {parseInt(designerOrderDetails?.data?.urgent) === 0
                          ? "not-urgent"
                          : "urgent"}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-around my-1">
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Status:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">
                        {parseInt(designerOrderDetails?.data?.designer_status) === 0 ? "New Order" : "Completed"}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} className="m-1 detail_field">
                  <Row>
                    <Col md={6}>
                      <Typography variant="h6">Price:</Typography>
                    </Col>
                    <Col md={6}>
                      <Typography className="text-end">{designerOrderDetails?.data?.price === 0 ? "Price not selected" : `${CurrencySymbol}${designerOrderDetails?.data?.price}`}</Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="justify-content-around my-1">
                <Col md={11} className="m-1 detail_field">
                  <Row>
                    <Col md={12}>
                      <Typography variant="h6">
                        Additional Instruction:{" "}
                      </Typography>
                      <Typography >
                        {designerOrderDetails?.data?.additional_instruction}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {(designerOrderDetails?.data?.attachments?.length > 0) && (
                <Row className="justify-content-around my-1">
                  <Col md={11} className="m-1">
                    <Typography variant="h4">Customer Attachments:</Typography>
                  </Col>
                </Row>
              )}
              <Row className="justify-content-around my-1">
                {(designerOrderDetails?.data?.attachments) &&
                  designerOrderDetails?.data?.attachments.map(
                    (attachment: any, i: any) => {
                      if (parseInt(attachment?.attachment_type) === 0) {
                        return (
                          <Col md={11} className="m-1 detail_field">
                            <Row className="align-items-center">
                              <Col md={4}>
                                <Typography variant="h6">File {i + 1}:</Typography>
                              </Col>
                              <Col md={4}>
                                <Typography className="text-center">
                                  {attachment.attachment_name}
                                </Typography>
                              </Col>
                              <Col md={4} className="d-flex justify-content-end">
                                <button className="download_btn" onClick={() => downloadFile(`${cloudBaseURL}vector/${id}/${attachment.attachment_name}`, `${attachment.attachment_name}`)}>
                                  <FaDownload /> Download
                                </button>
                              </Col>
                            </Row>
                          </Col>
                        )
                      }
                    }
                  )}
              </Row>

              {(parseInt(designerOrderDetails?.data?.designer_status) === 1 && designerOrderDetails?.data?.attachments?.length > 0) && (
                <Row className="justify-content-around my-1">
                  <Col md={11} className="m-1">
                    <Typography variant="h4">Designer Attachments:</Typography>
                  </Col>
                </Row>
              )}
              <Row className="justify-content-around my-1">
                {(designerOrderDetails?.data?.attachments && parseInt(designerOrderDetails?.data?.designer_status) === 1) &&
                  designerOrderDetails?.data?.attachments.map(
                    (attachment: any, i: any) => {
                      if (parseInt(attachment?.attachment_type) === 1) {
                        return (
                          <Col md={11} className="m-1 detail_field">
                            <Row className="align-items-center">
                              <Col md={4}>
                                <Typography variant="h6">File {i + 1}:</Typography>
                              </Col>
                              <Col md={4}>
                                <Typography className="text-center">
                                  {attachment.attachment_name}
                                </Typography>
                              </Col>
                              <Col md={4} className="d-flex justify-content-end">
                                <button className="download_btn" onClick={() => downloadFile(`${cloudBaseURL}vector/${id}/${attachment.attachment_name}`, `${attachment.attachment_name}`)}>
                                  <FaDownload /> Download
                                </button>
                              </Col>
                            </Row>
                          </Col>
                        )
                      }
                    }
                  )}
              </Row>
              <Row className="pt-4 justify-content-end">
                <Col md={11} className="p-0 button_container_main">
                  <button
                    className="my-1 details_btn bg-gray"
                    onClick={() => navigate("/designer/orders")}
                  >
                    Go Back
                  </button>

                  {
                    parseInt(designerOrderDetails?.data?.designer_status) === 0 ?
                      <button className="my-1 details_btn sent_order" onClick={() => navigate(`/designer/upload/order/${id}`)}>
                        Order Upload
                      </button>
                      :
                      <button className="my-1 details_btn sent_order" id='disabled_btn' disabled>
                        Sent Order
                      </button>
                  }
                </Col>
              </Row>
            </Card.Body>
          )}
        </Card>
      </div>
    </div>
  );
};

export default OrderDetails;
