import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Form, Spinner } from 'react-bootstrap';
import reportIcon from '../../../../Assets/images/report.png'
import './MonthlyReport.css';
import MuiDataTable from "../../../../Components/MUIDataTable/MUIDataTable";
import moment from 'moment';
import { Link } from 'react-router-dom';
import eyeIcon from '../../../../Assets/images/eye.png';
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { monthlyData } from './dummydata';
import { useForm, SubmitHandler } from "react-hook-form";
import { ISalesMonthlyReport } from '../../../../Utils/interfaces';
import { getSalesMonthlyReport } from '../../../../Redux/Actions/Sales';
import { errorNotify, successNotify } from '../../../../Utils/toast';
import Loader from '../../../../Utils/Loader';
type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const MonthlyReport = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10000);
    const [text, setText] = useState('');
    const dispatch: AppDispatch = useDispatch();
    const { register, handleSubmit, reset, formState: { errors }, setValue } = useForm<ISalesMonthlyReport>();
    var date = new Date();
    var startDate: any = new Date();
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const { error, loading, salesMonthlyReport } = useSelector((state: any) => state.salesReportGenerate);

    setValue("startdate", moment(startDate).format("YYYY-MM-DD"));
    setValue("enddate", moment(lastDay).format("YYYY-MM-DD"))


    useEffect(() => {
        if (salesMonthlyReport?.status === 1) {
            successNotify("Report generated successfully!")
            reset();
        }
        if (error) {
            errorNotify(error)
            dispatch({ type: "CLEAR_ERRORS" })
        }
    }, [salesMonthlyReport?.status, error])

    const monthlyRecordsColumns = [
        { name: "sno", label: "S:NO" },
        { name: "id", label: "ORDER ID" },
        { name: "username", label: "NAME" },
        { name: "order_name", label: "ORDER NAME" },
        { name: "order_type", label: "ORDER TYPE" },
        {
            name: "createdAt", label: "CREATED AT",
            options: {
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return moment(new Date(`${value} UTC`)).format("DD-MM-YYYY hh:mm:ss");
                },
            },
        },

        {
            name: "price", label: "PRICE",
            options: {
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    if (!value || null || 0) {
                        return '-'
                    }

                    else {
                        return (<span className='currency'>{parseInt(value)}</span>)
                    }
                },
            },
        },

        {
            name: "STATUS",
            label: "STATUS",
            options: {
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                     if (parseInt(value) === 2) {
                        return (
                            <div className="status_completed">
                                <p className="mb-0">completed</p>
                            </div>
                        )
                    }
                },
            },
        },
    ];
    let newData: any = [];
    salesMonthlyReport?.data?.map((item: any, index: any) => {
        newData.push({ sno: rowsPerPage * page + index + 1, ...item });
    });

    const salesReportSubmit: SubmitHandler<ISalesMonthlyReport> = async (data: any) => {
        dispatch(getSalesMonthlyReport(data?.startdate, data?.enddate));
    };

    return (
        <div className='page_responsive'>
            <div className="card">
                <div className="send_quotes_head_container" id="digitizer_order">
                    <div className='send_digitizing_order_div_shorter'>
                        <img src={reportIcon} alt="pencil-logo" />
                    </div>
                    <h4>Generate Monthly Report</h4>
                </div>
            </div>

            <div className='monthly_report_main'>
                <Container fluid>
                    <Form onSubmit={handleSubmit(salesReportSubmit)}>
                        <Row>
                            <Col md={4}>
                                <div className='report_input_container'>
                                    <label>START DATE:</label>
                                    <input className='form-control' type="date" {...register("startdate")} />
                                </div>
                            </Col>

                            <Col md={4}>
                                <div className='report_input_container'>
                                    <label>END DATE:</label>
                                    <input className='form-control' type="date" {...register("enddate")} />
                                </div>
                            </Col>

                            <Col md={4}>
                                <div className='report_btn_container'>
                                    <button type='submit'>
                                        {
                                            loading ? <Spinner animation='border' size='sm' /> : "GENERATE REPORT"
                                        }
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>

            <div className="digitizing-table" id='monthly_report'>
                {loading ? <Loader /> :
                    <MuiDataTable
                        title={"GENERATE REPORT"}
                        data={newData}
                        columns={monthlyRecordsColumns}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        count={newData?.length}
                        setText={setText}
                        text={text}
                    />
                }

            </div>

        </div >
    )
}

export default MonthlyReport
