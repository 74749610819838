import React from 'react';
import {Grid} from "react-loader-spinner";

const Loader = () => {
    return (
        <div className={'d-flex justify-content-center align-items-center my-5'}>
            <Grid ariaLabel="loading-indicator"
                      color="#AAAAFF"
                      width={ "30px"}
                      height={"30px"}
            />
        </div>
    );
};

export default Loader;