import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Col, Row, Form, Spinner } from "react-bootstrap";
import { IApprovedQuote } from "../../../../../Utils/interfaces";
import { ApprovedQuoteValidtion } from "../../../../../Utils/validation";
import { ThunkDispatch } from "redux-thunk";
import Input from "../../../../../Components/Input/Input";
import Button from "../../../../../Components/Button/Button";
import { AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { quoteApproved } from "../../../../../Redux/Actions/admin";
import { errorNotify, successNotify } from "../../../../../Utils/toast";

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;
const ApprovedQuote = () => {
  const {register,handleSubmit,reset,formState: { errors }} = useForm<IApprovedQuote>();
  const { id } = useParams();
  const navigate = useNavigate()
  const { error, loading, approved } = useSelector((state: any) => state.quoteApproved);

  useEffect(() => {
    if (approved?.status === 1) {
      successNotify("Quote Approved successfully");
      dispatch({ type: "QUOTE_APPROVED_RESET" });
      navigate("/admin/all-quotes")
    }
    if (error) {
      errorNotify(error);
      dispatch({ type: "CLEAR_ERRORS" });
    }
  }, [approved?.status, error]);

  const dispatch: AppDispatch = useDispatch();

  const onSubmitHandler = handleSubmit(async (data: any) => {
    dispatch(quoteApproved(id, data));
    reset();
  });
  return (
    <div className="page_responsive">
      <div className="create_emp_bg_background">
        <Container>
          <Form onSubmit={onSubmitHandler}>
            <Row>
              <Col md={12}>
                <div className="personal_details_container">
                  <div className="details_head">
                    <h5>Approved Quote</h5>
                  </div>

                  <div className="details_container">
                    <Row>
                      <Col md={12}>
                        <Input>
                          <Form.Label>Price:</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Price"
                            {...register("price", ApprovedQuoteValidtion.price)}
                          />
                        </Input>
                        {errors.price ? (
                          <small className="text-danger">
                            {errors.price && errors.price.message}
                          </small>
                        ) : null}
                      </Col>

                      <Col md={4}>
                        <Input>
                          <Form.Label>Status</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            {...register(
                              "status",
                              ApprovedQuoteValidtion.status
                            )}
                          >
                            <option defaultValue={-1} key={-1}>
                              select status
                            </option>
                            <option value={0}>Pending</option>
                            <option value={1}>Approved</option>
                          </Form.Select>
                        </Input>
                        {errors.status ? (
                          <small className="text-danger">
                            {errors.status && errors.status.message}
                          </small>
                        ) : null}
                      </Col>
                    </Row>
                    <Col md={12}>
                      <div className="d-flex justify-content-end py-3">
                        <Button type="submit">
                          {" "}
                          {loading ? (
                            <Spinner animation="border" size="sm" />
                          ) : (
                            "Approved Quote"
                          )}
                        </Button>
                      </div>
                    </Col>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </div>
  );
};

export default ApprovedQuote;
