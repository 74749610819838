import React from "react";
import CustomerNavbar from "../../Container/Customer/Routes/Navbar";
import { Outlet } from "react-router-dom";
import NotFound from "../../Container/Pages/NotFound/NotFound";

const CustomerLayout = () => {
  let userAuth: any = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "") : null;

  return userAuth?.role?.name === "Customer" ? (
    <React.Fragment>
      <CustomerNavbar />
      <Outlet />
    </React.Fragment>
  ) : (
    <NotFound />
  );
};
export default CustomerLayout;
