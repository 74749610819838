import React, { useEffect, useState } from "react";
import MuiDataTable from "../../../../Components/MUIDataTable/MUIDataTable";
import { Link, useNavigate } from "react-router-dom";
import { getSalesOrdersRecord } from "../../../../Redux/Actions/Sales";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import Loader from "../../../../Utils/Loader";
import moment from 'moment';
import { CurrentUserInfo } from '../../../../Utils/helper';
import eyeIcon from '../../../../Assets/images/eye.png';
import OrderIcon from "../../../../Assets/images/orders.png";

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const DigitizingRecords: React.FC<any> = ({ style, today = '' }) => {
  const { loading, salesOrderList, ordersCount } = useSelector((state: any) => state.salesTotalOrders);
  const dispatch: AppDispatch = useDispatch();
  const navigation = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [text, setText] = useState('');
  const currencySymbol = CurrentUserInfo()?.country?.currency_symbol;

  useEffect(() => {
    dispatch(getSalesOrdersRecord(page, rowsPerPage, text, today));
  }, [page, rowsPerPage, text]);// eslint-disable-line react-hooks/exhaustive-deps

  const digitizingRecordsColumns = [
    { name: "sno", label: "S:NO" },
    { name: "order_id", label: "ORDER NO" },
    { name: "order_name", label: "ORDER NAME" },
    {
      name: "createdAt", label: "SEND DATE",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return moment(new Date(`${value} UTC`)).format("DD-MM-YYYY hh:mm:ss");
        },
      },
    },
    {
      name: "status",
      label: "STATUS",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          if (parseInt(value) === 0) {
            return (
              <div className="status-pending">
                <p className="mb-0">pending</p>
              </div>
            )
          }
          else if (parseInt(value) === 1) {
            return (
              <div className="status_in_process">
                <p className="mb-0">In process</p>
              </div>
            )
          }
          else if (parseInt(value) === 2) {
            return (
              <div className="status_completed">
                <p className="mb-0">completed</p>
              </div>
            )
          }
        },
      },
    },
    {
      name: "price", label: "PRICE",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          if (!value || null || 0) {
            return '-'
          }

          else {
            return (<span className='currency'>{currencySymbol}{parseInt(value)}</span>)
          }
        },
      },
    },

    {
      name: "DETAILS",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <div className="action_container">
              <Link to={`/sales/digitizing-order-details/${tableMeta.rowData[1]}`}>
                <button
                  className="view-btn"
                  type="button"
                >
                  <img src={eyeIcon} alt="eye" />
                </button>
              </Link>
            </div>
          );
        },
      },
    },
  ];
  let newData: any = [];
  salesOrderList?.data?.rows?.map((item: any, index: any) => {
    newData.push({ sno: rowsPerPage * page + index + 1, ...item });
  });


  return (
    <div className={`${style ? style : "page_responsive"}`}>
      <div className="card">
        <div className="send_quotes_head_container" id="digitizer_order">
          <div className='send_digitizing_order_div_shorter'>
            <img src={OrderIcon} alt="pencil-logo" />
          </div>
          <h4>{today !== '' ? 'Todays All Digitizng Orders' : 'All Digitizng Orders'}</h4>
        </div>
      </div>
      <div className="digitizing-table">
        {!text && loading ? <Loader /> :
          <MuiDataTable
            title={today !== '' ? 'Todays All Digitizng Orders' : 'All Digitizng Orders'}
            data={newData}
            columns={digitizingRecordsColumns}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            count={ordersCount}
            setText={setText}
            text={text}
          />
        }
      </div>
    </div>
  );
};

export default DigitizingRecords;
