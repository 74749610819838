import api from "../../Utils/interceptors";
import { getToken } from "../../Utils/helper";

export const userSendQuotes = (quoteData: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: "SEND_QUOTES_REQUEST",
        });

        const { data } = await api.post("/quote", quoteData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: getToken()
            }
        });

        dispatch({
            type: "SEND_QUOTES_SUCCESS",
            payload: data,
            success: true,
        });
    }
    catch (e: any) {
        dispatch({
            type: "SEND_QUOTES_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
};


export const getUserQuotesRecord = (pageNO: number, perPage: number, text: string) => async (dispatch: any) => {

    try {
        dispatch({
            type: "GET_SEND_QUOTES_REQUEST"
        })

        const { data } = await api.get(`/quote/list?page_no=${pageNO + 1}&per_page=${perPage}&term=${text}`)

        dispatch({
            type: "GET_SEND_QUOTES_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e: any) {
        dispatch({
            type: "GET_SEND_QUOTES_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
}


export const getUserQuotesDetails = (quote_id: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: "GET_QUOTES_DETAIL_REQUEST"
        })

        const { data } = await api.get(`/quote/${quote_id}`)


        dispatch({
            type: "GET_QUOTES_DETAIL_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e: any) {
        dispatch({
            type: "GET_QUOTES_DETAIL_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
}


export const getUserProfileData = () => async (dispatch: any) => {
    try {
        dispatch({
            type: "GET_USER_PROFILE_REQUEST"
        })

        const { data } = await api.get('/user/profile')


        dispatch({
            type: "GET_USER_PROFILE_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e: any) {
        dispatch({
            type: "GET_USER_PROFILE_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
}


export const quoteConvertIntoOrder = (quote_id: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: "QUOTE_CONVERT_REQUEST"
        })

        const { data } = await api.put(`/digitizing/order/direct/${quote_id}`, {})


        dispatch({
            type: "QUOTE_CONVERT_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e: any) {
        dispatch({
            type: "QUOTE_CONVERT_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
}


// Customer Delete Quotes

export const deleteCustomerQuotesRecords = (order_id: string) => async (dispatch: any) => {

    try {
        dispatch({
            type: "CUSTOMER_QUOTES_DELETE_REQUEST",
        });

        const { data } = await api.delete(`/quote/${order_id}`);

        dispatch({
            type: "CUSTOMER_QUOTES_DELETE_SUCCESS",
            payload: data,
            success: true,
        });
    } catch (e: any) {
        dispatch({
            type: "CUSTOMER_QUOTES_DELETE_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
};


