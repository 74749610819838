import React, { useRef, useEffect } from 'react'
import './InvoiceDetails.css';
import { Container, Col, Row } from 'react-bootstrap';
import MUIDataTable, { FilterType } from "mui-datatables";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import Logo from '../../../../../Assets/images/new-logo.png';
import ReactToPrint from "react-to-print";
import { useParams } from "react-router-dom";
import Loader from "../../../../../Utils/Loader";
import moment from "moment";
import { getCustomerInvoiceDetails } from '../../../../../Redux/Actions/CustomerInvoices';
import downloadInvoiceIcon from '../../../../../Assets/images/download-icon.png';
import phoneIcon from '../../../../../Assets/images/phone.png'
import mailIcon from '../../../../../Assets/images/mail-white.png'
import worldIcon from '../../../../../Assets/images/world.png'

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const InvoiceDetails = () => {
    const componentRef = useRef<any>();
    const dispatch: AppDispatch = useDispatch();
    const { loading, customerInvoiceDetail } = useSelector((state: any) => state?.customerInvoicesDetail);
    const { id } = useParams();

    useEffect(() => {
        dispatch(getCustomerInvoiceDetails(id));
    }, []);

    let invoiceData: any = [];

    customerInvoiceDetail?.data?.items?.map((item: any, index: any) => {
        invoiceData.push({
            id: item?.id,
            order_id: item?.Digitizing
                ? "Digitizing: " + item?.Digitizing?.id
                : item?.Vector
                    ? "Vector: " + item?.Vector?.id
                    : undefined,
            order_name: item?.Digitizing
                ? item?.Digitizing?.order_name
                : item?.Vector
                    ? item?.Vector?.vector_name
                    : undefined,
            price: item?.Digitizing
                ? item?.Digitizing?.price
                : item?.Vector
                    ? item?.Vector?.price
                    : undefined,
        });
    });

    let total = 0;
    for (let amount of invoiceData) {
        total += amount.price;
    }

    const invoiceRecordsColumns = [
        {
            name: "order_id",
            label: "ORDER ID",
            options: {
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    if (value !== null) {
                        return value;
                    }
                },
            },
        },
        {
            name: "order_name",
            label: "ORDER NAME",
            options: {
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    if (value !== null) {
                        return value;
                    }
                },
            },
        },

        {
            name: "price",
            label: "PRICE",
            options: {
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    if (value !== null) {
                        return (
                            <span className='currency'>{customerInvoiceDetail?.data?.Customer?.Country?.currency_symbol + value}</span>
                        );
                    }
                },
            },
        },
    ];


    const option: FilterType | any = {
        filter: false,
        filterType: "checkbox",
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        responsive: "standard",
        selectableRows: "none",
        pagination: false,
    };

    return (
        <div className='page_responsive' >
            <Container>
                <div className="card d-flex">
                    <div className="send_quotes_head_container">
                        <h3 style={{ color: "#3077F2", fontSize: "25px", fontWeight: "600" }}>Invoice Details</h3>
                    </div>
                </div>
                {loading ? (
                    <Loader />
                ) : (
                    <Row>
                        <Col md={12} className='invoice_main'>
                            <div className='print_btn_container'>
                                <ReactToPrint
                                    trigger={() => <img src={downloadInvoiceIcon} style={{ cursor: "pointer" }} alt='donwload' />}
                                    content={() => componentRef.current}
                                />
                            </div>
                            <div className='card invoice_logo_container' ref={componentRef}>
                                <img src={Logo} alt="invoice-logo" />
                                <div className='invoice_no_container'>
                                    <div>
                                        <h3>Invoice</h3>
                                        <p>Invoice No:</p>
                                    </div>
                                    <div>
                                        <p>{customerInvoiceDetail?.data?.invoice_no}</p>
                                    </div>
                                </div>
                            
                                <div className='invoice_no_container'>
                                    <div>
                                        <h3>{customerInvoiceDetail?.data?.Customer?.username}</h3>
                                        <p>Email: {customerInvoiceDetail?.data?.Customer?.email}</p>
                                        <p>Phone: {customerInvoiceDetail?.data?.Customer?.phone}</p>
                                        <p>Address: {customerInvoiceDetail?.data?.Customer?.address ? customerInvoiceDetail?.data?.Customer?.address : "-"}</p>
                                        <p>Company:  {customerInvoiceDetail?.data?.Customer?.CompanyName ? customerInvoiceDetail?.data?.Customer?.CompanyName : "-"}</p>
                                    </div>
                                    <div className='print_container'>
                                        <p>Issue Date: {moment(new Date(`${customerInvoiceDetail?.data?.createdAt} UTC`)).format("DD-MM-YYYY hh:mm:ss")}</p>
                                    </div>
                                </div>

                                <Row className='justify-content-center'>
                                    <Col md={12}>
                                        <div className='quotes-table'>
                                            <MUIDataTable
                                                title={''}
                                                data={invoiceData}
                                                columns={invoiceRecordsColumns}
                                                options={option}
                                            />
                                        </div>
                                    </Col>
                                </Row>

                                <Row className='justify-content-end'>
                                    <Col md={4}>
                                        <div className='total_table mb-4'>
                                            <div className='cards'>
                                                <div className='total_head'>
                                                    <div>
                                                        <h5>SUBTOTAL</h5>
                                                    </div>

                                                    <div>
                                                        <p>
                                                            {customerInvoiceDetail?.data?.Customer?.Country?.currency_symbol}{total}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className='card-body'>
                                                    <div>
                                                        <h5>TOTAL</h5>
                                                    </div>

                                                    <div>
                                                        <p>
                                                            {customerInvoiceDetail?.data?.Customer?.Country?.currency_symbol}{total}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className='card-body'>
                                                    <div>
                                                        <h5>CHECKOUT</h5>
                                                    </div>

                                                    <div>
                                                        <button className="status-pending">Pay Now</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="justify-content-center">
                                    <Col md={12}>
                                        <div className="mail_container">
                                            <h4>Thanks for your business with Climax Digitizing!</h4>
                                            <p className="query_text">
                                                If you have any questions about this invoice, do not
                                                hesitate to contact us.
                                            </p>

                                            <div className="container_main">
                                                <div className="phone_mail_container_main">
                                                    <div className="tel_container">
                                                        <img src={phoneIcon} alt='phone' />
                                                    </div>
                                                    <p> +1 602 635 1843</p>
                                                </div>

                                                <div className="phone_mail_container_main">
                                                    <div className="Mail_Container">
                                                        <img src={mailIcon} alt='mail' />
                                                    </div>
                                                    <a href="mailto:accounts@climaxdigitizing.net">
                                                        accounts@climaxdigitizing.net
                                                    </a>
                                                </div>

                                                <div className="phone_mail_container_main">
                                                    <div className="world_container">
                                                        <img src={worldIcon} alt='world' />
                                                    </div>
                                                    <p>www.climaxdigitizing.net</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </div>
    )
}

export default InvoiceDetails
