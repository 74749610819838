
// Operator Dashboard Count Cards

export const operatorDashboardReducer = (state = [], action: any) => {
    switch (action.type) {
        case "OPERATOR_DASHBOARD_DATA_REQUEST":
            return {
                ...state,
                loading: true,
            }
        case "OPERATOR_DASHBOARD_DATA_SUCCESS":
            return {
                ...state,
                loading: false,
                dashboard: action.payload,
            }
        case "OPERATOR_DASHBOARD_DATA_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state;
    }
}

// Operator Order List

export const operatorOrderListReducer = (state = [], action: any) => {
    switch (action.type) {
        case "OPERATOR_ORDER_LIST_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "OPERATOR_ORDER_LIST_SUCCESS":
            return {
                ...state,
                loading: false,
                orders: action.payload,
                ordersCount: action.payload.data.count
            };
        case "OPERATOR_ORDER_LIST_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
                ordersCount: 0
            };
        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};

// Order Order Details

export const operatorOrderDetailsReducer = (state = {}, action: any) => {
    switch (action.type) {
        case "OPERATOR_ORDER_DETAILS_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "OPERATOR_ORDER_DETAILS_SUCCESS":
            return {
                ...state,
                loading: false,
                orderDetails: action.payload,
            };
        case "OPERATOR_ORDER_DETAILS_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "OPERATOR_ORDER_DETAILS_RESET":
            return {
                ...state,
                orderDetails: null
            }
        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};

// Operator Upload Attachments

export const operatorAttachmentReducer = (state = {}, action: any) => {
    switch (action.type) {
        case "OPERATOR_ATTACHMENTS_REQUEST":
            return {
                ...state,
                loading: true,
            };

        case "OPERATOR_ATTACHMENTS_SUCCESS":
            return {
                ...state,
                loading: false,
                attachments: action.payload,
            };
        case "OPERATOR_ATTACHMENTS_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "OPERATOR_ATTACHMENTS_RESET":
            return {
                ...state,
                attachments: null,
            };
        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}