import React, { useEffect } from "react";
import { Container, Col, Row, Form } from "react-bootstrap";
import Input from "../../../../Components/Input/Input";
import { IUserProfile } from "../../../../Utils/interfaces";
import { useForm } from "react-hook-form";
import { getUserProfileData } from "../../../../Redux/Actions/UserQuotes";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import Loader from "../../../../Utils/Loader";
import moment from "moment";
import { AnyAction } from "redux";
import './MyProfile.css'
import profileIcon from '../../../../Assets/images/profile-blue.png'

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const MyProfile = () => {
  const { register, setValue } = useForm<IUserProfile>();
  const { loading, getUserProfile } = useSelector((state: any) => state.getUserProfileData);
  const dispatch: AppDispatch = useDispatch();

  setValue("username", getUserProfile?.data?.username);
  setValue("first_name", getUserProfile?.data?.first_name);
  setValue("last_name", getUserProfile?.data?.last_name);
  setValue("email", getUserProfile?.data?.email);
  setValue("alternative_email", getUserProfile?.data?.alternative_email == null || " " ? "-" : getUserProfile?.data?.alternative_email);
  setValue("company_name", getUserProfile?.data?.company_name == null || " " ? "-" : getUserProfile?.data?.company_name);
  setValue("companyType", getUserProfile?.data?.companyType?.name == null || " " ? "-" : getUserProfile?.data?.companyType?.name);
  setValue("phone", getUserProfile?.data?.phone == null || " " ? "-" : getUserProfile?.data?.phone);
  setValue("cell", getUserProfile?.data?.cell == null || " " ? "-" : getUserProfile?.data?.cell);
  setValue("fax", getUserProfile?.data?.fax == null || " " ? "-" : getUserProfile?.data?.fax);
  setValue("address", getUserProfile?.data?.address == null || " " ? "-" : getUserProfile?.data?.address);
  setValue("city", getUserProfile?.data?.city == null || " " ? "-" : getUserProfile?.data?.city);
  setValue("Country", getUserProfile?.data?.Country.name);
  setValue("zip_code", getUserProfile?.data?.zip_code == null || " " ? "-" : getUserProfile?.data?.zip_code);
  setValue("postal_code", getUserProfile?.data?.postal_code == null || " " ? "-" : getUserProfile?.data?.postal_code);
  setValue("state", getUserProfile?.data?.state == null || " " ? "-" : getUserProfile?.data?.state);
  setValue("Reference", getUserProfile?.data?.Reference?.name);
  setValue("registered_date", moment(`${new Date(getUserProfile?.data?.registered_date)}UTC`).format("DD-MM-YYYY hh:mm:ss A"));
  setValue("userStatus", getUserProfile?.data?.status === 1 ? "Active" : "pending");

  useEffect(() => {
    dispatch(getUserProfileData());
  }, []);

  return (
    <div className="page_responsive">
      <Container>
        <div className="card d-flex">
          <div className="send_quotes_head_container" id="profile_Div">
            <img src={profileIcon} alt="pencil-logo" />
            <h4>My Profile</h4>
          </div>
        </div>
      </Container>
      {loading ? (
        <Loader />
      ) : (
        <div className="create_emp_bg_background">
          <Container>
            <Form>
              <Row>
                <Col md={12}>
                  <div className="personal_details_container">
                    <div className="details_head">
                      <h5>Details</h5>
                    </div>

                    <div className="details_container">
                      <Row>
                        <Col md={4}>
                          <Input>
                            <Form.Label>Customer User Name:</Form.Label>
                            <Form.Control
                              type="text"
                              {...register("username")}
                              disabled={true}
                            />
                          </Input>
                        </Col>

                        <Col md={4}>
                          <Input>
                            <Form.Label>Customer First Name:</Form.Label>
                            <Form.Control
                              type="text"
                              {...register("first_name")}
                              disabled={true}
                            />
                          </Input>
                        </Col>
                        <Col md={4}>
                          <Input>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              type="text"
                              {...register("last_name")}
                              disabled={true}
                            />
                          </Input>
                        </Col>

                        <Col md={6}>
                          <Input>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="email"
                              {...register("email")}
                              disabled={true}
                            />
                          </Input>
                        </Col>

                        <Col md={6}>
                          <Input>
                            <Form.Label>Alternative Email</Form.Label>
                            <Form.Control
                              type="email"
                              {...register("alternative_email")}
                              disabled={true}
                            />
                          </Input>
                        </Col>

                        <Col md={4}>
                          <Input>
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control
                              type="text"
                              {...register("company_name")}
                              disabled={true}
                            />
                          </Input>
                        </Col>

                        <Col md={4}>
                          <Input>
                            <Form.Label>Company Type</Form.Label>
                            <Form.Control
                              type="text"
                              {...register("companyType")}
                              disabled={true}
                            />
                          </Input>
                        </Col>

                        <Col md={4}>
                          <Input>
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                              type="text"
                              {...register("phone")}
                              disabled={true}
                            />
                          </Input>
                        </Col>
                        <Col md={4}>
                          <Input>
                            <Form.Label>Cell</Form.Label>
                            <Form.Control
                              type="text"
                              {...register("cell")}
                              disabled={true}
                            />
                          </Input>
                        </Col>

                        <Col md={4}>
                          <Input>
                            <Form.Label>Fax</Form.Label>
                            <Form.Control
                              type="text"
                              {...register("fax")}
                              disabled={true}
                            />
                          </Input>
                        </Col>

                        <Col md={4}>
                          <Input>
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                              type="text"
                              {...register("address")}
                              disabled={true}
                            />
                          </Input>
                        </Col>

                        <Col md={4}>
                          <Input>
                            <Form.Label>City</Form.Label>
                            <Form.Control
                              type="text"
                              {...register("city")}
                              disabled={true}
                            />
                          </Input>
                        </Col>

                        <Col md={4}>
                          <Input>
                            <Form.Label>State</Form.Label>
                            <Form.Control
                              type="text"
                              {...register("state")}
                              disabled={true}
                            />
                          </Input>
                        </Col>

                        <Col md={4}>
                          <Input>
                            <Form.Label>Country</Form.Label>
                            <Form.Control
                              type="text"
                              {...register("Country")}
                              disabled={true}
                            />
                          </Input>
                        </Col>

                        <Col md={4}>
                          <Input>
                            <Form.Label>Zip Code</Form.Label>
                            <Form.Control
                              type="text"
                              {...register("zip_code")}
                              disabled={true}
                            />
                          </Input>
                        </Col>

                        <Col md={4}>
                          <Input>
                            <Form.Label>Postal Code</Form.Label>
                            <Form.Control
                              type="text"
                              {...register("postal_code")}
                              disabled={true}
                            />
                          </Input>
                        </Col>

                        <Col md={4}>
                          <Input>
                            <Form.Label>Reference</Form.Label>
                            <Form.Control
                              type="text"
                              {...register("Reference")}
                              disabled={true}
                            />
                          </Input>
                        </Col>

                        <Col md={6}>
                          <Input>
                            <Form.Label>Registered Date</Form.Label>
                            <Form.Control
                              type="text"
                              {...register("registered_date")}
                              disabled={true}
                            />
                          </Input>
                        </Col>

                        <Col md={6}>
                          <Input>
                            <Form.Label>Status</Form.Label>
                            <Form.Control
                              type="text"
                              id={getUserProfile?.data?.status === 1 ? "Status" : "Pending_Status"}
                              {...register("userStatus")}
                              disabled={true}
                            />
                          </Input>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>

          </Container>
        </div>
      )}
    </div>
  );
};

export default MyProfile;
