import React, { useEffect } from 'react'
import { Card, Col, Row } from "react-bootstrap";
import Loader from "../../../../../Utils/Loader";
import { Typography } from "@mui/material";
import moment from "moment";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { useParams, useNavigate } from 'react-router-dom'
import { getUserDetails } from '../../../../../Redux/Actions/admin';
import Button from "../../../../../Components/Button/Button";

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const CustomerDetails = () => {
    const dispatch: AppDispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()
    const { loading, userDetails } = useSelector((state: any) => state.UserDetails);

    useEffect(() => {
        dispatch(getUserDetails(id))
    }, [])

    return (
        <div className='page_responsive'>
            
            <div className="card d-flex">
                <div className="send_quotes_head_container">
                    <h3 style={{color:"#3077F2",fontSize:"25px",fontWeight:"600"}}>Customer Details</h3>
                </div>
            </div>

            <div className="user_details_card">
                <Card className="order_details_card">
                    {loading ? (
                        <Loader />
                    ) : (
                        <Card.Body>
                            <Row className="justify-content-around my-1">
                                <Col md={5} className="m-1 detail_field">
                                    <Row>
                                        <Col md={6}>
                                            <Typography variant="h6">Customer User Name:</Typography>
                                        </Col>
                                        <Col md={6}>
                                            <Typography className='text-end'>{userDetails?.data?.username}</Typography>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={5} className="m-1 detail_field">
                                    <Row>
                                        <Col md={6}>
                                            <Typography variant="h6">Customer First Name:</Typography>
                                        </Col>
                                        <Col md={6}>
                                            <Typography className='text-end'>
                                                {userDetails?.data?.first_name}
                                            </Typography>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="justify-content-around my-1">
                                <Col md={5} className="m-1 detail_field">
                                    <Row>
                                        <Col md={6}>
                                            <Typography variant="h6">Customer Last Name</Typography>
                                        </Col>
                                        <Col md={6}>
                                            <Typography className='text-end'>
                                                {userDetails?.data?.last_name}
                                            </Typography>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={5} className="m-1 detail_field">
                                    <Row>
                                        <Col md={6}>
                                            <Typography variant="h6">Reference:</Typography>
                                        </Col>
                                        <Col md={6}>
                                            <Typography className='text-end'>
                                                {userDetails?.data?.Reference?.name ? userDetails?.data?.Reference?.name : '-'}
                                            </Typography>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="justify-content-around my-1">
                                <Col md={5} className="m-1 detail_field">
                                    <Row>
                                        <Col md={6}>
                                            <Typography variant="h6">Email:</Typography>
                                        </Col>
                                        <Col md={6}>
                                            <Typography className='text-end'>
                                                {userDetails?.data?.email}
                                            </Typography>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={5} className="m-1 detail_field">
                                    <Row>
                                        <Col md={6}>
                                            <Typography variant="h6">Alternative Email:</Typography>
                                        </Col>
                                        <Col md={6}>
                                            <Typography className='text-end'>
                                                {userDetails?.data?.alternative_email ? userDetails?.data?.alternative_email : "-"}
                                            </Typography>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="justify-content-around my-1">
                                <Col md={5} className="m-1 detail_field">
                                    <Row>
                                        <Col md={6}>
                                            <Typography variant="h6">Company Name:</Typography>
                                        </Col>
                                        <Col md={6}>
                                            <Typography className='text-end'> {userDetails?.data?.company_name ? userDetails?.data?.company_name : "-"}</Typography>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={5} className="m-1 detail_field">
                                    <Row>
                                        <Col md={6}>
                                            <Typography variant="h6">Company Type:</Typography>
                                        </Col>
                                        <Col md={6}>
                                            <Typography className='text-end'>{userDetails?.data?.companyType?.name ? userDetails?.data?.companyType?.name : "-"}</Typography>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="justify-content-around my-1">
                                <Col md={5} className="m-1 detail_field">
                                    <Row>
                                        <Col md={6}>
                                            <Typography variant="h6">Phone:</Typography>
                                        </Col>
                                        <Col md={6}>
                                            <Typography className='text-end'>
                                                {userDetails?.data?.phone ? userDetails?.data?.phone : "-"}
                                            </Typography>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={5} className="m-1 detail_field">
                                    <Row>
                                        <Col md={6}>
                                            <Typography variant="h6">Cell:</Typography>
                                        </Col>
                                        <Col md={6}>
                                            <Typography className='text-end'> 
                                                {userDetails?.data?.cell ? userDetails?.data?.cell : "-"}
                                            </Typography>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>


                            <Row className="justify-content-around my-1">
                                <Col md={5} className="m-1 detail_field">
                                    <Row>
                                        <Col md={6}>
                                            <Typography variant="h6">Fax:</Typography>
                                        </Col>
                                        <Col md={6}>
                                            <Typography className='text-end'>
                                                {userDetails?.data?.fax ? userDetails?.data?.fax : "-"}
                                            </Typography>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={5} className="m-1 detail_field">
                                    <Row>
                                        <Col md={6}>
                                            <Typography variant="h6">City:</Typography>
                                        </Col>
                                        <Col md={6}>
                                            <Typography className='text-end'>
                                                {userDetails?.data?.city ? userDetails?.data?.city : "-"}
                                            </Typography>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>


                            <Row className="justify-content-around my-1">
                                <Col md={5} className="m-1 detail_field">
                                    <Row>
                                        <Col md={6}>
                                            <Typography variant="h6">Country:</Typography>
                                        </Col>
                                        <Col md={6}>
                                            <Typography className='text-end'>
                                                {userDetails?.data?.Country?.name ? userDetails?.data?.Country?.name : "-"}
                                            </Typography>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={5} className="m-1 detail_field">
                                    <Row>
                                        <Col md={6}>
                                            <Typography variant="h6">State:</Typography>
                                        </Col>
                                        <Col md={6}>
                                            <Typography className='text-end'>
                                                {userDetails?.data?.state ? userDetails?.data?.state : "-"}
                                            </Typography>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="justify-content-around my-1">
                                <Col md={5} className="m-1 detail_field">
                                    <Row>
                                        <Col md={6}>
                                            <Typography variant="h6">Zip Code:</Typography>
                                        </Col>
                                        <Col md={6}>
                                            <Typography className='text-end'>
                                                {userDetails?.data?.zip_code ? userDetails?.data?.zip_code : "-"}
                                            </Typography>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={5} className="m-1 detail_field">
                                    <Row>
                                        <Col md={6}>
                                            <Typography variant="h6">Postal Code:</Typography>
                                        </Col>
                                        <Col md={6}>
                                            <Typography className='text-end'>
                                                {userDetails?.data?.postal_code ? userDetails?.data?.postal_code : "-"}
                                            </Typography>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="justify-content-around my-1">
                                <Col md={5} className="m-1 detail_field">
                                    <Row>
                                        <Col md={6}>
                                            <Typography variant="h6">Registered Date:</Typography>
                                        </Col>
                                        <Col md={6}>
                                            <Typography className='text-end'>
                                                {userDetails?.data?.registered_date ? moment(userDetails?.data?.registered_date).utc().format("DD-MM-YYYY") : "-"}
                                            </Typography>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={5} className="m-1 detail_field">
                                    <Row>
                                        <Col md={6}>
                                            <Typography variant="h6">Status:</Typography>
                                        </Col>
                                        <Col md={6}>
                                            <Typography className='text-end'>
                                                {userDetails?.data?.status === 1 ? "Active" : "Pending"}
                                            </Typography>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="justify-content-around my-1">
                                <Col md={11} className="m-1 detail_field">
                                    <Row>
                                        <Col md={12}>
                                            <Typography variant="h6">
                                                Address:{" "}
                                            </Typography>
                                            <Typography>
                                                {userDetails?.data?.address ? userDetails?.data?.address : "-"}
                                            </Typography>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="pt-4">
                                <Col xs={12} md={12} className='button_container_main' style={{marginLeft:"-40px"}}>
                                    <Button onClick={() => navigate(-1)}>Back</Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    )}
                </Card>
            </div>
        </div>
    )
}

export default CustomerDetails
