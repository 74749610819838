import React, { useState, useEffect } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../Components/Input/Input";
import { generateInvoice } from '../../../../Utils/interfaces';
import { generateInvoiceValidation } from '../../../../Utils/validation';
import { useForm } from "react-hook-form";
import { AnyAction } from "redux";
import { Link, useNavigate } from "react-router-dom";
import MuiDataTable from "../../../../Components/MUIDataTable/MUIDataTable";
import { getInvoicesList, getUsersByRoles, getGeneratedInvoice } from "../../../../Redux/Actions/admin";
import Loader from "../../../../Utils/Loader";
import './AllInvoices.css';
import Modal from "../../../../Components/SiteModal/SiteModal";
import { errorNotify, successNotify } from "../../../../Utils/toast";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import Button from '../../../../Components/Button/Button'
import eyeIcon from '../../../../Assets/images/eye.png';
import moment from "moment";

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const AllInvoices = () => {
  const { register, handleSubmit, formState: { errors }, } = useForm<generateInvoice>();
  const navigation = useNavigate();
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [customerPage, setCustomerPage] = useState(0)
  const [customerRowsPerPage, setCustomerRowsPerPage] = useState(100);
  const [show, setShow] = useState(false);
  const [text, setText] = useState("");
  const dispatch: AppDispatch = useDispatch();
  const { loading, invoicesList, invoicesCount } = useSelector((state: any) => state?.invoicesList);
  const { users } = useSelector((state: any) => state.allUsersByRole);
  const { loading: generatedLoading } = useSelector((state: any) => state.generateInvoice);
  const [customerID, setCustomerID] = useState('');
  const [showall, setShowAll] = useState(1)

  useEffect(() => {
    dispatch(getInvoicesList(page, rowsPerPage, text));
  }, [page, rowsPerPage, text, dispatch]);

  useEffect(() => {
    dispatch(getUsersByRoles("Customer", customerPage, customerRowsPerPage, '', showall));
  }, [customerPage, customerRowsPerPage, showall])


  const onSubmitHandler = handleSubmit(async (data: any) => {
    if (customerID === '') {
      errorNotify("Please select any customer")
    }
    else {
      dispatch(getGeneratedInvoice(customerID, data.start_date, data.end_date));
      navigation(`/admin/generate-invoice/${customerID}`)
      successNotify("Invoice Generated Successfully!")
      setShow(false)
    }
  });

  const quotesColumns = [
    {
      name: "id",
      options: {
        display: false,
      },
    },
    {
      name: "sno",
      label: "SNO"
    },
    {
      name: "Customer", label: "CUSTOMER NAME",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            `${value?.first_name} ${value?.last_name}`
          );
        },
      },
    },

    {
      name: "Customer", label: "PHONE",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return value?.phone
        }
      },
    },
    {
      name: "createdAt", label: "ISSUE DATE",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          if (value !== null) {
            return moment(new Date(`${value} UTC`)).format("DD-MM-YYYY hh:mm:ss");
          }
        }
      },
    },
    {
      name: "payment_status",
      label: "PAYMENT STATUS",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <div className="status-pending">
              <p className="mb-0"> {parseInt(value) === 0 ? 'payable' : 'paid'}  </p>
            </div>
          );
        },
      },
    },
    {
      name: "Customer",
      label: "DETAIL",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <div className="action_container">
              <Link to={`/admin/invoice-details/${tableMeta.rowData[0]}`}>
                <button
                  className="view-btn"
                  type="button"
                >
                  <img src={eyeIcon} alt="eye" />
                </button>
              </Link>
            </div>
          );
        },
      },
    },

  ];

  let newData: any = [];
  invoicesList?.data?.rows?.map((item: any, index: any) => {
    newData.push({ sno: rowsPerPage * page + index + 1, ...item });
  });

  // Auto Complete Search Handlers
  const formatResult = (item: any) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>{item?.first_name} {item?.last_name}</span>
      </>
    )
  }
  const handleOnSearch = (string: any, results: any) => {
    console.log(string, results)
  }

  const handleOnSelect = (item: any) => {
    // the item selected
    setCustomerID(item?.id)
  }

  const modal = (
    <Modal
      title="Generate Invoice"
      size={"lg"}
      show={show}
      onCloseModal={() => setShow(!show)}
    >
      <React.Fragment>
        <Form onSubmit={onSubmitHandler} className="generate_invoice_form">
          <Row>
            <Col md={6}>
              <Input>
                <Form.Label>Start Date:</Form.Label>
                <Form.Control
                  {...register(
                    "start_date", generateInvoiceValidation.start_date
                  )}
                  type="date"
                  placeholder="Enter start date"
                />
              </Input>
              {errors.start_date ? (
                <small className="text-danger d-flex">
                  {errors.start_date && errors.start_date.message}
                </small>
              ) : null}
            </Col>

            <Col md={6}>
              <Input>
                <Form.Label>End Date:</Form.Label>
                <Form.Control
                  type="date"
                  {...register(
                    "end_date", generateInvoiceValidation.end_date
                  )}
                  placeholder="Enter end date"
                />
              </Input>
              {errors.end_date ? (
                <small className="text-danger d-flex">
                  {errors.end_date && errors.end_date.message}
                </small>
              ) : null}
            </Col>

            <Col md={12}>
              <Input>
                <Form.Label>Select Customer</Form.Label>
                <div id="users_container">
                  <ReactSearchAutocomplete
                    placeholder="Search any customer"
                    onClear={() => setCustomerID('')}
                    items={users?.data?.rows}
                    onSearch={handleOnSearch}
                    fuseOptions={{ keys: ['first_name', 'last_name'] }}
                    autoFocus
                    onSelect={handleOnSelect}
                    resultStringKeyName='first_name'
                    formatResult={formatResult}
                    maxResults={15}
                  />
                </div>
              </Input>
            </Col>

            <Col md={12}>
              <div className='generate_container'>
                <button className='my-1 details_btn bg-gray' id='generate_btn' type='submit'>{generatedLoading ? <Spinner animation='border' size='sm' /> : "Generate"}</button>
              </div>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    </Modal >
  );

  return (
    <div className="page_responsive generate_Invoice_main">
      <Row>

        <Col md={12}>
          <div className="d-flex justify-content-end">
            <Button onClick={() => setShow(!show)}>Generate Invoice</Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="p-2 my-3 all-invoices-table">
          {!text && loading ? (
            <Loader />
          ) : (
            <MuiDataTable
              title={"ALL INVOICES RECORDS"}
              data={newData}
              columns={quotesColumns}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              count={invoicesCount}
              setText={setText}
              text={text}
            />
          )}
        </Col>
      </Row>
      {modal}
    </div>
  );
};

export default AllInvoices;
