// Sales Quotes List And Detail Page Reducer

export const getSalesQuotesListReducer: any = (state = { getSalesQuotes: [] }, action: any) => {
    switch (action.type) {
        case "GET_SALES_QUOTES_LIST_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "GET_SALES_QUOTES_LIST_SUCCESS":
            return {
                ...state,
                loading: false,
                getSalesQuotes: action.payload?.data,
                totalCount: action.payload?.data?.count,
                error: false
            }

        case "GET_SALES_QUOTES_LIST_FAILED":
            return {
                ...state,
                loading: false,
                getSalesQuotes: null,
                totalCount: 0,
                error: action.payload
            }

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

export const getSalesQuotesDetailReducer: any = (state = { getSalesQuoteDetail: [] }, action: any) => {
    switch (action.type) {
        case "GET_SALES_QUOTES_DETAIL_REQUEST":
            return {
                loading: true,
                error: false
            }

        case "GET_SALES_QUOTES_DETAIL_SUCCESS":
            return {
                ...state,
                loading: false,
                getSalesQuoteDetail: action.payload,
                error: false
            }

        case "GET_SALES_QUOTES_DETAIL_FAILED":
            return {
                ...state,
                loading: false,
                getSalesQuoteDetail: null,
                error: action.payload
            }

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// Sales Order List Reducer And Detail Page

export const salesOrderListReducer = (state = [], action: any) => {
    switch (action.type) {
        case "SALES_DIGITIZING_ORDER_LIST_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "SALES_DIGITIZING_ORDER_LIST_SUCCESS":
            return {
                ...state,
                loading: false,
                salesOrderList: action.payload,
                ordersCount: action.payload.data.count
            };
        case "SALES_DIGITIZING_ORDER_LIST_FAILED":
            return {
                ...state,
                loading: false,
                salesOrderList: null,
                error: action.payload,
                ordersCount: 0
            };

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};



export const salesOrderDetailsReducer = (state = {}, action: any) => {
    switch (action.type) {
        case "SALES_DIGITIZING_ORDER_DETAIL_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "SALES_DIGITIZING_ORDER_DETAIL_SUCCESS":
            return {
                ...state,
                loading: false,
                salesOrderDetails: action.payload,
            };
        case "SALES_DIGITIZING_ORDER_DETAIL_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};

// Sales Vector Order List Reducer

export const salesVectorOrderListReducer = (state = [], action: any) => {
    switch (action.type) {
        case "SALES_VECTOR_ORDER_LIST_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "SALES_VECTOR_ORDER_LIST_SUCCESS":
            return {
                ...state,
                loading: false,
                salesVectorOrderList: action.payload,
                vectorsCount: action.payload.data.count,

            };
        case "SALES_VECTOR_ORDER_LIST_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
                vectorsCount: 0
            };
        case "SALES_VECTOR_ORDER_LIST_RESET":
            return {
                ...state,
                salesVectorOrderList: null
            }
        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};


// Sales Vector Order Details

export const salesVectorOrderDetailsReducer = (state = {}, action: any) => {
    switch (action.type) {
        case "SALES_VECTOR_ORDER_DETAIL_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "SALES_VECTOR_ORDER_DETAIL_SUCCESS":
            return {
                ...state,
                loading: false,
                salesVectorDetails: action.payload,
            };
        case "SALES_VECTOR_ORDER_DETAIL_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "SALES_VECTOR_ORDER_DETAIL_RESET":
            return {
                ...state,
                salesVectorDetails: null
            }
        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};


// SALES CUSTOMERS LIST REDUCER

export const getSalesCustomersListReducer: any = (state = { getSalesCustomers: [] }, action: any) => {
    switch (action.type) {
        case "GET_SALES_CUSTOMERS_LIST":
            return {
                loading: true,
                error: false
            }

        case "GET_SALES_CUSTOMERS_SUCCESS":
            return {
                ...state,
                loading: false,
                getSalesCustomers: action.payload?.data,
                totalCount: action.payload?.data?.count,
                error: false
            }

        case "GET_SALES_CUSTOMERS_FAILED":
            return {
                ...state,
                loading: false,
                getSalesCustomers: null,
                totalCount: 0,
                error: action.payload
            }

        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}


// Sales Customer Details

export const salesCustomerDetailsReducer = (state = {}, action: any) => {
    switch (action.type) {
        case "GET_SALES_CUSTOMER_DETAILS_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "GET_SALES_CUSTOMER_DETAILS_SUCCESS":
            return {
                ...state,
                loading: false,
                salesCustomer: action.payload,
            };
        case "GET_SALES_CUSTOMER_DETAILS_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "GET_SALES_CUSTOMER_DETAILS_RESET":
            return {
                ...state,
                salesCustomer: null
            }
        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};


// Sales Dashboard Count Reducer

export const salesDashboard = (state = [], action: any) => {
    switch (action.type) {
        case "SALES_DASHBOARD_DATA_REQUEST":
            return {
                ...state,
                loading: true,
            }
        case "SALES_DASHBOARD_DATA_SUCCESS":
            return {
                ...state,
                loading: false,
                salesDashboard: action.payload,
            }
        case "SALES_DASHBOARD_DATA_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state;
    }
}


// SALES MONTHLY REPORT GENERATION

export const salesMonthlyReportReducer = (state = {}, action: any) => {
    switch (action.type) {
        case "SALES_MONTHY_REPORT_GENERATE_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "SALES_MONTHY_REPORT_GENERATE_SUCCESS":
            return {
                ...state,
                loading: false,
                salesMonthlyReport: action.payload,
            };
        case "SALES_MONTHY_REPORT_GENERATE_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};