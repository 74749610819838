import api from "../../Utils/interceptors";

export const getCustomerInvoicesList = (pageNO: number, perPage: number, text: string) => async (dispatch: any) => {
    try {
        dispatch({
            type: "GET_CUSTOMER_INVOICE_LIST_REQUEST",
        });

        const { data } = await api.get(`/user/invoice/list?page_no=${pageNO + 1}&per_page=${perPage}&term=${text}`);

        dispatch({
            type: "GET_CUSTOMER_INVOICE_LIST_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e: any) {
        dispatch({
            type: "GET_CUSTOMER_INVOICE_LIST_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
}

export const getCustomerInvoiceDetails = (invoice_id: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: "GET_CUSTOMER_INVOICE_DETAIL_REQUEST",
        });

        const { data } = await api.get(`/user/invoice/${invoice_id}`);

        dispatch({
            type: "GET_CUSTOMER_INVOICE_DETAIL_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e: any) {
        dispatch({
            type: "GET_CUSTOMER_INVOICE_DETAIL_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
}