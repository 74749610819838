import React from 'react'
import Cards from "../../Assets/images/cards.png";
import './Footer.css';

const Footer = () => {
  return (
    <div className='footer_section text-center'>
      <img src={Cards} alt='cards' />
      <p> Copyright 2016-2022 @climaxdigitizing.net | Terms & Conditions</p>
    </div>
  )
}

export default Footer