import React from "react";
import OperatorNavbar from "../../Container/MachineOperator/Routes/Navbar";
import { Outlet } from "react-router-dom";
import NotFound from "../../Container/Pages/NotFound/NotFound";

const AdminLayout = () => {
    let userAuth: any = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "") : null;

    return userAuth?.role?.name === "Operator" ? (
        <React.Fragment>
            <OperatorNavbar />
            <Outlet />
        </React.Fragment>
    ) : (
        <NotFound />
    );
};
export default AdminLayout;
