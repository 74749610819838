import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Col, Row, Form, Spinner } from "react-bootstrap";
import { IAssignOrder } from "../../../../../Utils/interfaces";
import { AssingAnOrderValidtion } from "../../../../../Utils/validation";
import { ThunkDispatch } from "redux-thunk";
import Input from "../../../../../Components/Input/Input";
import Button from "../../../../../Components/Button/Button";
import { AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { getUsersByRoles, orderAssigned } from "../../../../../Redux/Actions/admin";
import { errorNotify, successNotify } from "../../../../../Utils/toast";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const AssignOrder = () => {
  const { register, handleSubmit, reset, formState: { errors }, } = useForm<IAssignOrder>();
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate()
  const orderName = location?.state?.name;
  const { users } = useSelector((state: any) => state.allUsersByRole);
  const { loading, assigned, error: assignedError } = useSelector((state: any) => state.assignedOrder);
  const dispatch: AppDispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [digitizerID, setDigitizerID] = useState('');
  const [showall, setShowAll] = useState(1)


  const onSubmitHandler = handleSubmit(async (data: any) => {
    if (digitizerID === '') {
      errorNotify("Please select any digitizer")
    }
    else {
      dispatch(orderAssigned(id, digitizerID));
    }
  });


  useEffect(() => {
    dispatch(getUsersByRoles("Digitizer", page, rowsPerPage, '', showall));
    if (assigned?.status === 1) {
      successNotify("Order Assigned successfully")
      dispatch({ type: "ORDER_ASSIGNED_RESET" })
      reset()
      navigate("/admin/all-orders")
    }
    if (assignedError) {
      errorNotify(assignedError)
      dispatch({ type: "CLEAR_ERRORS" })
    }
  }, [assigned?.status, assignedError, page, rowsPerPage,showall]);


  // Auto Complete Search Handlers

  const formatResult = (item: any) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>{item?.first_name} {item?.last_name}</span>
      </>
    )
  }

  const handleOnSearch = (string: any, results: any) => {
    console.log(string, results)
  }

  const handleOnSelect = (item: any) => {
    // the item selected
    setDigitizerID(item?.id)
  }


  return (
    <div className="page_responsive">
      <div className="create_emp_bg_background">
        <Container>
          <Form onSubmit={onSubmitHandler}>
            <Row>
              <Col md={12}>
                <div className="personal_details_container">
                  <div className="details_head">
                    <h5>Assign An Order</h5>
                  </div>

                  <div className="details_container">
                    <Row>
                      <Col md={12}>
                        <Input>
                          <Form.Label>Order Name:</Form.Label>
                          <Form.Control
                            type="text"
                            value={orderName}
                            disabled
                          />
                        </Input>
                      </Col>
                      <Col md={4}>
                        <Input>
                          <Form.Label>Select Digitizer</Form.Label>
                          <div id="users_container">
                            <ReactSearchAutocomplete
                              onClear={() => setDigitizerID('')}
                              placeholder="Search any digitizer"
                              items={users?.data?.rows}
                              onSearch={handleOnSearch}
                              fuseOptions={{ keys: ['first_name', 'last_name'] }}
                              autoFocus
                              onSelect={handleOnSelect}
                              resultStringKeyName='first_name'
                              formatResult={formatResult}
                              maxResults={15}
                            />
                          </div>
                        </Input>
                      </Col>

                    </Row>
                    <Col md={12}>
                      <div className="d-flex justify-content-end py-3">
                        <Button type="submit">
                          {" "}
                          {loading ? (
                            <Spinner animation="border" size="sm" />
                          ) : (
                            "Send"
                          )}
                        </Button>
                      </div>
                    </Col>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </div>
  );
};

export default AssignOrder;
