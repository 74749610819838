import React, { useEffect } from 'react'
import { Container, Col, Row, Form, Spinner } from "react-bootstrap";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import Input from "../../../../../../Components/Input/Input";
import Button from "../../../../../../Components/Button/Button";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { errorNotify, successNotify } from '../../../../../../Utils/toast';
import { useDispatch, useSelector } from 'react-redux';
import { VecotOrderSentToCustomer } from '../../../../../../Redux/Actions/admin';

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const SendToCustomer = () => {
    const navigate = useNavigate()
    const dispatch: AppDispatch = useDispatch();
    const [price, setPrice] = React.useState<any>(0);
    const { id } = useParams()
    const location = useLocation()

    const { loading, vectorSentToCustomer, error } = useSelector((state: any) => state.VectorOrderSent)
    useEffect(() => {
        if (vectorSentToCustomer?.status === 1) {
            successNotify("Order Sent Successfully!")
            navigate('/admin/all/vector-record')
            dispatch({ type: "VECTOR_ORDER_SENT_TO_CUSTOMER_RESET" })
        }
        if (error) {
            errorNotify(error)
            dispatch({ type: "CLEAR_ERRORS" })
        }
    }, [vectorSentToCustomer?.status, error])


    const onSubmitHandler = (e: any) => {
        e.preventDefault()
        if (!price || !price.trim()) {
            errorNotify("Please fill in the field")
        }

        else {
            const fileData: any = new FormData()
            fileData.append("price", price)
            dispatch(VecotOrderSentToCustomer(id, fileData))
        }
    }
    return (
        <div className='page_responsive'>
            <div className="create_emp_bg_background">
                <Container>
                    <Form onSubmit={onSubmitHandler}>
                        <Row>
                            <Col md={12}>
                                <div className="personal_details_container">
                                    <div className="details_head">
                                        <h5>Upload Vector Attachment</h5>
                                    </div>

                                    <div className="details_container">
                                        <Row>

                                            <Col md={6}>
                                                <Input>
                                                    <Form.Label>Vector Name:</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        disabled
                                                        value={location?.state?.vectorName}
                                                    />
                                                </Input>
                                            </Col>

                                            <Col md={6}>
                                                <Input>
                                                    <Form.Label>Price: $</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder='Enter Price'
                                                        onChange={(e) => setPrice(e.target.value)}
                                                    />
                                                </Input>
                                            </Col>

                                        </Row>
                                        <Col md={12}>
                                            <div className="d-flex justify-content-end py-3">
                                                <Button type="submit">
                                                    {loading ? <Spinner animation="border" size="sm" /> : "Send"}
                                                </Button>
                                            </div>
                                        </Col>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>
        </div>
    )
}

export default SendToCustomer
