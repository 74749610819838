import api from "../../Utils/interceptors";
import { getToken } from "../../Utils/helper";

export const userSendVectorOrder = (orderData: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: "SEND_VECTOR_ORDER_REQUEST",
        });

        const { data } = await api.post("/vector/order", orderData,{
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: getToken()
            }
        });

        dispatch({
            type: "SEND_VECTOR_ORDER_SUCCESS",
            payload: data,
            success: true,
        });
    }
    catch (e: any) {
        dispatch({
            type: "SEND_VECTOR_ORDER_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
};
export const getUserVectorOrdersRecord = (pageNO: number, perPage: number, text: string) => async (dispatch: any) => {
    try {
        dispatch({
            type: "VECTOR_ORDER_LIST_REQUEST"
        })

        const { data } = await api.get(`/vector/list?page_no=${pageNO + 1}&per_page=${perPage}&term=${text}`)

        dispatch({
            type: "VECTOR_ORDER_LIST_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e: any) {
        dispatch({
            type: "VECTOR_ORDER_LIST_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
}

export const getUserVectorOrderDetails = (order_id: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: "VECTOR_ORDER_DETAIL_REQUEST"
        })

        const { data } = await api.get(`/vector/order/${order_id}`)
  
        dispatch({
            type: "VECTOR_ORDER_DETAIL_SUCCESS",
            payload: data,
            success: true,
        });
    }

    catch (e: any) {
        dispatch({
            type: "VECTOR_ORDER_DETAIL_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
}


// / Customer Delete  Vector Orders

export const deleteCustomerVectorOrderRecords = (order_id: string) => async (dispatch: any) => {

    try {
        dispatch({
            type: "CUSTOMER_VECTOR_ORDER_DELETE_REQUEST",
        });

        const { data } = await api.delete(`/vector/${order_id}`);

        dispatch({
            type: "CUSTOMER_VECTOR_ORDER_DELETE_SUCCESS",
            payload: data,
            success: true,
        });
    } catch (e: any) {
        dispatch({
            type: "CUSTOMER_VECTOR_ORDER_DELETE_FAILED",
            payload: e.response.data.message,
            success: false,
        });
    }
};