import React, { useEffect } from "react";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import * as RiIcon from "react-icons/ri";
import * as AiIcon from "react-icons/ai";
import LoginScreen from "../../../Assets/images/new-logo.png";
import { useForm, SubmitHandler } from "react-hook-form";
import { LoginInput } from "../../../Utils/interfaces";
import { authValidation } from "../../../Utils/validation";
import "./Login.css";
import { errorNotify, successNotify } from "../../../Utils/toast";
import Footer from "../../../Components/Footer/Footer";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { Login } from "../../../Redux/Actions/Auth";
import { useNavigate } from "react-router-dom";

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const UserLogin = () => {
  const {register,handleSubmit,reset,formState: { errors }} = useForm<LoginInput>();
  let userAuth: any = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "") : null;
  const { error, loading, user } = useSelector((state: any) => state.Auth);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.message) {
      successNotify(`Welcome ${user?.data?.username}!`);
      reset();
      dispatch({ type: "LOGIN_RESET" });
    }
    if (error) {
      errorNotify(error);
      dispatch({ type: "CLEAR_ERRORS" });
    }

    if (userAuth?.role?.name === "Customer") {
      navigate("/user/dashboard");
    }

    if(userAuth?.role?.name === "Sales"){
      navigate("/sales/dashboard");
    }

    if (userAuth?.role?.name === "Admin") {
      navigate("/admin/dashboard");
    }

    if (userAuth?.role?.name === "Digitizer") {
      navigate("/digitizer/dashboard");
    }

    if (userAuth?.role?.name === "Operator") {
      navigate("/machine-operator/dashboard");
    }

    if (userAuth?.role?.name === "Designer") {
      navigate("/designer/dashboard");
    }

  }, [user, error, userAuth]);

  const loginFormSubmit: SubmitHandler<LoginInput> = async (data: any) => {
    const loggedData = {
      ...data,
    };
    dispatch(Login(loggedData));
  };

  return (
    <div className="main_home">
      <Container className="container_box">
        <Row className="h-100 justify-content-center align-items-center">
          <Col md={12} className="main_right_section">
            <div className="main_left_section">
              <img src={LoginScreen} alt="login_screen" />
            </div>
            <Form
              className={"auth_form"}
              onSubmit={handleSubmit(loginFormSubmit)}
            >
              <Form.Group className={"input_container"}>
                <RiIcon.RiMailSendLine />
                <Form.Control
                  type="text"
                  placeholder={"Enter Username or Email"}
                  className={"email"}
                  {...register("username", authValidation.username)}
                />
                {errors.username && (
                  <span className="error_msg">{errors.username.message}</span>
                )}
              </Form.Group>
              <Form.Group className={"input_container"}>
                <AiIcon.AiOutlineLock />
                <Form.Control
                  type="password"
                  autoComplete="off"
                  placeholder={"Enter Password"}
                  className={"password"}
                  {...register("password", authValidation.password)}
                />
                {errors.password && (
                  <span className="error_msg">{errors.password.message}</span>
                )}
              </Form.Group>
              <div className="forget_password">
                <p>Forget Password?</p>
              </div>

              <div className={"text-center mt-3"}></div>
              <div className="text-center">
                <Button type="submit" className={"mt-3 all_btn"}>
                  {loading ? <Spinner animation="border" size="sm" /> : "Login"}
                </Button>
              </div>
            </Form>
            <p className="text-black" style={{ color: "black" }}>
              Don't have an account{" "}
              <Link to="/user/registration">Sign Up ?</Link>{" "}
            </p>
          </Col>
        </Row>

        <Footer />
      </Container>
    </div>
  );
};

export default UserLogin;
