import React, { useRef, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import MUIDataTable, { FilterType } from "mui-datatables";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import Logo from "../../../../../Assets/images/new-logo.png";
import { invoiceDetails } from "../../../../../Redux/Actions/admin";
import ReactToPrint from "react-to-print";
import { useParams } from "react-router-dom";
import Loader from "../../../../../Utils/Loader";
import moment from "moment";
import phoneIcon from '../../../../../Assets/images/phone.png'
import mailIcon from '../../../../../Assets/images/mail-white.png'
import worldIcon from '../../../../../Assets/images/world.png'
import downloadInvoiceIcon from '../../../../../Assets/images/download-icon.png'

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const InvoiceDetails = () => {
  const componentRef = useRef<any>();
  const dispatch: AppDispatch = useDispatch();
  const { loading, invoiceDetail } = useSelector((state: any) => state.invoiceDetails);
  const { id } = useParams();
  const [editAble, setEditAble] = React.useState(false);
  const [editablePrice, setEditablePrice] = React.useState<any>(0)
  const [editableTotal,setEditableTotal] = React.useState<any>('')

  useEffect(() => {
    dispatch(invoiceDetails(id));
  }, []);

 

  let invoiceData: any = [];

  invoiceDetail?.data?.items?.map((item: any, index: any) => {
    invoiceData.push({
      id: item?.id,
      order_id: item?.Digitizing
        ? "Digitizing: " + item?.Digitizing?.id
        : item?.Vector
          ? "Vector: " + item?.Vector?.id
          : undefined,
      createdAt: item?.Digitizing
        ? item?.Digitizing?.createdAt
        : item?.Vector
          ? item?.Vector?.createdAt
          : undefined,
      order_name: item?.Digitizing
        ? item?.Digitizing?.order_name
        : item?.Vector
          ? item?.Vector?.vector_name
          : undefined,
      price: item?.Digitizing
        ? item?.Digitizing?.price
        : item?.Vector
          ? item?.Vector?.price
          : undefined,
    });
  });

  let getTotal = (invoiceData: any) => {
    let total = 0;
    for (let amount of invoiceData) {
      total += parseInt(amount.price);
    }
    if(total){
      return 
    }
    setEditablePrice(total)
  }

  let total = getTotal(invoiceData);

  const dataHandle = (id: any, event: any) => {
    setEditablePrice(0)
    const newRows = [...invoiceData];
    const index = newRows.findIndex(row => row.order_id === id);
    newRows[index].price = (event.target.value)?event.target.value:0;
    invoiceData = newRows;
    const editableTotal = getTotal(invoiceData);
    // document.getElementById("totalAmount").innerHTML =  total;
    // document.getElementById("subAmount").innerHTML = total;
    // for (let amount of invoiceData) {
    //   setEditablePrice((prev: any) => { return parseInt(prev) + parseInt(amount.price) });
    // }
  };



  const invoiceRecordsColumns = [
    {
      name: "order_id",
      label: "ORDER ID",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          if (value !== null) {
            return value;
          }
        },
      },
    },
    {
      name: "createdAt",
      label: "Order Date",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          if (value !== null) {
            return (
              moment(value).utc().format("DD-MM-YYYY hh:mm:ss")
            )
          }
        },
      },
    },
    {
      name: "order_name",
      label: "ORDER NAME",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          if (value !== null) {
            return value;
          }
        },
      },
    },

    {
      name: "price",
      label: "PRICE",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {

          if (value !== null) {
            return (
              <React.Fragment>
                {
                  editAble ?
                    <div>
                      <div className="editable_div">
                        <p>{invoiceDetail?.data?.Customer?.Country?.currency_symbol}</p>
                        <input defaultValue={value} onChange={(event) => dataHandle(tableMeta?.rowData[0], event)} type={"number"} />
                      </div>
                    </div>

                    :
                    <div>
                      {invoiceDetail?.data?.Customer?.Country?.currency_symbol +
                        " " +
                        value}
                    </div>
                }
              </React.Fragment>
            );
          }
        },
      },
    },
  ];
  const option: FilterType | any = {
    filter: false,
    filterType: "checkbox",
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    responsive: "standard",
    selectableRows: "none",
    pagination: false,
  };

  return (
    <div className="page_responsive ">
      <Container>
        {loading ? (
          <Loader />
        ) : (
          <Row>
            <Col md={12} className="invoice_main">
              <div className="print_btn_container">
                <ReactToPrint
                  trigger={() => (
                    <img src={downloadInvoiceIcon} style={{ cursor: "pointer" }} alt='download' />
                  )}
                  content={() => componentRef.current}
                />

                <div className="edit_price_container">
                  <button onClick={() => setEditAble(true)}>Edit Price</button>
                </div>
              </div>
              <div className="card invoice_logo_container" ref={componentRef}>
                <img src={Logo} alt="invoice-logo" />
                <div className="invoice_no_container">
                  <div>
                    <h3>Invoice</h3>
                    <p>Invoice No: {invoiceDetail?.data?.invoice_no}</p>
                  </div>
                </div>

                <div className="invoice_no_container">
                  <div>
                    <h3>{invoiceDetail?.data?.Customer.username}</h3>
                    <p>Email: {invoiceDetail?.data?.Customer.email}</p>
                    <p>Phone: {invoiceDetail?.data?.Customer.phone}</p>
                    <p>
                      Address:{" "}
                      {invoiceDetail?.data?.Customer.address
                        ? invoiceDetail?.data?.Customer.address
                        : "-"}
                    </p>
                    <p>
                      Company:{" "}
                      {invoiceDetail?.data?.Customer.company
                        ? invoiceDetail?.data?.Customer.company
                        : "-"}
                    </p>
                  </div>
                  <div className="print_container">
                    <p>
                      Issue Date:{" "}
                      {moment(new Date(`${invoiceDetail?.data?.createdAt} UTC`)).format("DD-MM-YYYY hh:mm:ss")}
                    </p>
                  </div>
                </div>
                <Row className="justify-content-center">
                  <Col md={12} className='mb-4'>
                    <div className="quotes-table">
                      <MUIDataTable
                        title={""}
                        data={invoiceData}
                        columns={invoiceRecordsColumns}
                        options={option}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="justify-content-end">
                  <Col md={4}>
                    <div className="total_table mb-3">
                      <div className="cards">
                        <div className="total_head">
                          <div>
                            <h5>SUBTOTAL</h5>
                          </div>
                          <div>
                            <p id="subAmount">

                              {invoiceDetail?.data?.Customer.Country
                                .currency_symbol
                              }
                              {editableTotal === 0 ? total : editableTotal}

                            </p>
                          </div>
                        </div>

                        <div className="card-body">
                          <div>
                            <h5>TOTAL</h5>
                          </div>
                          <div>
                            <p id="totalAmount">
                              {" "}
                              {

                                invoiceDetail?.data?.Customer.Country
                                  .currency_symbol

                              }
                              {editableTotal === 0 ? total : editableTotal}
                            </p>
                          </div>
                        </div>

                      </div>
                    </div>
                  </Col>
                </Row>

                <Row className="justify-content-center">
                  <Col md={12}>
                    <div className="mail_container">
                      <h4>Thanks for your business with Climax Digitizing!</h4>
                      <p className="query_text">
                        If you have any questions about this invoice, do not
                        hesitate to contact us.
                      </p>

                      <div className="container_main">
                        <div className="phone_mail_container_main">
                          <div className="tel_container">
                            <img src={phoneIcon} alt='phone' />
                          </div>
                          <p> +1 602 635 1843</p>
                        </div>

                        <div className="phone_mail_container_main">
                          <div className="Mail_Container">
                            <img src={mailIcon} alt='mail' />
                          </div>
                          <a href="mailto:accounts@climaxdigitizing.net">
                            accounts@climaxdigitizing.net
                          </a>
                        </div>

                        <div className="phone_mail_container_main">
                          <div className="world_container">
                            <img src={worldIcon} alt='world' />
                          </div>
                          <p>www.climaxdigitizing.net</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default InvoiceDetails;
