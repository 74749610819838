import React from "react";
import Navbar from "../../Container/Designer/Routes/Navbar";
import { Outlet } from "react-router-dom";
import NotFound from "../../Container/Pages/NotFound/NotFound";

const VectorOperatorLayout = () => {
    let userAuth: any = localStorage.getItem("user")? JSON.parse(localStorage.getItem("user") || ""): null;
    return userAuth?.role?.name === "Designer" ? (
        <React.Fragment>
            <Navbar />
            <Outlet />
        </React.Fragment>
    ) : (
        <NotFound />
    );
}
export default VectorOperatorLayout;
