// Designer Dashboard


export const designerDashboardCountReducer = (state = [], action: any) => {
    switch (action.type) {
        case "DESIGNER_DASHBOARD_COUNT_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "DESIGNER_DASHBOARD_COUNT_SUCCESS":
            return {
                ...state,
                loading: false,
                designerDashboardCounts: action.payload,
            };
        case "DESIGNER_DASHBOARD_COUNT_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};


export const designerAllOrdersListReducer = (state = [], action: any) => {
    switch (action.type) {
        case "DESIGNER_ALL_ORDERS_LIST_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "DESIGNER_ALL_ORDERS_LIST_SUCCESS":
            return {
                ...state,
                loading: false,
                designerAllOrders: action.payload,
                designerOrderCount: action.payload.data.count
            };
        case "DESIGNER_ALL_ORDERS_LIST_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
                designerOrderCount: 0
            };
        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};


export const designerOrderDetailsReducer = (state = {}, action: any) => {
    switch (action.type) {
        case "DESIGNER_ORDER_DETAIL_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "DESIGNER_ORDER_DETAIL_SUCCESS":
            return {
                ...state,
                loading: false,
                designerOrderDetails: action.payload,
            };
        case "DESIGNER_ORDER_DETAIL_FAILED":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "DESIGNER_ORDER_DETAIL_RESET":
            return {
                ...state,
                designerOrderDetails: null
            }
        case "CLEAR_ERRORS":
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};