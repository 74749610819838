import React from 'react'
import { Container, Col, Row } from 'react-bootstrap';
import './Dashboard.css'
import quoteRecords from '../../../../Assets/images/quoteRecord_updated.png';
import digitizingRecords from '../../../../Assets/images/digitizing record.png';
import vectorRecords from '../../../../Assets/images/vectors record.png';
import human from '../../../../Assets/images/profile_updated.png';
import logOut from '../../../../Assets/images/logout_updated.png';
import QuoteIcon from "../../../../Assets/images/send-quote-New.png";
import OrderIcon from "../../../../Assets/images/digitizingorder_updated.png";
import InvoicesIcon from "../../../../Assets/images/Allinvoices_updated.png";
import VectorIcon from "../../../../Assets/images/vectors order.png";
import dashbordIcon from '../../../../Assets/images/dashboard-icon.png';
import { useNavigate } from 'react-router-dom';
import { successNotify } from '../../../../Utils/toast';

const Dashboard = () => {
    const navigate = useNavigate();
    const switchRoutes = (path: string) => {
        navigate(path)
    }

    const LogoutHandler = () => {
        localStorage.clear()
        navigate('/')
        successNotify("Logged Out Successfully!")
    }

    const date = new Date()

    return (
        <div className='page_responsive'>
            <div className="dashboard_div_main card">
                <div className="dashboard_container">
                    <img src={dashbordIcon} alt='dashboaord' />
                    <h4>Dashboard</h4>
                </div>
            </div>
            <Container fluid className='card_container'>
                <Row>
                    <Col md={4}>
                        <div className='card' id='card1' onClick={() => switchRoutes('/user/send-quote')}>
                            <Row className='justify-content-center align-items-center'>
                                <Col>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h4 className="card_data">SEND QUOTES</h4>
                                        <div className='send_quote_div'>
                                            <img src={QuoteIcon} alt="pencil-logo" />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col md={4}>
                        <div className='card' id='card2' onClick={() => switchRoutes('/user/customer-order-form')}>
                            <Row className='justify-content-center align-items-center'>
                                <Col>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h4 className="card_data">DIGITIZING ORDER</h4>
                                        <div className='digitizing_order_div'>
                                            <img src={OrderIcon} alt="pencil-logo" />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>


                    <Col md={4}>
                        <div className='card' id='card3' onClick={() => switchRoutes('/user/vector-form')}  >
                            <Row className='justify-content-center align-items-center'>
                                <Col>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h4 className="card_data">VECTOR ORDER</h4>
                                        <div className='vector_order_div'>
                                            <img src={VectorIcon} alt="pencil-logo" />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col md={4}>
                        <div className='card' id='card4' onClick={() => switchRoutes('/user/quote-all-record')}>
                            <Row className='justify-content-between align-items-center'>
                                <Col>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h4 className="card_data">QUOTE RECORDS</h4>
                                        <div className='quotes_record_div'>
                                            <img src={quoteRecords} alt="pencil-logo" />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col md={4}>
                        <div className='card' id='card5' onClick={() => switchRoutes('/user/all-orders')}>
                            <Row className='justify-content-center align-items-center'>
                                <Col>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h4 className="card_data">DIGITIZING RECORDS</h4>
                                        <div className='digitizing_records_div'>
                                            <img src={digitizingRecords} alt="pencil-logo" />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col md={4}>
                        <div className='card' id='card6' onClick={() => switchRoutes('/user/all-vector-record')}>
                            <Row className='justify-content-center align-items-center'>
                                <Col>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h4 className="card_data">VECTOR RECORDS</h4>
                                        <div className='vector_records_div'>
                                            <img src={vectorRecords} alt="pencil-logo" />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col md={4}>
                        <div className='card' id='card7' onClick={() => switchRoutes('/user/all-invoices-record')}>
                            <Row className='justify-content-center align-items-center'>
                                <Col>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h4 className="card_data">ALL INVOICES</h4>
                                        <div className='invoices_icon_div'>
                                            <img src={InvoicesIcon} alt="pencil-logo" />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col md={4}>
                        <div className='card' id='card8' onClick={() => switchRoutes('/user/my-profile')}>
                            <Row className='justify-content-center align-items-center'>
                                <Col>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h4 className="card_data mx-4">MY PROFILE</h4>
                                        <div className='my_profile_div'>
                                            <img src={human} alt="pencil-logo" />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col md={4}>
                        <div className='card' id='card9' onClick={() => LogoutHandler()}>
                            <Row className='justify-content-center align-items-center'>
                                <Col>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h4 className="card_data mx-4">LOGOUT</h4>
                                        <div className='logout_div'>
                                            <img src={logOut} alt="pencil-logo" />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Dashboard
