import { saveAs } from "file-saver";

// Download Files Cloud Base URL 
export const cloudBaseURL = 'https://api.climaxdigitizing.net/assets/uploads/'

export function getToken() {
    let userAuth: any = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '') : null
    return userAuth?.accessToken;
}

export function getRefreshToken() {
    let userAuth: any = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '') : null
    return userAuth?.refreshToken
};


export function getTokenFormat() {
    const token = getToken();
    if (token) {
        return { headers: { Authorization: `Bearer ${token}` } };
    }
}

export function updateLocalAccessToken(token: any) {
    let userAuth: any = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '') : null
    userAuth.accessToken = token;
    localStorage.setItem("user", JSON.stringify(userAuth));
}

export function CurrentUserInfo() {
    let userAuth: any = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '') : null
    return userAuth
}

export const downloadFile = (url: string, name: string) => {
    saveAs(url, name)
}