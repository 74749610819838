import React from "react";
import AdminNavbar from "../../Container/Admin/Routes/Navbar";
import { Outlet } from "react-router-dom";
import NotFound from "../../Container/Pages/NotFound/NotFound";

const AdminLayout = () => {
  let userAuth: any = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "") : null;

  return userAuth?.role?.name === "Admin" ? (
    <React.Fragment>
      <AdminNavbar />
      <Outlet />
    </React.Fragment>
  ) : (
    <NotFound />
  );
};
export default AdminLayout;
