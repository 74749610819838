import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Col, Row, Form, Spinner } from "react-bootstrap";
import { ThunkDispatch } from "redux-thunk";
import Input from "../../../../../Components/Input/Input";
import Button from "../../../../../Components/Button/Button";
import { AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { IUserProfile } from "../../../../../Utils/interfaces";
import { useForm } from "react-hook-form";
import { getSalesCustomerDetails } from "../../../../../Redux/Actions/Sales";
import Loader from "../../../../../Utils/Loader";
import moment from "moment";

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const CustomerDetails = () => {
    const dispatch: AppDispatch = useDispatch();
    const { register, setValue } = useForm<IUserProfile>();
    const navigation = useNavigate();
    const { id } = useParams();
    const { loading, salesCustomer } = useSelector((state: any) => state.salesCustomerDetails);

    useEffect(() => {
        dispatch(getSalesCustomerDetails(id));
    }, [dispatch]);

    setValue('username', salesCustomer?.data?.username)
    setValue('email', salesCustomer?.data?.email)
    setValue('alternative_email', salesCustomer?.data?.alternative_email ? salesCustomer?.data?.alternative_email : "-")
    setValue('registered_date', moment(salesCustomer?.data?.registered_date).format("DD-MM-YYYY hh:mm:ss"))
    setValue('first_name', salesCustomer?.data?.first_name)
    setValue('last_name', salesCustomer?.data?.last_name)
    setValue('company_name', salesCustomer?.data?.company_name ? salesCustomer?.data?.company_name : '-')
    setValue('companyType', salesCustomer?.data?.companyType?.name ? salesCustomer?.data?.companyType?.name : '-')
    setValue('phone', salesCustomer?.data?.phone ? salesCustomer?.data?.phone : '-')
    setValue('cell', salesCustomer?.data?.cell ? salesCustomer?.data?.cell : '-')
    setValue('fax', salesCustomer?.data?.fax ? salesCustomer?.data?.fax : '-')
    setValue('address', salesCustomer?.data?.address ? salesCustomer?.data?.address : '-')
    setValue('city', salesCustomer?.data?.city ? salesCustomer?.data?.city : '-')
    setValue('Country', salesCustomer?.data?.Country.id === 256 ? "United States" : 'United Kingdom')
    setValue('state', salesCustomer?.data?.state ? salesCustomer?.data?.state : '-')
    setValue('zip_code', salesCustomer?.data?.zip_code ? salesCustomer?.data?.zip_code : '-')
    setValue('postal_code', salesCustomer?.data?.state ? salesCustomer?.data?.postal_code : '-')
    setValue('Reference', salesCustomer?.data?.Reference?.name ? salesCustomer?.data?.Reference.name : '-')
    setValue("userStatus", salesCustomer?.data?.status === 1 ? "Active" : "pending");

    return (
        <div className="page_responsive">
            <div className="create_emp_bg_background">
                <Container>
                    <Row>
                        <Col md={12}>
                            {loading ? <Loader /> :
                                <div className="personal_details_container">

                                    <div className="details_head">
                                        <h5>Customer Details</h5>
                                    </div>

                                    <div className="details_container">
                                        <Row>
                                            <Col md={6}>
                                                <Input>
                                                    <Form.Label>User Name:</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        disabled
                                                        {...register("username")}
                                                    />
                                                </Input>
                                            </Col>
                                            <Col md={6}>
                                                <Input>
                                                    <Form.Label>Email:</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        disabled
                                                        {...register("email")}
                                                    />
                                                </Input>
                                            </Col>

                                            <Col md={4}>
                                                <Input>
                                                    <Form.Label>Alternative Email:</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        disabled
                                                        {...register("alternative_email")}
                                                    />
                                                </Input>
                                            </Col>

                                            <Col md={4}>
                                                <Input>
                                                    <Form.Label>Registered Date:</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        disabled
                                                        {...register("registered_date")}
                                                    />
                                                </Input>
                                            </Col>

                                            <Col md={4}>
                                                <Input>
                                                    <Form.Label>First Name:</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        disabled
                                                        {...register("first_name")}
                                                    />
                                                </Input>
                                            </Col>

                                            <Col md={4}>
                                                <Input>
                                                    <Form.Label>Last Name:</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        disabled
                                                        {...register("last_name")}
                                                    />
                                                </Input>
                                            </Col>

                                            <Col md={4}>
                                                <Input>
                                                    <Form.Label>Company Name:</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        disabled
                                                        {...register("company_name")}
                                                    />
                                                </Input>
                                            </Col>

                                            <Col md={4}>
                                                <Input>
                                                    <Form.Label>Company Type:</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        disabled
                                                        {...register("companyType")}
                                                    />
                                                </Input>
                                            </Col>

                                            <Col md={4}>
                                                <Input>
                                                    <Form.Label>Phone No:</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        disabled
                                                        {...register("phone")}
                                                    />
                                                </Input>
                                            </Col>


                                            <Col md={4}>
                                                <Input>
                                                    <Form.Label>Cell:</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        disabled
                                                        {...register("cell")}
                                                    />
                                                </Input>
                                            </Col>

                                            <Col md={4}>
                                                <Input>
                                                    <Form.Label>Fax:</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        disabled
                                                        {...register("fax")}
                                                    />
                                                </Input>
                                            </Col>

                                            <Col md={8}>
                                                <Input>
                                                    <Form.Label>Address:</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        disabled
                                                        {...register("address")}
                                                    />
                                                </Input>
                                            </Col>

                                            <Col md={4}>
                                                <Input>
                                                    <Form.Label>City:</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        disabled
                                                        {...register("city")}
                                                    />
                                                </Input>
                                            </Col>

                                            <Col md={4}>
                                                <Input>
                                                    <Form.Label>State:</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        disabled
                                                        {...register("state")}
                                                    />
                                                </Input>
                                            </Col>

                                            <Col md={4}>
                                                <Input>
                                                    <Form.Label>Country:</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        disabled
                                                        {...register("Country")}
                                                    />
                                                </Input>
                                            </Col>

                                            <Col md={4}>
                                                <Input>
                                                    <Form.Label>Zip Code:</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        disabled
                                                        {...register("zip_code")}
                                                    />
                                                </Input>
                                            </Col>

                                            <Col md={4}>
                                                <Input>
                                                    <Form.Label>Postal Code:</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        disabled
                                                        {...register("postal_code")}
                                                    />
                                                </Input>
                                            </Col>

                                            <Col md={4}>
                                                <Input>
                                                    <Form.Label>Reference:</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        disabled
                                                        {...register("Reference")}
                                                    />
                                                </Input>
                                            </Col>

                                            <Col md={4}>
                                                <Input>
                                                    <Form.Label>Status</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        id={salesCustomer?.data?.status === 1 ? "Status" : "Pending_Status"}
                                                        {...register("userStatus")}
                                                        disabled={true}
                                                    />

                                                </Input>
                                            </Col>

                                        </Row>
                                        <Col md={12}>
                                            <div className="py-3">
                                                <Button onClick={() => navigation("/sales/customers-list")}>
                                                    Back
                                                </Button>
                                            </div>
                                        </Col>
                                    </div>
                                </div>
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default CustomerDetails;
