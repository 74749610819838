import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { Link, useNavigate } from "react-router-dom";
import { getSalesCustomerRecords } from '../../../../Redux/Actions/Sales';
import Loader from "../../../../Utils/Loader";
import MuiDataTable from "../../../../Components/MUIDataTable/MUIDataTable";
import { Col, Row } from "react-bootstrap";
import eyeIcon from '../../../../Assets/images/eye.png';

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const CustomersList = () => {
    const { getSalesCustomers, loading, totalCount } = useSelector((state: any) => state.getSalesCustomersList);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const dispatch: AppDispatch = useDispatch();
    const navigation = useNavigate()
    const [text, setText] = useState('');
    const today = ''

    useEffect(() => {
        dispatch(getSalesCustomerRecords(page, rowsPerPage, text, today));
    }, [page, rowsPerPage, text, today, dispatch]);

    const quotesColumns = [
        {
            name: "id",
            options: {
                display: false
            }

        },
        { name: "sno", label: "S:NO" },
        {
            name: "Role",
            label: "ROLE",
            options: {
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return value?.name;
                },
            },
        },

        { name: "first_name", label: "FIRST NAME" },
        { name: "last_name", label: "LAST NAME" },
        {
            name: "status", label: "STATUS",
            options: {
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    if (parseInt(value) === 1) {
                        return (
                            <div className="status_completed">
                                <p className="mb-0">Active</p>
                            </div>
                        )
                    }
                    else {
                        return (
                            <div className="status-pending">
                                <p className="mb-0">Pending</p>
                            </div>
                        )
                    }

                },
            },
        },
        {
            name: "ACTION",
            label: "ACTION",
            options: {
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (
                        <React.Fragment>
                            <div className="action_container">
                                <Link to={`/sales/customer-details/${tableMeta.rowData[0]}`}>
                                    <button
                                        className="view-btn"
                                        type="button"
                                    >
                                        <img src={eyeIcon} alt='edit' />
                                    </button>
                                </Link>
                            </div>
                        </React.Fragment>
                    );
                },
            },
        },
    ];

    let newData: any = [];
    getSalesCustomers?.rows?.map((item: any, index: any) => {
        newData.push({ sno: rowsPerPage * page + index + 1, ...item });
    });

    return (
        <div className='page_responsive'>
            <Row>
                <Col md={12} className="p-2 my-3 all-customers-table ">
                    {!text && loading ? <Loader /> :
                        <MuiDataTable
                            title={"ALL CUSTOMERS"}
                            data={newData}
                            columns={quotesColumns}
                            page={page}
                            setPage={setPage}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            count={totalCount}
                            setText={setText}
                            text={text}
                        />
                    }

                </Col>
            </Row>
        </div>
    )
}

export default CustomersList
