import React, { useEffect, useState } from 'react';
import MainLayout from './Layouts/MainLayout/Layout';
import AdminLayout from './Layouts/AdminLayout/AdminLayout';
import CustomerLayout from './Layouts/CustomerLayout/CustomerLayout';
import DigitizerLayout from './Layouts/DigitizerLayout/DigitizerLayout';
import SalesLayout from './Layouts/SalesLayout/SalesLayout';
import MachineOperatorLayout from './Layouts/MachineOperatorLayout/MachineOperatorLayout';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom"
import { Slide, ToastContainer } from "react-toastify";
import { userRoutes, adminRoutes } from './Container/Admin/Routes/Routes';
import { customerRoutes } from './Container/Customer/Routes/Routes';
import { digitizerRoutes } from './Container/Digitizer/Routes/Routes';
import { salesRoutes } from './Container/Sales/Routes/Routes';
import { operatorRoutes } from './Container/MachineOperator/Routes/Routes';
import NotFound from './Container/Pages/NotFound/NotFound';
import { errorNotify } from './Utils/toast';
import VectorOperatorLayout from './Layouts/VectorOperatorLayout/VectorOperatorLayout';
import { vectorOperatorRoutes } from './Container/Designer/Routes/Routes';

const App = () => {

  // Online state
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  useEffect(() => {
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };
    window.addEventListener('online', handleStatusChange);
    window.addEventListener('offline', handleStatusChange);
    if(!isOnline){
      errorNotify("Oops :( You are Offline!")
    }
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [isOnline]);

  const userLayout = (
    <Route path={"/"} element={<MainLayout />}>
      {userRoutes.map((item, index) => (
        <Route key={index} path={item.path} element={item.component} />
      ))}
    </Route>
  );

  const adminLayout = (
    <Route path={"/admin"} element={<AdminLayout />}>
      {adminRoutes.map((item, index) => (
        <Route key={index} path={item.path} element={item.component} />
      ))}
    </Route>
  )

  const customerLayout = (
    <Route path={"/user"} element={<CustomerLayout />}>
      {customerRoutes.map((item, index) => (
        <Route key={index} path={item.path} element={item.component} />
      ))}
    </Route>
  )

  const digitizerLayout = (
    <Route path={"/digitizer"} element={<DigitizerLayout />}>
      {digitizerRoutes.map((item, index) => (
        <Route key={index} path={item.path} element={item.component} />
      ))}
      <Route path="*" element={<NotFound />} />
    </Route>
  )

  const salesLayout = (
    <Route path={"/sales"} element={<SalesLayout />}>
      {salesRoutes.map((item, index) => (
        <Route key={index} path={item.path} element={item.component} />
      ))}
    </Route>
  )

  const operatorLayout = (
    <Route path={"/machine-operator"} element={<MachineOperatorLayout />}>
      {operatorRoutes.map((item, index) => (
        <Route key={index} path={item.path} element={item.component} />
      ))}
    </Route>
  )

  const vectoroperatorLayout = (
    <Route path={"/designer"} element={<VectorOperatorLayout />}>
      {vectorOperatorRoutes.map((item, index) => (
        <Route key={index} path={item.path} element={item.component} />
      ))}
    </Route>
  )

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        transition={Slide}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Router>
        <Routes>
          {userLayout}
          {adminLayout}
          {customerLayout}
          {digitizerLayout}
          {salesLayout}
          {operatorLayout}
          {vectoroperatorLayout}
        </Routes>
      </Router>
    </React.Fragment>
  )
}
export default App
