import React from 'react';
import { Modal } from "react-bootstrap";
import "./SiteModal.css";
import { IoMdCloseCircleOutline } from "react-icons/io";


interface ISiteModal {
    title: string,
    show: boolean,
    onCloseModal: () => void;
    children?: JSX.Element,
    size?: "sm"  | "lg" | "xl" | undefined,
}

const SiteModal: React.FC<ISiteModal> = ({ title, show, size, onCloseModal, children }) => {
    return (
        <Modal show={show} size={size} backdrop="static" keyboard={false} className={"site_modal"}>
            <Modal.Header>
                <div className={'review_header'}>
                    <h4>{title}</h4>
                    <IoMdCloseCircleOutline onClick={onCloseModal} />
                </div>
            </Modal.Header>
            <Modal.Body>{children}</Modal.Body>
        </Modal>
    );
};
export default SiteModal;