import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import MuiDataTable from "../../../../Components/MUIDataTable/MUIDataTable";
import { getAdminQuotesRecords, deleteAdminQuotesRecords } from "../../../../Redux/Actions/admin";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import Loader from "../../../../Utils/Loader";
import moment from "moment";
import { successNotify } from "../../../../Utils/toast";
import Modal from '../../../../Components/SiteModal/SiteModal';
import QuoteIcon from "../../../../Assets/images/quote.png";
import deleteIcon from '../../../../Assets/images/delete-icon.png';
import eyeIcon from '../../../../Assets/images/eye.png';

type State = { a: any };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const AllQuotes: React.FC<any> = ({ style, today = '' }) => {
  const { loading, quotes, quotesCount } = useSelector((state: any) => state.adminAllQuotes);
  const { deletequotes, loading: deletedLoading } = useSelector((state: any) => state.adminDeleteQuotes);

  const dispatch: AppDispatch = useDispatch();
  const navigation = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [text, setText] = useState('');
  const [show, setShow] = useState(false);
  const [quoteID, setQuoteID] = useState('');

  useEffect(() => {
    dispatch(getAdminQuotesRecords(page, rowsPerPage, text, today));
  }, [page, rowsPerPage, text]);

  useEffect(() => {
    if (deletequotes && deletequotes?.status === 1) {
      successNotify('Quote Deleted Successfully')
      setShow(false)
      dispatch({ type: "QUOTES_DELETE_RESET" })
      dispatch(getAdminQuotesRecords(page, rowsPerPage, text, today));
    }

  }, [deletequotes])// eslint-disable-next-line


  const QuoteIdHandler = (id: string) => {
    setQuoteID(id)
    setShow(!show)
  }

  const modal = (
    <Modal title="Delete" size={'lg'} show={show} onCloseModal={() => setShow(!show)}>
      <React.Fragment>
        <p>Are u sure you want to delete this quote?</p>
        <div className="modal_decision">
          <button className="my-1 details_btn bg-red" onClick={() => dispatch(deleteAdminQuotesRecords(quoteID))}>
            {deletedLoading ? <Spinner animation='border' size='sm' /> : "Yes"}
          </button>
          <button className="my-1 details_btn sent_order" onClick={() => setShow(false)}>No</button>
        </div>
      </React.Fragment>
    </Modal>
  )

  const quotesColumns = [
    { name: "sno", label: "S:NO" },
    { name: "quote_no", label: "QUOTE NO" },
    { name: "quote_name", label: "QUOTE NAME" },
    {
      name: "Customer",
      options: {
        display: false
      },
    },
    {
      name: "send_date",
      label: "SEND DATE",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return moment(new Date(`${value} UTC`)).format("DD-MM-YYYY hh:mm:ss");
        },
      },
    },
    {
      name: "price", label: "PRICE",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <span>{tableMeta.rowData[3]?.Country?.currency_symbol}{value}</span>
          )
        }
      },
    },
    {
      name: "status",
      label: "STATUS",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {

          return (
            <React.Fragment>
              {parseInt(value) === 0 ? (
                <div className="status-pending">
                  <p className="mb-0">pending</p>
                </div>
              ) : (
                <div className="status_completed">
                  <p className="mb-0">completed</p>
                </div>
              )}
            </React.Fragment>
          );
        },
      },
    },
    {
      name: "Quote Details",
      label: "DETAILS",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {

          return (
            <div className="action_container">
              <Link to={`/admin/quote-details/${tableMeta?.rowData[1]}`}>
                <button
                  className="view-btn"
                  type="button"
                >
                  <img src={eyeIcon} alt="eye" />
                </button>
              </Link>

              <button
                className="delete-btn"
                type="button"
                onClick={() => QuoteIdHandler(tableMeta?.rowData[1])}
              >
                <img src={deleteIcon} alt="eye" />
              </button>
            </div>
          );
        },
      },
    },
  ];

  let newData: any = [];
  quotes?.data?.rows?.map((item: any, index: any) => {
    newData.push({ sno: rowsPerPage * page + index + 1, ...item });
  });

  return (
    <div className={`${style ? style : "page_responsive"}`}>
      <div className="card ">
        <div className="send_quotes_head_container">
          <div className='send_quote_div_shorter'>
            <img src={QuoteIcon} alt="pencil-logo" />
          </div>
          <h4>{today !== "" ? "Today's All Quotes" : "All Quotes"}</h4>
        </div>
      </div>
      <Row>
        <Col md={12} className="p-2 my-3 quotes-table">
          {!text && loading ? <Loader /> :
            <MuiDataTable
              title={today !== "" ? "Today's All Quotes" : "All Quotes"}
              data={newData}
              columns={quotesColumns}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              count={quotesCount}
              setText={setText}
              text={text}
            />
          }
        </Col>
      </Row>
      {modal}
    </div>
  );
};

export default AllQuotes;
